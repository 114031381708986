import { Button, Input, Loader } from "@mantine/core";
import { Text as GestaltText } from "gestalt";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import { authenticateAdmin } from "../../apis/admin";
import { showErrorNotifications, showSuccessNotification } from "../../utils";
import CustomLoader from "../custom-items/Loader";
import AdminContext from "../local-context/AdminContext";

const AdminNavHeader = ({ authMutation, progressing }: { authMutation: any, progressing: boolean }) => {
  const navigate = useNavigate();
  
  const [secret, setSecret] = useState("");
  const authWithSecret = () => {
    if (secret.length < 4) {
      return
    }

    authMutation.mutate({ secret: secret })
  }
  const { authenticated } = useContext(AdminContext);
  const { email } = useContext(ContextProvider);

  return (
    <div className="padding-5vw-and-2vw" style={{ position: "fixed", top: 0, width: "100%", height: "65px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
      <div
        className="neural-logic-logo noselect"
        onClick={ () => navigate("/home") }
      >
        neural-logic
      </div>

      {
        !authenticated ? <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
        < Input type="password" placeholder="Secret key" value={secret} onChange={(e) => setSecret(e.currentTarget.value)} />
        <Button disabled={ progressing } onClick={() => authWithSecret()}>{ progressing ? < Loader size="xs" /> : "Login" }</Button>
      </div>  : < GestaltText size="200" weight="bold" >{ email }</GestaltText>}
    </div>
  )
}

const AdminRoutes = ({ children }: any) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [active, setActive] = useState('Home');
  const [progressing, setProgressing] = useState(false);

  const authMutation = useMutation({
    mutationFn: (params: { secret: string }) => {
      setProgressing(true)
      return authenticateAdmin(params.secret)
    },
    onSuccess: (data) => {
      setProgressing(false)
      
      setAuthenticated(data)
      showSuccessNotification("Authentication successfully!", "Move ahead...")
    },
    onError: (e: unknown) => {
      setProgressing(false)
      showErrorNotifications(e, "Failed to authenticate. Try again!")
      return
    },
    retry: false,
  })

  if (progressing) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="machine-loading-wrapper">
          <CustomLoader message={"Authenticating..."} animate={true} />
        </div>
      </div>
    );
  }

  return (
    <AdminContext.Provider value={{ authenticated, setAuthenticated, active, setActive }}>
      <AdminNavHeader progressing={ progressing } authMutation={authMutation} />
          <div style={{ paddingTop: "65px" }}>
            {children}
          </div>
    </ AdminContext.Provider >
  );
};

export default AdminRoutes;
