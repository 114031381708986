import React, { SetStateAction } from "react";

export interface ContextProviderI {
  token: string | undefined;
  email: string | undefined;
  setToken: React.Dispatch<SetStateAction<string | undefined>>;
  setEmail: React.Dispatch<SetStateAction<string | undefined>>;
  navVisible: boolean;
  setNavVisible: React.Dispatch<SetStateAction<boolean>>;
  showAuthModal: boolean;
  setShowAuthModal: React.Dispatch<SetStateAction<boolean>>;
  currBackgroundColor: string;
  setCurrBackgroundColor: React.Dispatch<SetStateAction<string>>;
  showSelectGPUModal: boolean;
  setShowSelectGPUModal: React.Dispatch<SetStateAction<boolean>>;
  verifiedStatus:
    | {
        phoneNumber: string;
        phoneVerified: boolean;
        emailVerified: boolean;
      }
    | undefined;
  setVerifiedStatus: React.Dispatch<
    React.SetStateAction<
      | {
          phoneNumber: string;
          phoneVerified: boolean;
          emailVerified: boolean;
        }
      | undefined
    >
  >;
  logout: () => void;
  tracker: {
    resourceType: string;
    trackingId: string;
    postSuccessFn: () => void;
    postFailureFn: () => void;
    unmountFn: () => void
  }[];
  setTracker: React.Dispatch<React.SetStateAction<{
    resourceType: string;
    trackingId: string;
    postSuccessFn: () => void;
    postFailureFn: () => void;
    unmountFn: () => void
  }[] >>;
  currTextColor: string;
  setCurrentTextColor: React.Dispatch<SetStateAction<string>>;
  topConsoleButtonText: "blue" | "white";
  setTopConsoleButtonText: React.Dispatch<SetStateAction<"blue" | "white">>;
  showTopHeaderMenu: boolean,
  setShowTopHeaderMenu: React.Dispatch<SetStateAction<boolean>>;
  showAtCapacityBanner: boolean;
  setShowAtCapacityBanner: React.Dispatch<SetStateAction<boolean>>;
}

export interface OSImageI {
  id: string;
  value: string;
  name: string;
  category: string;
  description: string;
  access: string[];
  img: any;
}

export interface AvailableGPUSI {
  name: string;
  memoryBandwidth: number;
  cpu: string;
  ram: string;
  price: number;
  generation: string;
  cuda_cores: number;
  gpuMemory: number;
  tensorCore: number;
  tflops: string;
}

export interface Machines {
  id: string;
  runtime: string;
  node: string;
  region: string;
  autoShutdown: Boolean;
  autoSnapshot: Boolean;
  snapshotFrequency: string;
  snapshotCount: string;
  staticIp: Boolean;
  vmName: string;
  gpu: string;
  status: string;
  createdAt: Date;
  ipAddress: string;
  portsForwarded: Number[][];
  computeCost: Number;
  storageCost: Number;
}

export interface MachinesIndividual {
  id: string;
  gpu: string;
  status: string;
  runtime: string;
  vcpu: string;
  ram: string;
  image: React.ReactNode;
  vm_name: string;
  storage_size: number;
  node_id: string;
  is_free: boolean;
  created_at: string;
}

export interface AdminContextI {
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

export interface MachineDetailPageContextI {
  active: string;
  setActive: React.Dispatch<SetStateAction<string>>;
  vmId: string;
  setVmId: React.Dispatch<SetStateAction<string>>;
  nodeId: string;
  setNodeId: React.Dispatch<SetStateAction<string>>;
  deleteLoading: boolean;
  filteredOsImage: string;
  setFilteredOsImage: React.Dispatch<SetStateAction<string>>;
  setDeleteLoading: React.Dispatch<SetStateAction<boolean>>;
}

export interface RDPPagePageContextI {
  machineId: string;
  nodeId: string;
}

export interface ConsleContextI {
  active: string;
  setActive: React.Dispatch<SetStateAction<string>>;
  showCreateMachinePage: boolean;
  setShowCreateMachinePage: React.Dispatch<SetStateAction<boolean>>;
  filtered: string | null;
  setFiltered: React.Dispatch<SetStateAction<string | null>>;
  progressing: boolean;
  setProgressing: React.Dispatch<SetStateAction<boolean>>;
}

export interface GPUDetails {
  name: string;
  memoryBandwidth: number;
  cpu: string;
  ram: string;
  price: number;
  generation: string;
  cuda_cores: number;
  gpuMemory: number;
  tensorCore: number;
  tflops: string;
}

export const SizeToPriceMap = new Map([
  ["50 GB", 5],
  ["100 GB", 7],
  ["250 GB", 10],
  ["500 GB", 25],
  ["1000 GB", 55],
  ["2000 GB", 120],
]);

export const snapshotToPriceMap = new Map([
  ["50 GB", 1],
  ["100 GB", 1.2],
  ["250 GB", 1.4],
  ["500 GB", 2],
  ["1000 GB", 4],
  ["2000 GB", 8],
]);

export interface ChartDataI {
  primary: string;
  secondary: number;
}

export interface CostTimelineI {
  label: string;
  data: ChartDataI[];
}

export interface TemplateI {
  created_at: Date;
  name: string;
  storage_size: Number;
  runtime: string;
  id: string;
}

export interface AcitivtyDataI {
  command: string;
  activity_date: string;
}

export interface PricingRowI {
  component: string
  cost: string
}

export interface UsageDataFormattedForReactChartJS2I{
  labels: string[];
  datasets:
    {
      label: string;
      data: number[];
      backgroundColor: string;
      minBarLength: number;
    }[]
    max_value: number,
}

export interface PastIssuesI {
  id: string;
  created_at: string;
  status: string;
  subject: string;
}

export interface PastCreditsI{
  person: string;
  status: string;
  amount: number;
  type: string;
}