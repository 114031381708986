import { UnstyledButton, rem } from "@mantine/core";
import {
    IconArrowLeft,
    IconHome2,
    IconReload
} from "@tabler/icons-react";
import { useContext, useState } from "react";
import { Text as GestaltText } from "gestalt";
import { useNavigate, useParams } from "react-router-dom";
import RDPDetailPageContext from "../../local-context/RDPPageNavContext";
// @ts-ignore
import classes from "./RDPMinimalNavBar.module.css";

interface NavbarLinkProps {
  icon: typeof IconHome2;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  return (
    <UnstyledButton
      onClick={onClick}
      className={classes.link}
      data-active={active || undefined}
    >
      <Icon
        color={active ? "black" : "white"}
        style={{ width: rem(20), height: rem(20), marginRight: "10px" }}
        stroke={1.5}
      />
      <GestaltText size="200" color="light">{ label }</GestaltText>
    </UnstyledButton>
  );
}

export function NavbarMinimal() {
  const [active, setActive] = useState(2);
  const navigate = useNavigate();
  const { machineId, nodeId } = useContext(RDPDetailPageContext);

  const mockdata = [
    {
      icon: IconArrowLeft,
      label: "Back to console",
      onClick: () => navigate(`/machine/${machineId}/${nodeId}`),
      size: "md",
    },
  ];

  const bottomData = [
    {
      icon: IconReload,
      label: "Problem? Reload",
      onClick: () => window.location.reload(),
      size: "md",
    },
  ];

  const links = mockdata.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => link.onClick()}
    />
  ));

  const bottomLinks = bottomData.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => link.onClick()}
    />
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <div>
          {links}
        </div>
      </div>

      <div>
        {bottomLinks}
      </div>
    </nav>
  );
}

export default function RDPNavWrapper({ children }: { children: any }) {
  const { machineId, nodeId } = useParams();
  if (machineId == "" || nodeId == "" || machineId == null || nodeId == null) {
    return <div></div>
  } 

  return (
    <RDPDetailPageContext.Provider value={{ machineId, nodeId }}>
      <div>
        <NavbarMinimal />
        {children}
      </div>
    </RDPDetailPageContext.Provider>
  );
}
