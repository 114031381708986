import { Loader } from "@mantine/core";
import { useViewportSize } from '@mantine/hooks';
import axios from "axios";
import { Box, Flex, Button as GestaltButton, Text as GestlatText, Heading, Link, TextField } from "gestalt";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import { BASE_URL } from "../../constants";
import { fetchErrorString, showErrorNotificationWithTitleAndMessage, showSuccessNotification } from "../../utils";
import "./AuthSinglePage.css";
import "./ForgotPassword.css";
import { useQueryParam } from "./Hooks";

const ForgotPassword = () => {
    const [isLogin, setIsLogin] = useState(true)
    const queries = useQueryParam()
    const navigate = useNavigate();
    const [progressing, setProgressing] = useState(false);
    const { height, width } = useViewportSize();
    const [email, setEmail] = useState("");
    const queryClient = useQueryClient();
    const [active, setActive] = useState(0);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ OTP, setOTP ] = useState("");
    const otpRef = useRef(null);
    const [ errorMessage, setErrorMessages ] = useState({
        otp: "",
        email: "",
        newPassword: "",
        confirmPassword: "",
    })
    const [ newPasswords, setNewPasswords ] = useState({
        newPassword: "",
        confirmPassword: "",
    })
    const { setShowTopHeaderMenu, setCurrBackgroundColor, setCurrentTextColor } = useContext(ContextProvider);

    const emailRef = useRef(null);
    const codeRef = useRef(null);

    useEffect(() => {
        let act = searchParams.get("active")
        if (act != null && act == "otp") {
            setActive(1)
            let em = searchParams.get("email")
            if (em != null) {
                setEmail(em!)
            }
        }

    }, [])

    useEffect(() => {
        if (active == 0) {
            if (emailRef != null) {
                // @ts-ignore
                emailRef.current.focus();
            }
        } else if (active == 2) {
            if (otpRef != null) {
                // @ts-ignore
                otpRef.current.focus();
            }
        }
    }, [active])

    const submitEmailMutation = useMutation({
        mutationFn: () => {
            setProgressing(true);

            return axios.get(`${BASE_URL}/password/reset?email=${email}`);
        },
        onSuccess: ({ data }) => {
            setProgressing(false);
            setActive(1)
        },
        onError: (e) => {
            setProgressing(false);
            const errorMsg = fetchErrorString(e)
            setErrorMessages((curr) => {
                return {
                    ...curr,
                    email: errorMsg,
                }
            })
        },
        retry: false,
    });

    const submitOTPMutation = useMutation({
        mutationFn: () => {
            setProgressing(true);

            return axios.post(`${BASE_URL}/password/reset/otp`, {
                otp: OTP,
                email: email,
            });
        },
        onSuccess: ({ data }) => {
            setProgressing(false);
            showSuccessNotification(
                "Success",
                "Your code was correct. Please select a new password in the form below."
            );
            setActive(3)
        },
        onError: (e) => {
            setProgressing(false);
            const errorMsg = fetchErrorString(e)
            setErrorMessages((curr) => {
                return {
                    ...curr,
                    otp: errorMsg,
                }
            })
        },
        retry: false,
    });

    const submitPasswordsMutation = useMutation({
        mutationFn: () => {
            setProgressing(true);

            return axios.post(`${BASE_URL}/password/reset/password`, {
                password: newPasswords.newPassword,
                email: email,
                otp: OTP,
            });
        },
        onSuccess: ({ data }) => {
            setProgressing(false);
            showSuccessNotification(
                "Success",
                "Successfully changed password"
            );
            navigate("/auth")
        },
        onError: (e) => {
            setProgressing(false);
            const errorMsg = fetchErrorString(e)
            setErrorMessages((curr) => {
                return {
                    ...curr,
                    newPassword: errorMsg,
                    confirmPassword: errorMsg,
                }
            })
        },
        retry: false,
    });

    useEffect(() => {
        const type = queries.get("type")
        let img = new Image();
        img.onload = function () {
            
            document.body.style.overflow = "hidden"
            // @ts-ignore
            document.getElementsByClassName("auth-single-page")[0].style.backgroundImage = "url('" + img.src + "')";
        };
        img.src = 'https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/04b1241f-0023-4995-b524-e7ce1d28db00/publiclg';

        if (type == "referral") {
            setIsLogin(false)
        }

        setShowTopHeaderMenu(false);
        setCurrBackgroundColor("#000000")
        setCurrentTextColor("#FFFFFF")

        return () => {
            document.body.style.overflow = ""
            setShowTopHeaderMenu(true);
        }
    }, [])

    useEffect(() => {
        setErrorMessages({
            otp: "",
            email: "",
            newPassword: "",
            confirmPassword: "",
        })
    }, [OTP, email, newPasswords])

    const handleSubmit = () => {
        if (active == 0) {
            if ( /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(email) === false) {
                setErrorMessages((curr) => {
                    return {
                        ...curr,
                        email: "The email you entered is not valid.",
                    }
                })
                showErrorNotificationWithTitleAndMessage("Invalid email", "The email you entered is not valid.")
                return
            }

            submitEmailMutation.mutate()
        } else if (active == 1) {
            setActive(2)
        } else if (active == 2) {
            if ( /^\d{4}/.test(OTP) == false) {
                setErrorMessages((curr) => {
                    return {
                        ...curr,
                        otp: "You did not enter a valid 4 digit OTP code between 0000 and 9999.",
                    }
                })
                showErrorNotificationWithTitleAndMessage("Invalid code", "The code you entered is incorrect.")
                return
            }

            submitOTPMutation.mutate()
        } else {
            if ( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPasswords.newPassword) == false) {
                setErrorMessages((curr) => {
                    return {
                        ...curr,
                        newPassword: "Your password must meet these criteria: At least 8 characters in length. At least one lowercase letter. At least one uppercase letter. At least one digit. At least one special character from: [@ $ ! % * ? &]",
                    }
                })
                showErrorNotificationWithTitleAndMessage("Invalid code", "The code you entered is incorrect.")
                return
            }

            if ( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPasswords.confirmPassword) == false) {
                setErrorMessages((curr) => {
                    return {
                        ...curr,
                        confirmPassword: "Your password must meet these criteria: At least 8 characters in length. At least one lowercase letter. At least one uppercase letter. At least one digit. At least one special character from: [@ $ ! % * ? &]",
                    }
                })
                showErrorNotificationWithTitleAndMessage("Invalid code", "The code you entered is incorrect.")
                return
            }

            if (newPasswords.newPassword !== newPasswords.confirmPassword) {
                setErrorMessages((curr) => {
                    return {
                        ...curr,
                        newPassword: "Both passwords must match",
                        confirmPassword: "Both passwords must match",
                    }
                })
                showErrorNotificationWithTitleAndMessage("Invalid code", "The code you entered is incorrect.")
                return
            }

            submitPasswordsMutation.mutate()
        }
    }

    const handleResetEverything = () => {
        setActive(0)
        setNewPasswords({
            newPassword: "",
            confirmPassword: "",
        })
        setOTP("")
        setEmail("")
        showSuccessNotification("Successfully reset", "Please restart from the begining.")
    }

    const handleKeyDownInsideForm = (keyCode: string) => {
        if (keyCode == 'Enter') {
          handleSubmit()
        }
      }

    return (
        <div className="auth-single-page">
            <div className={`form-wrapper ${"auth-form-outside-modal-wrapper"}`}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px" }}>
                    <Heading align="center" size={width > 768 || height > 1000 ? "500" : "400"}>
                        Reset password
                    </Heading>
                    {progressing && < Loader type="dots" size="md" color="#DE3700" />}
                </div>

                <div style={{ width: "100%" }} className="username-and-pw support-form-wrapper">
                    { active == 0 ?
                        < Box width="100%">
                            < TextField errorMessage={ errorMessage.email } placeholder="john.doe@gmail.com" type="email" ref={ emailRef } id="reset-pw-email-field" label="Enter email" helperText="You will receive an email with a unique code. Use that code to reset password in the next page." onChange={(v) => setEmail(v.value)} value={email} onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)} />
                        </Box> 
                        : active == 1 ?
                        < Box id="password-reset-info-section" width="100%" marginTop={4} marginBottom={2}>
                            <GestlatText size="300">
                                We sent you an email that contains a code to reset your password. Whenever you access your email and are ready to enter the code, please click on the button below.
                            </GestlatText>
                        </Box> 
                        : active == 2 ?
                        < Box width="100%">
                            < TextField errorMessage={ errorMessage.otp } maxLength={{ errorAccessibilityLabel: "The OTP has to be 4 digits long.", characterCount: 4 }} placeholder="0000" type="text" ref={ otpRef } id="reset-pw-otp-field" label="Enter OTP" helperText="Enter the code you received in your email." onChange={(v) => setOTP(v.value)} value={OTP} onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)} />
                        </Box> 
                        : active == 3 ? 
                        <div className="password-reset-confirm-password-section">
                            < Flex  gap={{ column: 6, row: 0 }} direction="column" justifyContent="center" alignItems="center" width="100%">
                                < Box width="100%">
                                    < TextField value={ newPasswords.newPassword } onChange={({ value }) => {
                                        setNewPasswords((curr) => {
                                            return { ...curr, newPassword: value }
                                        })
                                    }} errorMessage={ errorMessage.newPassword } placeholder="New Password" type="password" id="reset-pw-new-password-field" label="New Password" helperText="Passwords must meet the following criterias: At least 8 characters in length. At least one lowercase letter. At least one uppercase letter. At least one digit. At least one special character from: [@ $ ! % * ? &]" 
                                    onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)}
                                    />
                                </Box>

                                < Box width="100%">
                                < TextField value={ newPasswords.confirmPassword } onChange={({ value }) => {
                                    setNewPasswords((curr) => {
                                        return { ...curr, confirmPassword: value }
                                    })
                                }} errorMessage={ errorMessage.confirmPassword } placeholder="Confirm Password" type="password" id="reset-pw-new-password-confirm-field" label="Confirm password" helperText="Both passwords must match"
                                onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)}
                                />
                                </Box>

                            </Flex>
                        </div> : <></>
                    }
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        { active == 2 && <Box marginEnd="auto"> < GestaltButton onClick={() => setActive(0)} text="Go back" color="gray" /></Box> } 
                        { active == 3 && <Box marginEnd="auto"> < GestaltButton onClick={ handleResetEverything } text="Restart" color="gray" /></Box> } 
                        < GestaltButton onClick={ handleSubmit } text={ active == 0 ? "Send code" : active == 1 ? "Next" : active == 2 ? "Verify Code" : "Change Password"  } color="blue" />
                    </div>

                    < Box marginTop={8} >
                        < Link href="#" onClick={() => navigate("/auth")}>
                            <GestlatText underline={true} size="200" color="dark"> Already have an account? Log in.</GestlatText>
                        </Link>
                    </Box>
                </div>

            </div>
        </div>
    );
};

export default ForgotPassword;