import {
  Badge,
  Button,
  Drawer,
  Skeleton,
  Title
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronRight,
  IconPlus
} from "@tabler/icons-react";
import { MachinesIndividual } from "../../../../types";
import "./MachinesList.css";

import { Tooltip as GestaltTooltip, IconButton } from "gestalt";
import moment from "moment";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const mapOfLogosToImages = new Map();
mapOfLogosToImages.set(
  "ubuntu_22.04",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/ubuntu-logo-black-white.png",
);
mapOfLogosToImages.set(
  "ubuntu_18.04",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/ubuntu-logo-black-white.png",
);
mapOfLogosToImages.set(
  "windows_10",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/windows-black-and-white.png",
);
mapOfLogosToImages.set(
  "tensorflow",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/tensorflow-black-and-white.png",
);
mapOfLogosToImages.set(
  "pytorch",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/pytorch-black-and-white.png",
);
mapOfLogosToImages.set(
  "tensorflow_pytorch",
  "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/black-and-white/pytorch-black-and-white.png",
);

export const getImg = (runtime: string) => {
  let imgSrc = mapOfLogosToImages.get(runtime);
  if (
    runtime == "ubuntu_22.04" ||
    runtime == "ubuntu_18.04" ||
    runtime == "windows_10" ||
    runtime == "pytorch"
  ) {
    return <img width="50" src={imgSrc} />;
  } else {
    return (
      <div
        style={{
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img width="40" height="50px" src={imgSrc} />
      </div>
    );
  }
};

const MachinesList = ({
  machines,
  setShowCreateMachinePage,
}: {
  machines: MachinesIndividual[];
  setShowCreateMachinePage: any;
}) => {
  const [
    bottomDrawerOpened,
    { open: openBottomDrawer, close: closeBottomDrawer },
  ] = useDisclosure(false);
  const [drawerContent, setDrawerContent] = useState("This is a helpful text");
  const currentRef = useRef(null);

  return (
    <div style={{ backgroundColor: "transparent" }} ref={currentRef}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="machine-list-display">
          <CreateMachine setShowCreateMachinePage={setShowCreateMachinePage} />
          {machines.map((machine, idx) => (
            <IndividualMachine
              id={machine.id}
              gpu={machine.gpu}
              ram={machine.ram}
              vcpu={machine.vcpu}
              runtime={machine.runtime}
              name={machine.vm_name}
              status={machine.status}
              storage={machine.storage_size}
              nodeId={machine.node_id}
              isFree={machine.is_free}
              createdAt={machine.created_at}
            />
          ))}
        </div>
      </div>

      <Drawer
        position="right"
        opened={bottomDrawerOpened}
        size="lg"
        onClose={closeBottomDrawer}
        title={
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Title order={3}>What am I looking at?</Title>
          </div>
        }
        styles={{
          content: { borderRadius: "16px 0 0 16px" },
          root: { display: "flex", alignItems: "center" },
          inner: {
            height: "80dvh",
            alignSelf: "center",
            marginTop: "auto",
            marginBottom: "auto",
          },
        }}
        transitionProps={{ duration: 250, timingFunction: "linear" }}
        overlayProps={{ backgroundOpacity: 0.3, blur: 4 }}
      >
        <div className="display-machine-list-wrapper">{drawerContent}</div>
      </Drawer>
    </div>
  );
};

const CreateMachine = ({
  setShowCreateMachinePage,
}: {
  setShowCreateMachinePage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div
      style={{
        padding: "16px",
        display: "flex",
        borderRadius: "8px",
        border: "1px solid #d2d2d7",
      }}
      className="light-raised-shadow"
    >
      <div
        style={{
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Skeleton h="55px" visible={!imgLoaded}>
          <img
            onLoad={() => setImgLoaded(true)}
            className="h-55-30"
            src="https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/8775dbac-8778-470b-71aa-eb82d06aa200/public"
          />
        </Skeleton>
      </div>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            setShowCreateMachinePage(true);
          }}
          size="sm"
          leftSection={<IconPlus size="18" />}
          gradient={{ from: "blue", to: "cyan", deg: 90 }}
          variant="gradient"
        >
          Create New Machine
        </Button>
      </div>
    </div>
  );
};

export function addThreeDays(time: string) {
  const date = new Date(time);
  date.setDate(date.getDate() + 3); // Add 3 days
  return date;
}

const IndividualMachine = ({
  id,
  runtime,
  name,
  gpu,
  vcpu,
  ram,
  status,
  storage,
  nodeId,
  isFree,
  createdAt,
}: {
  id: string;
  runtime: string;
  name: string;
  gpu: string;
  vcpu: string;
  ram: string;
  status: string;
  storage: number;
  nodeId: string;
  isFree: boolean;
  createdAt: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="on-hover-pointer on-hover-shadow light-raised-shadow"
      onClick={() => navigate(`/machine/${id}/${nodeId}`)}
      style={{
        padding: "16px",
        display: "flex",
        borderRadius: "8px",
        border: "1px solid #d2d2d7",
      }}
    >
      <div
        style={{ marginRight: "auto", display: "flex", alignItems: "center" }}
      >
        <div>{getImg(runtime)}</div>
        <div
          style={{
            display: "flex",
            marginLeft: "20px",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <div style={{ fontWeight: "500", fontSize: "20px" }}>{name.toUpperCase()}</div>
          </div>
          <div
            className="fs-14 font-light fw-500"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <div>{vcpu} vcpu</div>-
            <div>{ram}GB ram</div>-
            <div>{storage}GB ssd</div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {
          isFree &&
          <div className="centered-flex">
            <Badge style={{ paddingRight: 2 }} variant="transparent" color="#cc0000" size="md">Free</Badge>
            <GestaltTooltip text={`This is a complimentary machine. It will be deleted if not upgraded within ${moment(addThreeDays(createdAt)).fromNow(true)}.`}>
              <IconButton
                accessibilityLabel="Additional info"
                bgColor="white"
                icon="info-circle"
                iconColor="red"
                size="sm"
                padding={1}
              />
            </GestaltTooltip>
          </div>

          // < Tooltip label={`This is a complimentary machine. It will be deleted if not upgraded within ${ moment(addThreeDays(createdAt)).fromNow(true) }.`}>
          //   <div className="centered-flex">
          //   < IconInfoOctagon size={16} stroke={1.8} />
          //     <Badge style={{ paddingLeft: 6 }} variant="transparent" color="rgba(255, 0, 0, 1)" size="md">Free</Badge>
          //   </div>
          // </Tooltip>
        }

        <Button
          className="on-hover-pointer"
          style={{ display: "flex", alignItems: "center" }}
          styles={{ section: { marginLeft: 2 } }}
          rightSection={<IconChevronRight size="14" />}
          color="black"
          size="sm"
          variant="outline"
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default MachinesList;
