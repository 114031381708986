import {
  Accordion,
  Button,
  Text as MantineText,
  PasswordInput,
  Select,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { getHotkeyHandler } from '@mantine/hooks';
import {
  IconCheck,
  IconCircleChevronDown,
  IconCircleChevronUp,
  IconCpu,
  IconEye,
  IconEyeCancel,
  IconInfoCircle,
  IconSquareRoundedLetterT
} from "@tabler/icons-react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllTemplates } from "../../../../apis/machines.ts";
import { BASE_URL } from "../../../../constants.ts";
import {
  OSImageI,
  SizeToPriceMap,
  snapshotToPriceMap,
} from "../../../../types.ts";
import {
  showErrorNotificationWithTitleAndMessage,
  showErrorNotifications,
  showSuccessNotification,
  truncate,
} from "../../../../utils.tsx";
import { AllAvailableGPUS } from "../../../custom-items/AllAvailableGPUS";
import CustomAccordion from "../../../custom-items/CustomAccordion";
import CustomLabel from "../../../custom-items/CustomLabel";
import { SelectOptionComponent } from "../../../custom-items/CustomSelectGPUModal";
import CustomLoader from "../../../custom-items/Loader.tsx";
import MantineCustomizedButton from "../../../custom-items/MantineCustomizedButton";
import ConsoleContext from "../../../local-context/ConsoleContext.tsx";
import AdvancedOption from "./AdvancedOptions.tsx";
import "./CreateMachineForm.css";
import { AllAvailableImages, AllStorageSizes, AmericanFlag } from "./Utils";

const convertName = (snakeStr: string) => {
  return snakeStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const SystemInformation = ({
  SelectedMachineInfo,
  SelectedOS,
  AddOns,
}: any) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <TextAtBothEnds
          increaseGap={true}
          title={""}
          texts={[
            ["GPU name", `${SelectedMachineInfo!.name} GPU`],
            ["vCPU", `${SelectedMachineInfo?.cpu}x vCPU`],
            ["RAM", `${SelectedMachineInfo?.ram} GB RAM`],
            ["Operating System", `${convertName(SelectedOS.value)}`],
            ["Add-ons", AddOns],
            ["GPU Memory", `${SelectedMachineInfo?.gpuMemory} GB`],
            ["Base OS", SelectedOS!.baseOS],
            [
              "GPU Memory Bandwidth",
              `${SelectedMachineInfo?.memoryBandwidth} GB/sec`,
            ],
            ["TFLOPS2", `${SelectedMachineInfo?.tflops}`!],
            ["CUDA Cores", `${SelectedMachineInfo?.cuda_cores}`!],
            ["Tensor Cores", `${SelectedMachineInfo?.tensorCore}`],
          ]}
        />
      </div>
    </div>
  );
};

const CreateMachineForm = ({
  setShowCreateMachinePage,
}: {
  setShowCreateMachinePage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [availableImages, setAvailableImages] = useState<OSImageI[]>(AllAvailableImages);
  const [osImageSelected, setOsImageSelected] = useState("tensorflow_pytorch");
  const [isAnimating, setIsAnimating] = useState(false);
  const [region, setRegion] = useState("");
  const [selectedGPU, setSelectedGPU] = useState("M4000");
  const [dropdownsOpen, setDropdownsOpen] = useState({
    runtime: false,
    storage: false,
  });
  const [showError, setShowError] = useState({
    region: false,
    password: false,
  });
  const navigate = useNavigate();
  const checkPassword = (str: string) =>
    str.length >= 4 && /^[a-zA-Z0-9]+$/.test(str);
  const [pageIsLoadingInitially, setPageIsLoadingInitially] = useState(true);
  const autoSnapshotRef = useRef(null);
  const [userPassword, setUserPassword] = useState("");
  const { filtered, setFiltered, showCreateMachinePage } = useContext(ConsoleContext);
  const [ creating, setCreating ] = useState(false);

  const {
    isLoading: templatesIsLoading,
    error: templatesError,
    data: templates,
  } = useQuery({
    queryKey: "GET_ALL_TEMPLATES",
    queryFn: () => getAllTemplates(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (templates != null && templatesError == null && templates.length > 0) {
      let customTemplates = templates.map((d : any) => {
        let templateImage = AllAvailableImages.findLast(e => d.runtime == e.value)
        let newImg = {...templateImage}
        newImg!.name = d.name
        newImg!.category = "Custom Template"
        newImg!.value = d.name
        newImg!.description = "Custom Image"
        newImg.id = d.id;
        return newImg
      })
      
      if (filtered != null) {
        let filteredImage = customTemplates.findLast((d: OSImageI) => d.name == filtered)
        
        setOsImageSelected(filtered)
        setAvailableImages(() => [ filteredImage ])
      } else {
        setAvailableImages(() => [ ...AllAvailableImages, ...customTemplates ])
      }
    }
  }, [templates, filtered])

  

  if (templatesError) {
    showErrorNotifications(
      templatesError,
      "Could not fetch templates. Reload the page to refetch it",
    );
  }

  const form = useForm({
    initialValues: {
      osCategory: "All",
      osImage: "tensorflow_pytorch",
      storageSize: "50 GB",
      location: "",
      password: "",
      staticIP: false,
      gpu: "M4000",
      vmName: "",
      snapshotFrequency: "Week",
      snapshotCount: "1",
    },
  });

  const SelectedMachineInfo = AllAvailableGPUS.find(
    (e) => e.name == selectedGPU,
  );

  
  const SelectedOS = availableImages.find(
    (e) => e.value === osImageSelected,
  );

  const getPricingSummaryText = () => {
    let res = [
      [
        "Storage cost",
        `$${(SizeToPriceMap.get(form.values.storageSize)! / 24 / 30).toFixed(
          3,
        )}/hr`,
        `$${SizeToPriceMap.get(form.values.storageSize)} per month for ${
          form.values.storageSize
        }. Divided by 24 hours * 30 days`,
      ],
      [
        `Auto-Snapshot`,
        `$${(
          (snapshotToPriceMap.get(form.values.storageSize)! *
            Number(form.values.snapshotCount!)) /
          24 /
          30
        ).toFixed(3)}/hr`,
        `$${snapshotToPriceMap.get(form.values.storageSize)!} per month * ${
          form.values.snapshotCount
        } snapshots. Divided by 24 hours * 30 days`,
      ],
    ];

    if (form.values.staticIP) {
      res.push([
        `Static IP`,
        `${(3 / 24 / 30).toFixed(3)}/hr`,
        `$3 per month. Divided by 24 hours * 30 days`,
      ]);
    }
    return res;
  };

  useEffect(() => {
    setPageIsLoadingInitially(true);

    let timeoutId = setTimeout(() => {
      setPageIsLoadingInitially(false);
      clearTimeout(timeoutId);
    }, 600);
    document.body.style.backgroundColor = "#f1f1f1";

    return () => {
      clearTimeout(timeoutId);
      document.body.style.backgroundColor = "";
      setFiltered(null)
    };
  }, []);

  const endAnimation = () => {
    setIsAnimating(() => false);
  };

  const getAddOns = () => {
    let res = [];
    if (form.values.staticIP) {
      res.push("Static IP");
    }

    return res.join("/");
  };

  const items = [
    <Accordion.Item key={"sytem-info"} value={"sytem-info"}>
      <Accordion.Control
        style={{ fontSize: "16px" }}
        icon={<IconCpu stroke={1.2} />}
      >
        {"System Information - Optional"}
      </Accordion.Control>
      <Accordion.Panel>
        {
          <SystemInformation
            selectedGPU={form.values.gpu}
            SelectedMachineInfo={SelectedMachineInfo}
            osImageSelected={osImageSelected}
            SelectedOS={SelectedOS}
            AddOns={getAddOns()}
          />
        }
      </Accordion.Panel>
    </Accordion.Item>,
  ];

  const calculateTotal = () => {
    let storageCost = SizeToPriceMap.get(form.values.storageSize)! / 24 / 30;
    let staticIPCost = form.values.staticIP ? 3 / 24 / 30 : 0;
    let gpuCost = gpuPrice;
    return (gpuCost + storageCost + staticIPCost).toFixed(3);
  };

  const gpuPrice = AllAvailableGPUS.find((e) => e.name === selectedGPU)!.price;
  
  if (pageIsLoadingInitially) {
    return (
      <div style={{ minHeight: "100vh", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-20px",
            width: "100%",
          }}
        >
          <div className="machine-loading-wrapper">
            <CustomLoader message={"FETCHING..."} animate={false} />
          </div>
        </div>
      </div>
    );
  }

  const handleAccordionOpen = async (val: string) => {
    if (val != null) {
      if (autoSnapshotRef != null && autoSnapshotRef.current != null) {
        setTimeout(() => {
          // @ts-ignore
          autoSnapshotRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 400);
      }
    }
  };

  const handleSubmit = () => {
    if (!checkPassword(userPassword)) {
      setShowError((curr) => {
        return { ...curr, password: true };
      });
      showErrorNotificationWithTitleAndMessage(
        "Submission failed",
        "Please select a password with letters and number that longer than 3 characters",
      );
      return;
    } else {
      setShowError((curr) => {
        return { ...curr, password: false };
      });
    }

    if (region == "") {
      setShowError((curr) => {
        return { ...curr, region: true };
      });
      showErrorNotificationWithTitleAndMessage(
        "Submission failed",
        "Please select a region",
      );
      return;
    } else {
      setShowError((curr) => {
        return { ...curr, region: false };
      });
    }

    const selectedImage = availableImages.find(d => d.name == osImageSelected)!;
    const isTemplate = selectedImage?.category === "Custom Template";

    (async () => {
      try {
        setCreating(true)
        let resp = await axios.post(BASE_URL + "/create-vm", {
          ...form.values,
          osImage: osImageSelected,
          gpu: selectedGPU,
          region: region,
          snapshotCount: Number(form.values.snapshotCount),
          password: userPassword,
          use_template: isTemplate,
        });
        showSuccessNotification(
          "VM is being created",
          "This should take about a minute for Linux instances.",
        );
        let vmId = resp.data.payload.vmId;
        let nodeId = resp.data.payload.nodeId;
        setShowCreateMachinePage(() => false);
        navigate(`/status/${vmId}/${nodeId}`);
        setCreating(false)
      } catch (e) {
        setCreating(false)
        showErrorNotifications(e, "Something failed. Try again!", 10000);
      }
    })();
  };

  

  function dedup(arr: string[]) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  let filteredImages: OSImageI[] = [];
  if (form.values.osCategory == "All") {
    filteredImages = availableImages
  } else {
    filteredImages = availableImages.filter(each => each.category == form.values.osCategory)
  }

  return (
    <div className="create-machine-form-wrapper">
      <form className="create-machine-form">
        <div className="runtime-wrapper">
          <div style={{ width: "100%" }}>
            <CustomLabel label="Runtime" />
            <Select
              onDropdownOpen={() =>
                setDropdownsOpen((dOpen) => {
                  return { ...dOpen, runtime: true };
                })
              }
              autoFocus
              onDropdownClose={() =>
                setDropdownsOpen((dOpen) => {
                  return { ...dOpen, runtime: false };
                })
              }
              withAsterisk
              label="Runtime"
              placeholder="All"
              data={[ "All", ...dedup(availableImages.map((img) => img.category)) ]}
              // onChange={setOS}
              {...form.getInputProps("osCategory")}
              size="md"
              styles={{
                label: {
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "6px",
                  display: "none",
                },
                root: {
                  width: "100%",
                },
              }}
              rightSection={
                dropdownsOpen.runtime ? (
                  <IconCircleChevronUp color="#666666" strokeWidth={1.2} />
                ) : (
                  <IconCircleChevronDown color="#666666" strokeWidth={1.2} />
                )
              }
              checkIconPosition="right"
              classNames={{
                input: "console-input-border-box-shadow-and-placeholder",
                dropdown: "console-input-border-box-shadow-and-placeholder",
              }}
              allowDeselect={false}
            />
          </div>
          <div className="os-image-display">
            {filteredImages.map((each, i) => (
              <div
                onAnimationEnd={endAnimation}
                onClick={() => {
                  setOsImageSelected(() => each.value);
                  setIsAnimating(() => true);
                }}
                style={{
                  backgroundColor:
                    osImageSelected == each.value ? "#121212" : "white",
                  color: osImageSelected == each.value ? "white" : "black",
                }}
                className={`grid-individual-container ${
                  osImageSelected == each.value
                    ? "animate"
                    : "dont-animate"
                }`}
              >
                {each.value === osImageSelected && (
                  <div className="floating-check-icon">
                    <IconCheck
                      strokeWidth={3}
                      color="#121212"
                      width="20"
                      height="20"
                    />
                  </div>
                )}
                {
                  each.category == "Custom Template" && 
                    <Tooltip label="Custom template">
                      <div className="custom-template-icon">
                        <IconSquareRoundedLetterT
                          strokeWidth={1.3}
                          color="#0009"
                          width="24"
                          height="24"
                        />
                      </div>
                    </Tooltip>
                }
                <div style={{ borderRadius: "50%" }}>{each.img}</div>
                <div className="grid-individual-container-text">
                  < Tooltip label={ each.name }><div>{ truncate(each.name, 18) }</div></Tooltip>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip label={each.description}>
                      <IconInfoCircle
                        color={
                          osImageSelected == each.value ? "white" : "black"
                        }
                        strokeWidth={2}
                        size="16px"
                      />
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <Tooltip multiline
                    w={220}
                    withArrow
                    transitionProps={{ duration: 200 }}
                    label="There are different ways of accessing the machine. With SSH, you can securely access the machine through the terminal using a password/key. RDP allows you to look at the screen - from your browser or from a RDP client. You can install additional access mechanisms like VNC.">
                  <div className="text-tag font-12">
                    {each.access.join("/")}
                  </div></Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <CustomLabel label="Machine Type" />
          <SelectOptionComponent
            setSelectedGPU={setSelectedGPU}
            selectedGPU={selectedGPU}
          />
        </div>

        <div>
          <Select
            onDropdownOpen={() =>
              setDropdownsOpen((dOpen) => {
                return { ...dOpen, storage: true };
              })
            }
            onDropdownClose={() =>
              setDropdownsOpen((dOpen) => {
                return { ...dOpen, storage: false };
              })
            }
            label="Storage"
            {...form.getInputProps("storageSize")}
            data={AllStorageSizes}
            classNames={{
              input: "console-input-border-box-shadow-and-placeholder",
              dropdown: "console-input-border-box-shadow-and-placeholder",
              label: "font-12",
            }}
            checkIconPosition="right"
            placeholder="50 GB"
            size="md"
            required
            withAsterisk
            allowDeselect={false}
            styles={{
              label: {
                fontWeight: "600",
                paddingLeft: "6px",
                fontSize: "12px",
              },
              input: {
                fontSize: "16px",
              },
            }}
            rightSection={
              dropdownsOpen.storage ? (
                <IconCircleChevronUp color="#666666" strokeWidth={1.2} />
              ) : (
                <IconCircleChevronDown color="#666666" strokeWidth={1.2} />
              )
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CustomLabel label="Location" />

          <div className="region-button-wrapper">
            <Button
              className="region-button"
              onClick={() => setRegion("NY")}
              variant={region == "NY" ? "filled" : "outline"}
              leftSection={
                <img
                  width="25px"
                  style={{ borderRadius: 2 }}
                  src={AmericanFlag}
                />
              }
              color={region == "NY" ? "black" : "black"}
              size="md"
              radius="sm"
              fullWidth
              style={{
                backgroundColor: region == "NY" ? "black" : "white",
                fontSize: "16px",
                fontweight: "300",
              }}
            >
              New York
            </Button>

            <Button
              className="region-button"
              leftSection={
                <img
                  width="25px"
                  style={{ borderRadius: "2px" }}
                  src={AmericanFlag}
                />
              }
              onClick={() => setRegion("SF")}
              variant={region == "SF" ? "filled" : "outline"}
              color={region == "SF" ? "black" : "black"}
              size="md"
              radius="sm"
              fullWidth
              style={{ backgroundColor: region == "SF" ? "black" : "white" }}
            >
              San Francisco
            </Button>
          </div>

          {showError.region ? (
            <MantineText
              style={{
                fontSize: "11px",
                paddingLeft: "6px",
                paddingTop: "1px",
                color: "red",
              }}
            >
              You must select a region to create a machine.
            </MantineText>
          ) : (
            <MantineText
              style={{
                fontSize: "11px",
                paddingLeft: "6px",
                paddingTop: "1px",
                color: "#444444",
              }}
            >
              Select a region near you for optimal performance.
            </MantineText>
          )}
        </div>

        <div className="authentication">
          <CustomLabel label="Password" />
          <PasswordInput
            label="Input label"
            name="pw"
            styles={{
              label: {
                display: "none",
                visibility: "hidden",
                paddingBottom: "2px",
              },
            }}
            onKeyDown={getHotkeyHandler([
              ['Enter', handleSubmit],
            ])}
            onChange={(e) => setUserPassword(e.currentTarget.value)}
            value={userPassword}
            classNames={{
              input: "console-input-border-box-shadow-and-placeholder create-machine-pw",
            }}
            visibilityToggleIcon={({ reveal }) => (
              <ShowPassword reveal={reveal} />
            )}
            placeholder="Password"
            size="md"
          />
          {showError.password ? (
            <MantineText
              style={{
                fontSize: "11px",
                paddingLeft: "6px",
                paddingTop: "1px",
                color: "red",
              }}
            >
              The password must be longer than 4 characters long. And please
              remember your password.
            </MantineText>
          ) : (
            <MantineText
              style={{
                fontSize: "11px",
                paddingLeft: "6px",
                paddingTop: "1px",
                color: "#444444",
              }}
            >
              Select a secure password to authenticate with the Machine when
              logging in.
            </MantineText>
          )}
        </div>

        <div>
          <CustomLabel required={false} label="Advanced Configurations" />
          <CustomAccordion
            fn={handleAccordionOpen}
            item={{
              image:
                "https://static.vecteezy.com/system/resources/thumbnails/010/286/671/small/settings-icon-gear-3d-render-png.png",
              description: "Public IPs, Machine Name, VM Group etc.",
              label: "Assign additional configurations",
              content: (
                <AdvancedOption
                  selectedGPU={selectedGPU}
                  setSelectedGPU={setSelectedGPU}
                  reference={autoSnapshotRef}
                  form={form}
                />
              ),
            }}
          />
        </div>

        <div>
          <CustomLabel required={false} label="System Information" />
          <Accordion
            chevron={
              <IconCircleChevronDown
                width={24}
                height={24}
                color="#666666"
                strokeWidth={1.2}
              />
            }
            classNames={{
              root: "console-input",
            }}
            styles={{
              root: {
                backgroundColor: "white",
                borderRadius: "4px",
                border: "0.5px solid #d2d2d7",
              },
              item: {
                borderRadius: "4px",
              },
            }}
            defaultValue="Apples"
          >
            {items}
          </Accordion>
        </div>

        <div>
          <CustomLabel
            // fontSize={"16px"}
            required={false}
            label="Pricing summary"
          ></CustomLabel>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
            }}
            className="pricing-summary-wrapper"
          >
            <TextAtBothEnds
              title={"Charges occured when machine is active"}
              texts={[[`${selectedGPU} GPU`, `$${gpuPrice}/hr`]]}
            />

            <TextAtBothEnds
              title={"Charges incurred regardless of machine state:"}
              texts={getPricingSummaryText()}
            />
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "20px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "600" }}>Total</div>
            <div style={{ fontWeight: "600" }}>{`$${calculateTotal()}/hr`}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              setShowCreateMachinePage(false);
            }}
            variant="outline"
            color="rgba(0, 0, 0, 1)"
          >
            Go Back
          </Button>
          <MantineCustomizedButton
            clicker={() => handleSubmit()}
            color="black"
            className="switch-button"
            styles={{ border: "1px solid black" }}
            disabled={creating}
          >
            Create Machine
          </MantineCustomizedButton>
        </div>
      </form>
    </div>
  );
};

export const TextAtBothEnds = ({
  title,
  texts,
  increaseGap = false,
}: {
  title: string;
  texts: string[][];
  increaseGap?: boolean;
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ fontSize: "14px" }}>{title}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: increaseGap ? "5px" : "auto",
        }}
      >
        {texts.map((each) => {
          return (
            <div
              style={{
                paddingLeft: increaseGap ? 0 : "24px",
                width: "100%",
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <div>{each[0]}</div>
                  <div>{each[1]}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ShowPassword = ({ reveal }: { reveal: boolean }) => {
  if (reveal) {
    return <IconEyeCancel color="#666666" strokeWidth={1.2} />;
  }
  return <IconEye color="#666666" strokeWidth={1.2} />;
};

export const SmallText = ({ label }: { label: string }) => {
  return (
    <MantineText
      style={{
        fontSize: "11px",
        paddingLeft: "6px",
        paddingTop: "1px",
        color: "#444444",
      }}
    >
      {label}
    </MantineText>
  );
};

export default CreateMachineForm;
