import { Loader } from "@mantine/core";
import {
    CompositeZIndex,
    FixedZIndex,
    Flex,
    IconButton,
    Layer,
    Modal
} from "gestalt";
import { Fragment, useContext, useState } from "react";
import ContextProvider from "../../ContextProvider";
import "./AuthModal.css";
import Authentication from "./Authentication";

export default function AuthModal({ isLogin }: { isLogin: boolean }) {
  const HEADER_ZINDEX = new FixedZIndex(1000);
  const zIndex = new CompositeZIndex([HEADER_ZINDEX]);
  const { showAuthModal, setShowAuthModal } = useContext(ContextProvider);
  const [ authProgressingForModal, setAuthProgressingForModal ] = useState(false);

  return (
    <Fragment>
      {showAuthModal ? (
        <Layer zIndex={zIndex}>
          <Modal
            accessibilityModalLabel="Sign Up"
            align="center"
            heading={
              <Flex justifyContent="between" alignItems="center">
                <div className="auth-header">
                    Welcome to Neural Logic
                </div>
                {
                  authProgressingForModal &&
                  < Loader size="sm" color="red" />
                }
                <IconButton
                  accessibilityLabel="Dismiss modal"
                  bgColor="white"
                  icon="cancel"
                  iconColor="darkGray"
                  onClick={() => setShowAuthModal(() => false)}
                  size="sm"
                />
              </Flex>
            }
            // heading={
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "flex-end",
            //       alignItems: "flex-end",
            //       width: "90%",
            //       position: "absolute",
            //     }}
            //   >
            //     <div style={{ marginLeft: "auto" }}>
            //       <IconButton
            //         accessibilityLabel="Dismiss modal"
            //         bgColor="white"
            //         icon="cancel"
            //         iconColor="darkGray"
            //         onClick={() => setShowAuthModal(() => false)}
            //         size="sm"
            //       />
            //     </div>
            //   </div>
            // }
            
            onDismiss={() => {
              setShowAuthModal(() => false);
            }}
            size={500}
          >
            <div className="modal-top-and-bottom-padding">
              <Authentication setAuthProgressingForModal={setAuthProgressingForModal} logIn={isLogin} isInAModal={true} />
            </div>
          </Modal>
        </Layer>
      ) : null}
    </Fragment>
  );
}
