import { Text } from "gestalt";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getMachines } from "../../../apis/machines.ts";
import { fetchErrorString, showErrorNotifications } from "../../../utils.tsx";
import { useQueryParam as useLocationQuery } from "../../auth/Hooks.tsx";
import CustomLoader from "../../custom-items/Loader.tsx";
import MantineCustomizedButton from "../../custom-items/MantineCustomizedButton.tsx";
import "./Machines.css";
import CreateMachineForm from "../console-segments/machines/CreateMachineForm.tsx";
import { ActionIcon, Button, Divider, Image } from "@mantine/core";
import MachinesList from "../console-segments/machines/MachinesList.tsx";
import { AllAvailableImages } from "../console-segments/machines/Utils.tsx";
import { AllAvailableGPUS } from "../../custom-items/AllAvailableGPUS.ts";
import { MachinesIndividual } from "../../../types.ts";
import { IconChevronsLeft } from "@tabler/icons-react";
import MachineDetailPageContext from "../../local-context/MachinesDetailPageContext.tsx";
import ConsoleContext from "../../local-context/ConsoleContext.tsx";
import { AlternativeErrorSectionLg } from "../../custom-items/ErrorSection.tsx";
import ContextProvider from "../../../ContextProvider.tsx";
import Anchor from "../../custom-items/CustomAnchor";
import { DOCS_URL } from "../../../constants.ts";

interface MachinesListFromBackendI {
  id: string;
  gpu: string;
  status: string;
  runtime: string;
  vm_name: string;
  storage_size: number;
  node_id: string;
  is_free: boolean;
  created_at: string;
}

const processMachinesList = (data: MachinesListFromBackendI[]) => {
  return data.map((machine) => {
    let m = AllAvailableGPUS.filter((e) => e.name === machine.gpu)[0];
    let r = AllAvailableImages.filter((e) => e.value == machine.runtime)[0];

    return {
      ...machine,
      vcpu: m.cpu,
      ram: m.ram,
      image: r.img,
      storage_size: machine.storage_size,
    };
  });
};

const Machines = (props: any) => {
  const { showCreateMachinePage, setShowCreateMachinePage } = useContext(ConsoleContext);
  const { email } = useContext(ContextProvider);

  const { isLoading, error, data } = useQuery({
    queryKey: "GET_MACHINES",
    queryFn: () => getMachines(email),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  let query = useLocationQuery();
  useEffect(() => {

    if (query.get("showForm") != null) {
      let showForm = query.get("showForm");
      if (showForm == "true") {
        setShowCreateMachinePage(() => true);
      }
    }
  }, []);

  let processedMachinesList: MachinesIndividual[] = [];
  if (data != null) {
    processedMachinesList = processMachinesList(data.payload.machines);
  }

  

  if (error) {
    let errorStr = fetchErrorString(error)

    return (
      <div style={{ height: "100%", width: "100%", paddingLeft: "40px", paddingTop: "20px" }}>
        < AlternativeErrorSectionLg message={ `Error: Could not get list of user's machines. ${ errorStr }` } />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="machine-loading-wrapper">
          <CustomLoader message={"FETCHING..."} animate={false}/>
        </div>
      </div>
    );
  }

  if (showCreateMachinePage) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: "32px",
          position: "relative",
          backgroundColor: "#f1f1f1",
          paddingTop: "24px",
          paddingBottom: "100px",
        }}
      >
        <ActionIcon
          onClick={() => setShowCreateMachinePage(false)}
          variant="outline"
          color="black"
          style={{ position: "absolute", left: "30px", top: "10px", backgroundColor: "white" }}
        >
          <IconChevronsLeft stroke={1.4} />
        </ActionIcon>

        <CreateMachineForm
          setShowCreateMachinePage={setShowCreateMachinePage}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="padding-5vw-and-2vw"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          paddingBottom: "150px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <ControlsHeader text={"Machines"} />

          <MachinesList
            setShowCreateMachinePage={setShowCreateMachinePage}
            machines={processedMachinesList}
          />
        </div>

        <div className="half-and-full">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ControlsHeader text={"Info"} />
            <InfoSection />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ControlsHeader text={"Important note !"} />
            <PricingSection />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoSection = () => {
  return (
    <div className="info-section">
      Machines are virtual desktops running on the cloud. They are hosted using
      virtualization and they offer near-physical machine performance. These
      machines have been designed to be shared, and you are guaranteed to
      receive the resources (GPU, CPU, Storage) that you sign up for. 
      <p>
        <Anchor
          style={{
            fontSize: "inherit",
            color: "black",
            textDecoration: "underline",
          }}
          // TODO: add link to the docs to learn more about machines.
          isHref={true}
          href={ `${ DOCS_URL }/docs/Machines/overview` }
        >
          Learn more about machines
        </Anchor>
      </p>
    </div>
  );
};

const PricingSection = () => {
  return (
    <div className="info-section">
      You are only charged for the amount of time that you use your machine, not when it is shutdown. Be careful to always shutdown your machine from the details page of your machine, not from inside your machine.
    </div>
  );
};

export const ControlsHeader = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="name-display">{text}</div>
      </div>
    </div>
  );
};

export default Machines;
