import { Loader } from "@mantine/core";
import { Text as GestaltText, IconButton } from "gestalt";
import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContextProvider from "../../../../ContextProvider";
import { showErrorNotificationWithTitleAndMessage, showErrorNotifications, showSuccessNotification } from "../../../../utils";
import "./PendingMachineStatusUpdate.css";
import { WS_ENDPOINT } from "../../../../constants";

const SmallerPendingStatusUpdater = ({ i }: { i: number }) => {
  const [socket, setSocket] = React.useState<WebSocket | null>(null);
  const navigate = useNavigate();
  const [loaderMessage, setLoaderMessage] = useState("creating");
  const queryClient = useQueryClient();
  let [searchParams, setSearchParams] = useSearchParams();
  const { token, tracker, setTracker } = useContext(ContextProvider)

  let rType = tracker != null ? tracker[i]!.resourceType : "";
  let tId = tracker != null ? tracker[i]!.trackingId : "";
  let successFn = tracker != null ? tracker[i]!.postSuccessFn : () => {};
  let failureFn = tracker != null ? tracker[i]!.postFailureFn : () => {};
  let unmountFunction = tracker != null ? tracker[i]!.unmountFn : () => {};

  const removeItem = () => {
      setTracker((curr) => {
      if (curr!.length == 0) {
        return []
      } else {
        return curr!.filter((d) => d.trackingId != tId)
      }
    })
  }

  useEffect(() => {
    if (tracker == null) {
      showErrorNotificationWithTitleAndMessage(
        "Error",
        `Something went wrong. Could not get updates. Please refresh after a while to see your newly created resource`,
      );
      return;
    }

    const { trackingId, postSuccessFn, postFailureFn, resourceType } = tracker[i];

    let newSocket = new WebSocket(`${WS_ENDPOINT}/status-update/${trackingId}`);
    newSocket.onopen = () => {
      newSocket.send(
        JSON.stringify({
          type: "token-handshake",
          message: token,
        }),
      );
    };

    newSocket.onclose = () => {
      newSocket.close();
    };

    newSocket.onmessage = (e) => {
      const message = JSON.parse(e.data);

      if (message.status == "failed") {
        showErrorNotificationWithTitleAndMessage("Error", message.message);
        setTracker((curr) => {
            return curr!.filter((d) => d.trackingId != trackingId)
        })
        postFailureFn()
      } else if (message.status === "success") {
        if (message.message == "completed") {
          setLoaderMessage("completed");
          postSuccessFn()
          setTracker((curr) => {
            return curr!.filter((d) => d.trackingId != trackingId)
          })
          showSuccessNotification("Success", `${ resourceType } was successfully created`)
        } else if (message.message == "connected") {
          newSocket.send(
            JSON.stringify({
              message: trackingId,
              type: "update",
            }),
          );
        } else {
          setLoaderMessage(message.message);
        }
      }
    };
    newSocket.onerror = (e) => {
      showErrorNotifications(
        e,
        "Could not fetch status of the machine. Deployment is ongoing and you will be notified soon.",
      );
    };

    setSocket(newSocket);

    return () => {
      if (socket?.readyState === WebSocket.OPEN) {
        socket.close();
      }

      unmountFunction()
    };
  }, []);

  return (
    <div className="light-raised" style={{ height: "80px", minWidth: "370px", backgroundColor: "white", display: "flex", padding: "10px", justifyContent: "space-between", width: "100%", gap: "40px", borderRadius: "4px", position: "relative" }}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", }}>
        <GestaltText color="error">Status for {rType}{": "}{ tId.substring(0, 8) }</GestaltText>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
        < Loader size="xs" color="black"  /><GestaltText weight="bold" color="shopping" >{loaderMessage}</GestaltText>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center",  }}>
        <IconButton
          accessibilityLabel="Dismiss modal"
          bgColor="white"
          icon="cancel"
          iconColor="darkGray"
          onClick={() => removeItem()}
          size="xs"
        />
      </div>
    </div>
  )
}

export default SmallerPendingStatusUpdater;