

import { createContext } from "react";
import { AdminContextI } from "../../types";

const adminContext: AdminContextI = {
  authenticated: false,
  setAuthenticated: () => {},
  active: "Home",
  setActive: () => {},
};

const AdminContext = createContext(adminContext);

export default AdminContext;
