import {
    Badge,
    Combobox,
    Divider,
    InputBase,
    Text,
    useCombobox
} from "@mantine/core";
import {
    IconCircleChevronDown
} from "@tabler/icons-react";
import {
    CompositeZIndex,
    FixedZIndex,
    IconButton,
    Layer,
    Modal
} from "gestalt";
import { Fragment, useContext, useState } from "react";
import ContextProvider from "../../ContextProvider";
import { AllAvailableGPUS } from "./AllAvailableGPUS";
import "./CustomSelectGPUModal.css";
import { motion } from 'framer-motion';

export const M4000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/m4000.png";
const P4000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/p4000.png";
const P6000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/p6000.png";
const RTX4000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/rtx-4000.png";
const RTX5000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/rtx-5000.png";
const A4000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/a4000.png";
const A5000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/a5000.png";
const A6000Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/a6000.png";
const A100Logo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/gpu-images/a100.png";
const FreeGPULogo = "https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/a32d8b2c-5b36-4dbe-9b62-804de9fb3100/public" 

export const mapOfNamesToImages = new Map();
mapOfNamesToImages.set("M4000", M4000Logo);
mapOfNamesToImages.set("P4000", P4000Logo);
mapOfNamesToImages.set("P6000", P6000Logo);
mapOfNamesToImages.set("RTX4000", RTX4000Logo);
mapOfNamesToImages.set("RTX5000", RTX5000Logo);
mapOfNamesToImages.set("A4000", A4000Logo);
mapOfNamesToImages.set("A5000", A5000Logo);
mapOfNamesToImages.set("A6000", A6000Logo);
mapOfNamesToImages.set("A100", A100Logo);
mapOfNamesToImages.set("CPU", FreeGPULogo)

function SelectOption({
  name,
  memoryBandwidth,
  cpu,
  ram,
  price,
  generation,
  cuda_cores,
  gpuMemory,
  tensorCore,
  tflops,
  selected = false,
  shouldBeSelectable = false,
  onClickFn,
  clickedBefore = true,
}: {
  name: string | undefined;
  memoryBandwidth: number;
  cpu: string;
  ram: string;
  price: number;
  generation: string;
  cuda_cores: number;
  gpuMemory: number;
  tensorCore: number;
  tflops: string;
  selected: boolean;
  shouldBeSelectable: boolean;
  onClickFn: () => void;
  clickedBefore?: boolean;
}) {

  return (
    <div
      id="compute"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        // width: "100%",
        borderRadius: "8px",
        gap: "30px",
        padding: "10px",
        color: selected ? "white" : clickedBefore ? "black" : "#616161",
        backgroundColor: selected ? "#121212" : "transparent",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <img src={mapOfNamesToImages.get(name)} width="50px" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              fontWeight: selected ? "600" : "500",
            }}
          >
            {generation} {name}
          </div>
          <div className="gpu-details-subheader">
            {`${cpu}-core vCPU`} &bull; {`${ram}GB RAM`}
          </div>
        </div>
      </div>

      <div className="gpu-details-price">
        <Badge
          variant="outline"
          color={selected ? "white" : clickedBefore ? "black" : "#616161"}
          size="md"
          radius="sm"
          className="font-10-small-screen"
        >
          {`$${price.toFixed(2)}/hr`}
        </Badge>
      </div>
    </div>
  );
}

export function SelectOptionComponent({
  selectedGPU,
  setSelectedGPU,
}: {
  setSelectedGPU: React.Dispatch<React.SetStateAction<string>>;
  selectedGPU: string;
}) {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });
  const [clicked, setClicked] = useState(false);

  const selectedOption = AllAvailableGPUS.find(
    (item) => item.name === selectedGPU,
  )!;

  const { setShowSelectGPUModal } = useContext(ContextProvider);
  return (
    <Combobox
      store={combobox}
      withinPortal={true}
      onOptionSubmit={(val) => {
        setSelectedGPU(val);
        combobox.closeDropdown();
      }}
      transitionProps={{ duration: 400, transition: "slide-up" }}
      onOpen={() => {
        setShowSelectGPUModal(() => true);
        setClicked(true);
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          label={
            <>
              {/* <span>Compute</span>
              <span style={{ color: "#FA5252" }}> *</span> */}
            </>
          }
          pointer
          rightSection={
            <div style={{ height: "20px", width: "20px", paddingRight: "5px" }}>
              <IconCircleChevronDown color="#666666" strokeWidth={1.2} />
            </div>
          }
          onClick={() => {
            setShowSelectGPUModal(() => true);
            combobox.toggleDropdown();
          }}
          rightSectionPointerEvents="none"
          multiline
          styles={{
            label: {
              fontSize: "12px",
              fontWeight: "600",
              backgroundColor: "transparent",
              display: "none",
            },
            section: {
              paddingRight: "12px",
            },
          }}
          classNames={{
            input: "select-component-border",
          }}
        >
          <SelectOption
            shouldBeSelectable={false}
            onClickFn={() => {}}
            {...selectedOption}
            selected={false}
            clickedBefore={true}
          />
        </InputBase>
      </Combobox.Target>
    </Combobox>
  );
}

export default function SelectGPUModal({
  setSelectedGPU,
  selectedGPU,
}: {
  setSelectedGPU: React.Dispatch<React.SetStateAction<string>>;
  selectedGPU: string;
}) {
  const HEADER_ZINDEX = new FixedZIndex(1000);
  const zIndex = new CompositeZIndex([HEADER_ZINDEX]);
  const { showSelectGPUModal, setShowSelectGPUModal } =
    useContext(ContextProvider);
  const [pressedGPU, setPressedGPU] = useState<string | null>(null);

  const handleGPUSelect = async (name: string) => {
    setPressedGPU(() => name);
    setSelectedGPU(() => name);
    setTimeout(() => {
      setPressedGPU(null);
      setShowSelectGPUModal(() => false);
    }, 200);
  };

  if (showSelectGPUModal) {
    return (
      <Fragment>
        <Layer zIndex={zIndex}>
          <Modal
            accessibilityModalLabel="Sign Up"
            align="center"
            heading={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "90%",
                  position: "absolute",
                  right: "30px",
                  top: "10px",
                  backgroundColor: "transparent",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="modal-padding-left"
                  size="lg"
                >
                  Select a GPU
                </Text>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    accessibilityLabel="Dismiss modal"
                    bgColor="white"
                    icon="cancel"
                    iconColor="darkGray"
                    onClick={() => setShowSelectGPUModal(() => false)}
                    size="sm"
                  />
                </div>
              </div>
            }
            onDismiss={() => setShowSelectGPUModal(() => false)}
            size={"md"}
          >
            <div className="modal-top-and-bottom-padding">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                style={{
                  display: "flex",
                  maxHeight: "50dvh",
                  columnGap: "20px",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                  {AllAvailableGPUS.map((each, i) => {
                  return (
                    <div
                      onClick={() => handleGPUSelect(each.name)}
                      className={`gpuDisplayWrapper shadow-on-hover ${
                        pressedGPU === each.name
                          ? "make-bigger-animation"
                          : "dont-make-bigger"
                      }`}
                    >
                      <SelectOption
                        shouldBeSelectable={true}
                        onClickFn={() => handleGPUSelect(each.name)}
                        {...each}
                        selected={each.name === selectedGPU}
                      />
                      <Divider
                        style={{
                          width: "80%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </div>
                  );
                })}
                
              </motion.div>
            </div>
          </Modal>
        </Layer>
      </Fragment>
    );
  } else {
    return null;
  }
}
