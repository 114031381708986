import { useContext, useEffect, useRef } from "react";
import { createNoise2D } from "simplex-noise";
import ContextProvider from "../../ContextProvider";
import "./RuntimeCollection.css";

const noise2D = createNoise2D();

const SCROLL_SPEED = 0.3;
const NOISE_SPEED = 0.004;
const NOISE_AMOUNT = 10;

let bubbleSpecs = [
  { s: 0.6, x: 0, y: 0 },
  { s: 0.6, x: 50, y: 150 },
  { s: 0.6, x: 100, y: 300 },
  { s: 0.6, x: 150, y: 0 },
  { s: 0.6, x: 200, y: 150 },
  { s: 0.6, x: 250, y: 300 },
  { s: 0.6, x: 300, y: 0 },
  { s: 0.6, x: 350, y: 150 },
  { s: 0.6, x: 400, y: 300 },
  { s: 0.6, x: 450, y: 0 },
  { s: 0.6, x: 500, y: 150 },
  { s: 0.6, x: 550, y: 300 },
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

class Bubble {
  index: number;
  x: number;
  y: number;
  canvas_width: number;
  reentry_threshold: number;
  scale: number;
  noiseSeedX: number;
  el: HTMLDivElement;
  // @ts-ignore
  noiseSeedY: number;
  // @ts-ignore
  xWithNoise: number;
  // @ts-ignore
  yWithNoise: number;

  constructor(
    index: number, canvas_width: number, reentry_threshold: number,
    { x, y, s = 1, }: { x: number; y: number; s: number, },
  ) {
    this.index = index;
    this.x = x;
    this.y = y;
    this.scale = s;
    this.canvas_width = canvas_width;
    this.reentry_threshold = reentry_threshold;

    this.noiseSeedX = Math.floor(Math.random() * 64000);
    this.noiseSeedX = Math.floor(Math.random() * 64000);

    let elements = document.getElementsByClassName("bubble-" + index);
    // @ts-ignore
    this.el = elements[0];
  }

  update() {
    this.noiseSeedX += NOISE_SPEED;
    this.noiseSeedY += NOISE_SPEED;
    let randomX = noise2D(this.noiseSeedX, 0);
    let randomY = noise2D(this.noiseSeedY, 0);

    this.x -= SCROLL_SPEED;
    this.xWithNoise = this.x + randomX * NOISE_AMOUNT;
    this.yWithNoise = this.y + randomY * NOISE_AMOUNT;
    
    if (this.x < this.reentry_threshold) {
      this.x = this.canvas_width;
    }

    this.el.style.transform = `translate(${this.xWithNoise}px, ${this.yWithNoise}px) scale(${this.scale})`;
  }
}

export const RuntimesCollection = ({
}: {
}) => {
  const { setCurrBackgroundColor, setCurrentTextColor, setTopConsoleButtonText } = useContext(ContextProvider);
  const bubblesRef = useRef(null);

  useEffect(() => {
      setTimeout(() => {
        // @ts-ignore
        const CANVAS_WIDTH = bubblesRef.current.offsetWidth;
        // @ts-ignore
        const CANVAS_HEIGHT = bubblesRef.current.offsetHeight - 100;

        let step = Math.max(CANVAS_WIDTH/12, 60);
        const REENTRY_THRESHOLD = -1 * (step * 12 - CANVAS_WIDTH);

        bubbleSpecs = [
          { s: 0.6, x: 0, y: 0 },
          { s: 0.6, x: step * 1, y: CANVAS_HEIGHT/2 },
          { s: 0.6, x: step * 2, y: CANVAS_HEIGHT },
          { s: 0.6, x: step * 3, y: 0 },
          { s: 0.6, x: step * 4, y: CANVAS_HEIGHT/2 },
          { s: 0.6, x: step * 5, y: CANVAS_HEIGHT },
          { s: 0.6, x: step * 6, y: 0 },
          { s: 0.6, x: step * 7, y: CANVAS_HEIGHT/2  },
          { s: 0.6, x: step * 8, y: CANVAS_HEIGHT },
          { s: 0.6, x: step * 9, y: 0 },
          { s: 0.6, x: step * 10, y: CANVAS_HEIGHT/2 },
          { s: 0.6, x: step * 11, y: CANVAS_HEIGHT },
        ];
      // }

      const startAnimation = () => {
        // Animation using requestAnimationFrame
        let bubbles: Bubble[] = [];
        bubbleSpecs.forEach((spec, index) => {
          bubbles.push(new Bubble(index + 1, CANVAS_WIDTH, REENTRY_THRESHOLD, spec));
        });
        function playAnimation() {
          bubbles.forEach((e) => e.update());

          let reqId = requestAnimationFrame(playAnimation);
        }
        requestAnimationFrame(playAnimation);
      };

      startAnimation();
      }, 1000)
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",}}
      className="bubble-wrap"
    >
      <div ref={ bubblesRef } className="bubbles">
        <div className="bubble-1 lazy" />
        <div className="bubble-2 lazy" />
        <div className="bubble-3 lazy" />
        <div className="bubble-4 lazy" />
        <div className="bubble-5 lazy" />
        <div className="bubble-6 lazy" />
        <div className="bubble-7 lazy" />
        <div className="bubble-8 lazy" />
        <div className="bubble-9 lazy" />
        <div className="bubble-10 lazy" />
        <div className="bubble-11 lazy" />
        <div className="bubble-12 lazy" />
      </div>
    </div>
  );
};

export default RuntimesCollection;
