import { Loader } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Text
} from "gestalt";
import {
  Ref,
  useContext,
  useEffect,
  useId,
  useRef,
  useState
} from "react";
import Anchor from "../custom-items/CustomAnchor.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants.ts";
import "./Authentication.css";

import {
  IconExclamationCircle,
  IconEye,
  IconEyeOff
} from "@tabler/icons-react";
import sha256 from "sha256";
import ContextProvider from "../../ContextProvider.tsx";
import { addTokenToCookies, fetchErrorString, formatPhoneNumber } from "../../utils.tsx";
import { getHotkeyHandler, useViewportSize } from '@mantine/hooks';
// @ts-ignore
import errorClasses from "../../notifications/ErrorNotifications.module.css";
import {
  showErrorNotifications
} from "../../utils.tsx";
import CustomAnchor from "../custom-items/CustomAnchor.tsx";

export function hashPassword(password: string): string {
  const hashedPassword = sha256(password);
  return hashedPassword;
}

const findError = (
  input: { password: string; email: string; phone: string },
  isLogin: boolean,
  setShowErrorMessagesFlag: any
) => {
  if (
    input.email.length &&
    /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(input.email) === false
  ) {
    setShowErrorMessagesFlag((curr: any) => {
      return { ...curr,
        email: "Please enter a valid email",
      }
    })
    return "Please enter a valid email";
  }

  if (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      input.password,
    ) === false
  ) {
    setShowErrorMessagesFlag((curr: any) => {
      return { ...curr,
        password: "Password must be greater than 8 characters, with one uppercase, one lowercase, a digit and a symbol: !@#$%^&*",
      }
    })
    return "Password must be greater than 8 characters, with one uppercase, one lowercase, a digit and a symbol: !@#$%^&*";
  }

  let changedPhoneNumber = ("" + input.phone).replace(/\D/g, "");
  if (!isLogin && changedPhoneNumber.length != 10) {
    setShowErrorMessagesFlag((curr: any) => {
      return { ...curr,
        phone: "Phone must be 10 digits long",
      }
    })
    return "Phone must be 10 digits long";
  }
};


const Authentication = ({
  reference,
  isInAModal = false,
  logIn = true,
  referral_token="",
  is_referral=false,
  setAuthProgressingForModal,
}: any) => {
  

  const { setToken, setEmail, setShowAuthModal, showAuthModal, token } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    phone: "",
    password: "",
    email: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    phone: "",
    password: "",
    email: "",
  });
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const [isLogin, setIsLogin] = useState(logIn);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [googleButtonHeight, setGoogleButtonHeight] = useState(0);
  const { pathname } = useLocation();
  const [showErrorMessagesFlag, setShowErrorMessagesFlag] = useState({
    password: false,
    email: false,
    phone: false,
  });
  const [ progressing, setProgressing ] = useState(false);
  const { width, height } = useViewportSize();

  useEffect(() => {
    if (isInAModal) {
      setAuthProgressingForModal(progressing)
    }
  }, [progressing])


  const loginWithGoogle = () => {
    (async () => {
      try {
        let url: string
        if (is_referral) {
          url = `${BASE_URL}/login-with-google?type=referral&&token=${ referral_token }`
        } else {
          url = `${BASE_URL}/login-with-google`
        }
        let resp = await axios.get(url);
        setShowAuthModal(() => false);
        let redirect_url = resp.data.payload.auth_url;
        let win = window.open(redirect_url, "_self");
        if (win != null) {
          win.focus();
        }
      } catch (e) {
        showErrorNotifications(e, "Logging in with Google was unsuccessful");
      }
    })();
  };

  const validate = (updatedInput: any) => {
    let isError = false;
    let changedPhoneNumber = ("" + updatedInput.phone).replace(/\D/g, "");
    if (updatedInput.phone.length && changedPhoneNumber.length != 10) {
      isError = true;
      setErrorMessages((currError: any) => {
        let newObj = {
          ...currError,
          phone: "Phone must be 10 digits long",
        };
        return newObj;
      });
    } else {
      setShowErrorMessagesFlag((prevState) => {
        return { ...prevState, phone: false };
      });
      setErrorMessages((currError: any) => {
        let newObj = { ...currError, phone: "" };
        return newObj;
      });
    }

    if (
      updatedInput.password.length &&
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        updatedInput.password,
      ) === false
    ) {
      isError = true;
      setErrorMessages((currError: any) => {
        let newObj = {
          ...currError,
          password:
            "Password must be greater than 8 characters, with one uppercase, one lowercase, a digit and a symbol: !@#$%^&*",
        };
        return newObj;
      });
    } else {
      setShowErrorMessagesFlag((prevState) => {
        return { ...prevState, password: false };
      });
      setErrorMessages((currError: any) => {
        let newObj = { ...currError, password: "" };
        return newObj;
      });
    }

    if (
      updatedInput.email.length &&
      /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(updatedInput.email) ===
        false
    ) {
      isError = true;
      setErrorMessages((prevError) => {
        let newObj = { ...prevError };
        newObj.email = "Please enter a valid email";
        return newObj;
      });
    } else {
      setShowErrorMessagesFlag((prevState) => {
        return { ...prevState, email: false };
      });
      setErrorMessages((currError: any) => {
        let newObj = { ...currError, email: "" };
        return newObj;
      });
    }
    return isError;
  };

  const showErrorMessages = () => {
    let phone = !isLogin && errorMessages.phone != "";
    let email = errorMessages.email != "";
    let password = errorMessages.password != "";

    setShowErrorMessagesFlag((prevState: any) => {
      return {
        ...prevState,
        phone: phone,
        email: email,
        password: password,
      };
    });
  };

  const onFinish = () => {
    let errored = validate(input);
    if (errored) {
      let errorMsg = findError(input, isLogin, setShowErrorMessagesFlag);
      notifications.show({
        title: "Login failed",
        message: errorMsg,
        color: "red",
        icon: <IconExclamationCircle />,
        classNames: errorClasses,
      });
      return;
    }
    if (input.email == "") {
      notifications.show({
        title: "Login failed",
        message: "Email is empty",
        color: "red",
        icon: <IconExclamationCircle />,
        classNames: errorClasses,
      });
      setErrorMessages((prevMsg) => {
        return { ...prevMsg, email: "Email is empty" };
      });
      setShowErrorMessagesFlag((prevFlag) => {
        return {
          ...prevFlag,
          email: true,
        };
      });
      return;
    }
    if (input.password == "") {
      notifications.show({
        title: "Login failed",
        message: "Password is empty",
        color: "red",
        icon: <IconExclamationCircle />,
        classNames: errorClasses,
      });
      setErrorMessages((prevMsg) => {
        return { ...prevMsg, password: "Password is empty" };
      });
      setShowErrorMessagesFlag((prevFlag) => {
        return {
          ...prevFlag,
          password: true,
        };
      });
      return;
    }
    if (!isLogin && input.phone == "") {
      notifications.show({
        title: "Login failed",
        message: "Phone is empty",
        color: "red",
        icon: <IconExclamationCircle />,
        classNames: errorClasses,
      });
      setErrorMessages((prevMsg) => {
        return { ...prevMsg, email: "Phone is empty" };
      });
      setShowErrorMessagesFlag((prevFlag) => {
        return {
          ...prevFlag,
          phone: true,
        };
      });
      return;
    }

    (async () => {
      try {
        setProgressing(true)

        const hashedPassword = hashPassword(input.password);
        const password = !isLogin ? input.password : hashedPassword;
        const response = await axios.post(`${BASE_URL}/auth`, {
          ...input,
          login: isLogin,
          password: password,
          is_referral: is_referral,
          token: referral_token,
          phone: input.phone.replace(/\D/g, "").substring(0, 10),
        });
        addTokenToCookies(response.data.payload.token, input.email);
        setEmail(input.email);
        setShowAuthModal(() => false);
        setToken(response.data.payload.token);
        navigate("/console");
        setProgressing(false)
      } catch (e: any) {
        setProgressing(false)
        const title = `${isLogin ? "Login failed" : "Signup failed"}`;
        let errorStr = fetchErrorString(e)
        setErrorMessages((curr) => { return { ...curr, password: errorStr } })
        showErrorNotifications(e, title);
        setShowErrorMessagesFlag((curr) => { return { ...curr, password: true } })
      }
    })();
  };

  useEffect(() => {
    setShowErrorMessagesFlag((curr: any) => {
      return { ...curr, password: false, email: false, phone: false };
    })
  }, [input])

  const emailRef: Ref<HTMLInputElement> = useRef(
    null,
  ) as Ref<HTMLInputElement>;
  const loginButtonRef: Ref<HTMLButtonElement> = useRef(
    null,
  ) as Ref<HTMLButtonElement>;
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    if ((showAuthModal && emailRef != null && isInAModal) || pathname == "/auth") {
      
      setTimeout(() => {
              // @ts-ignore
              emailRef?.current?.focus();
      }, 500)
    }
  }, [showAuthModal])

  useEffect(() => {
    // @ts-ignore
    setButtonWidth(emailRef?.current.offsetWidth);
    // @ts-ignore
    setGoogleButtonHeight(loginButtonRef?.current.offsetHeight);
  }, [emailRef]);


  useEffect(() => {
    if (pathname === "/register") {
      setIsLogin(false);
    } else if (pathname == "/auth") {
      setIsLogin(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (token != null) {
      navigate("/console")
    }
  }, [token])

  const phoneId = useId();
  const passwordId = useId();
  const emailId = useId();

  const togglePassword = () => {
    setPasswordIsHidden(!passwordIsHidden);
  };

  return (
    <div ref={reference} className={ `form-wrapper ${ isInAModal ? "auth-form-modal-wrapper" : "auth-form-outside-modal-wrapper" }`}>
      {!isInAModal && (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px" }}>
          <Heading align="center" size={ width > 768 || height > 1000 ? "500" : "400"}>
            { isLogin ? "Sign in to console" : "Learn more by signing up" }
          </Heading>
          { progressing && < Loader type="dots" size="md" color="#DE3700" /> }
        </div>
      )}

      <div className="username-and-pw">
        <div style={{ width: "100%" }}>
          <label className="form-label">Email</label>
          <input
            autoComplete={"off"}
            type="email"
            onBlur={() => showErrorMessages()}
            onChange={({ target }) => {
              setInput((currInput) => {
                let newObj = { ...currInput, email: target.value };
                validate(newObj);
                return newObj;
              });
            }}
            style={{
              height: "52px",
            }}
            onFocus={(event) => {
              event.target.setAttribute("autocomplete", "off");
            }}
            name={"" + Math.random()}
            value={input.email}
            id={emailId}
            ref={emailRef}
            className="form-input auth-home"
            placeholder={"Email"}
            onKeyDown={getHotkeyHandler([
              ['Enter', onFinish],
            ])}
          />
          {showErrorMessagesFlag.email == true && (
            <div className="error-message">{errorMessages.email}</div>
          )}
        </div>

        {!isLogin && (
          <div style={{ width: "100%" }}>
            <label className="form-label">Phone number</label>

            <input
              onChange={({ target }) => {
                setInput((currInput) => {
                  let newObj = {
                    ...currInput,
                    phone: formatPhoneNumber(target.value),
                  };
                  validate(newObj);
                  return newObj;
                });
              }}
              autoComplete={"" + Math.random()}
              name={"" + Math.random()}
              className="phone-input form-input auth-home"
              value={input.phone}
              id={phoneId}
              type="text"
              style={{
                height: "52px",
              }}
              maxLength={14}
              ref={phoneRef}
              placeholder={"Phone"}
              onBlur={() => showErrorMessages()}
              onKeyDown={getHotkeyHandler([
                ['Enter', onFinish],
              ])}
            />
            {showErrorMessagesFlag.phone == true && (
              <div className="error-message">{errorMessages.phone}</div>
            )}
          </div>
        )}

        <div style={{ width: "100%" }}>
          <label className="form-label">Password</label>
          <div
            style={{
              display: "flex",
              width: "100%",
              position: "relative",
              alignItems: "center",
            }}
          >
            <input
              value={input.password}
              onBlur={() => showErrorMessages()}
              onChange={({ target }) => {
                setInput((currInput) => {
                  let newObj = { ...currInput, password: target.value };
                  validate(newObj);
                  return newObj;
                });
              }}
              autoComplete={"new-password"}
              name={"" + Math.random()}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
              id={passwordId}
              ref={passwordRef}
              placeholder={passwordIsHidden ? "********" : "Password"}
              type={passwordIsHidden ? "password" : "text"}
              className="form-input auth-home"
              style={{
                height: "52px",
              }}
              onKeyDown={getHotkeyHandler([
                ['Enter', onFinish],
              ])}
            />
            <div
              onClick={togglePassword}
              style={{ position: "absolute", right: 15 }}
            >
              {passwordIsHidden ? (
                <IconEye strokeWidth={1.5} />
              ) : (
                <IconEyeOff />
              )}
            </div>
          </div>
          {showErrorMessagesFlag.password == true && (
            <div className="error-message">{errorMessages.password}</div>
          )}
        </div>
        {
          isLogin &&
            <Box width="100%">
              <Link onClick={() => navigate("/forgot-password")} href="#">
                <div
                    className="prevent-select custom-link"
                    style={{ fontSize: "14px", fontWeight: 500, marginTop: -4, }}
                >
                  Forgot your password?
                </div>
              </Link>
            </Box>
        }
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          width: "100%",
        }}
      >
        <Flex width={"100%"}>
          <Button
            ref={loginButtonRef}
            color="blue"
            size="md"
            fullWidth={true}
            disabled={progressing}
            text={ isLogin ? "Login" : "Register"}
            onClick={onFinish}
          ></Button>
        </Flex>
        <Text weight={"bold"} size={"200"}>
          OR
        </Text>
        <Box width={"100%"}>
          <button
            style={{
              width: "100%",
              height:
                // @ts-ignore
                googleButtonHeight | 0,
            }}
            disabled={progressing}
            className="continue-with-google-button"
            onClick={loginWithGoogle}
          >
            <div style={{ marginRight: "5px", height: "30px" }}>
              <img
                src="https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/google-48.png"
                height="30px"
                width="30px"
              />
            </div>
            <div className="continue-with-google-wrapper">
              Continue with Google
            </div>
          </button>
        </Box>
      </div>

      <div className="bottom-text-wrapper">
        <div style={{ textAlign: "center", fontSize: "12px", color: "gray" }}>
          By continuing, you agree to Neural Logic LLC's{" "}
          <Anchor
          style={{
            fontSize: "inherit",
            color: "black",
            textDecoration: "underline",
          }}
          href="#"
          onClick={() => navigate("/tos")}
        >Terms of Service</Anchor> and acknowledge
          you've read our <Anchor 
            style={{
              fontSize: "inherit",
              color: "black",
              textDecoration: "underline",
            }}
            href="#"
            onClick={() => navigate("/privacy")}
          >Privacy Policy</Anchor>.{" "}
          <Anchor
            style={{
              fontSize: "inherit",
              color: "black",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/data-processing-agreement")}
          >
            Data collection policy
          </Anchor>
        </div>
      </div>

      <div style={{ width: "50%" }}>
        <Divider />
      </div>

      <div
        className="custom-link"
        style={{ textAlign: "center", fontSize: "14px", width: "100%", fontWeight: "500" }}
        onClick={() => setIsLogin((loggedIn: boolean) => !loggedIn)}
      >
        {isLogin
          ? "Don't have an account yet? Register"
          : "Already have an account? Log in"}
      </div>
    </div>
  );
};

export default Authentication;
