import axios from "axios";
import * as moment from 'moment';
import "moment-timezone";
import { BASE_URL } from "../constants.ts";

export const getMachines = async (email: string | undefined) => {
  
  let res = axios.get(`${BASE_URL}/get-list-of-machines`).then((res) => {
    return res.data;
  });
  return res;
};

export const getPhoneVerification = async () => {
  let res = axios.get(`${BASE_URL}/phone-verified`).then((res) => {
    return res.data;
  });
  return res;
};

export const getMachine = async (vmId: string) => {
  
  let res = axios.get(`${BASE_URL}/get-machine/${vmId}`).then((res) => {
    
    return res.data.payload;
  });
  return res;
};

export const getStatus = async (vmId: string) => {
  let res = axios.get(`${BASE_URL}/status/${vmId}`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const changeName = async (vmID: string, newName: string) => {
  let res = await axios
    .get(`${BASE_URL}/change-name/${vmID}?new_name=${newName}`)
    
  return res.data.payload;
};

export const getActivityForAMachine = async (vmID: string, quantity: number) => {
  
  let res = axios.get(`${BASE_URL}/activity/machine/${vmID}/${quantity}`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const getActivityForAUser = async (quantity: number) => {
  
  let res = axios.get(`${BASE_URL}/activity/user/${quantity}`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const getUsage = async (email: string) => {
  let res = axios.get(`${BASE_URL}/usage/current`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const getHistoricalUsage = (
  email: string,
  lookupMonth: string,
  lookupYear: string,
) => {
  let res = axios
    .get(`${BASE_URL}/usage-prev/${lookupMonth}/${lookupYear}`)
    .then((res) => {
      return res.data.payload;
    });
  return res;
};

export const getTemplates = async (vmId: string) => {
  let res = axios.get(`${BASE_URL}/templates/get/${vmId}`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const getAllTemplates = async () => {
  let res = axios.get(`${BASE_URL}/templates/all`).then((res) => {
    return res.data.payload;
  });
  return res;
};

export const getBackup = async (vmId: string) => {
  let res = axios.get(`${BASE_URL}/backup/last/${ vmId }`).then((res) => {
    return res.data.payload;
  });
  return res;
}

export const getUsageForAMonth = async ({ queryKey }: { queryKey: [ string, string, number, string ] }) => {
  // 
  const [ _, month, year, machineId ] = queryKey
  
  const { data } = await axios.get(`${BASE_URL}/usage/machine/${month}/${year}/${machineId}?timezone=${ moment.tz.guess() }`)
  return data.payload
}

export const getUsageForAMonthForAUser = async ({ queryKey }: { queryKey: [ string, string, number ] }) => {
  // 
  const [ _, month, year ] = queryKey
  
  const { data } = await axios.get(`${BASE_URL}/usage/user/${month}/${year}?timezone=${ moment.tz.guess() }`)
  return data.payload
}

export const getPastIssuesForAUser = async () => {
    const { data } = await axios.get(`${BASE_URL}/support/user/list`)
    return data.payload
}

export const initVerification = async () => {
  const { data } = await axios.get(`${BASE_URL}/init-verification-status`)
  return data.payload
}

export const getListOfPaymentCards = async () => {
  const { data } = await axios.get(`${BASE_URL}/customer/cards`)
  
  return data.payload;
}

export const getPastInvoices = async () => {
  const { data } = await axios.get(`${BASE_URL}/customer/invoice`)
  
  return data.payload; 
}

export const getUserProfile = async () => {
  const { data } = await axios.get(`${BASE_URL}/user/profile`)
  
  return data.payload; 
}

export const getCreditsForUser = async () => {
  const { data } = await axios.get(`${ BASE_URL }/user/credits`)
  
  return data.payload;
}