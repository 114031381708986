import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import "./AuthSinglePage.css";
import Authentication from "./Authentication";
import { useQueryParam } from "./Hooks";

const AuthSinglePage = () => {
  const [ isLogin, setIsLogin ] = useState(true)
  const queries = useQueryParam()
  const { setShowTopHeaderMenu, setCurrBackgroundColor, setCurrentTextColor } = useContext(ContextProvider);

  const navigate = useNavigate();

  useEffect(() => {
    const type = queries.get("type")  
    
    let img = new Image();

    img.onload = function(){
        document.body.style.overflow = "hidden"
        // @ts-ignore
        document.getElementsByClassName("auth-single-page")[0].style.backgroundImage = "url('" + img.src + "')";
    };

    
    setShowTopHeaderMenu(false);
    setCurrBackgroundColor("#000000")
    setCurrentTextColor("#FFFFFF")
    
    img.src = 'https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/04b1241f-0023-4995-b524-e7ce1d28db00/publiclg';

    if (type == "referral") {
      setIsLogin(false)
    }

    return () => {
      document.body.style.overflow = ""
      setShowTopHeaderMenu(true);
    }
  }, [])

  return (
    <div className="auth-single-page">
      <Authentication
        reference={useRef(null)}
        isInAModal={false}
        logIn={isLogin}
        is_referral={queries.get("type") == "referral"}
        referral_token={ queries.get("token") }
      />
    </div>
  );
};

export default AuthSinglePage;


// background: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/light-gpu.png);