import jwt from "jwt-decode";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import {
    getTokenAndUsernameFromCookie,
    showErrorNotificationWithTitleAndMessage
} from "../../utils";
import CustomLoader from "../custom-items/Loader";

const ProtectedRoutes = ({ children }: any) => {
  const navigate = useNavigate();
  const { logout } = useContext(ContextProvider);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedData = getTokenAndUsernameFromCookie();
    setIsLoading(true);
    
    if (storedData == null) {
      navigate("/auth");
    } else {
      let [storedToken, storedEmail] = storedData;
      if (storedToken != null) {
        let decodedToken: { email: string; exp: number } = jwt(storedToken);
        const currTime = moment().unix();
        if (moment(currTime).isAfter(decodedToken.exp)) {
          logout();
          navigate("/auth");
          showErrorNotificationWithTitleAndMessage(
            "Sign back in",
            "Session expired",
          );
        } else if (storedEmail == null) {
          logout();
          navigate("/auth");
        }
      } else {
        navigate("/auth");
        showErrorNotificationWithTitleAndMessage(
          "Not authorized",
          "You have to be logged in",
        );
      }
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="machine-loading-wrapper">
          <CustomLoader message={"HOLD ON..."} animate={false} />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default ProtectedRoutes;
