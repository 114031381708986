import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircle, IconX } from "@tabler/icons-react";
import Cookies, { CookieSetOptions } from "universal-cookie";
import "./utils.css";
import * as jose from "jose";

const cookie = new Cookies();

export const addVerifiedStatusToCookies = (
  payload:
    | {
        phoneNumber: string;
        phoneVerified: boolean;
        emailVerified: boolean;
      }
    | undefined,
) => {
  if (payload == undefined) {
    return;
  }

  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);
  const cookieOptions = {
    expires: expirationDate,
  };
  const cookieOptionsTemp: CookieSetOptions = cookieOptions;
  cookie.set(
    "verification-status",
    JSON.stringify({ ...payload }),
    // @ts-ignore
    cookieOptionsTemp,
  );
};

export const getVerificationStatusFromCookies = () => {
  let payload = cookie.get("verification-status");
  return payload;
};

export const showSuccessNotification = (title: string, message: string, autoCloseTime = 4000) => {
  notifications.show({
    title: title,
    message: message,
    color: "white",
    icon: <IconCheck color="#008753" strokeWidth={3} />,
    autoClose: autoCloseTime,
    styles: {
      root: {
        backgroundColor: "#008753",
        color: "white",
        boxShadow: "1px 1px 4px black",
      },
      closeButton: { color: "white", backgroundColor: "transparent" },
      title: { color: "white", fontSize: "16px" },
      description: { color: "white", fontSize: "14px" },
    },
  });
};

export const showErrorNotificationWithTitleAndMessage = (
  title: string,
  message: string,
  autoClose = 5000,
) => {

  notifications.show({
    title: title,
    message: message,
    color: "#9B0000",
    icon: <IconExclamationCircle color="white" strokeWidth={1.9} />,
    autoClose: autoClose,
    closeButtonProps: { width: "30px" },
    styles: {
      root: {
        backgroundColor: "#9B0000",
        boxShadow: "1px 1px black",
        color: "white",
      },
      title: { color: "white", fontSize: "16px" },
      description: { color: "white" },
      closeButton: { width: "30px" },
    },
    classNames: { closeButton: "notifications-close-btn-err" },
  });
};



export const showErrorNotifications = (e: any, title: string, autoclose = 5000) => {
  if (e != null && e.response != null) {
    if (e.response.data?.payload?.error != undefined) {
      showErrorNotificationWithTitleAndMessage(
        title,
        e.response.data.payload.error,
        autoclose
      );
      return;
    } else if (e.response.data != undefined) {
      showErrorNotificationWithTitleAndMessage(
        title,
        e.response.data,
        autoclose
      );
      return
    }
  }
  showErrorNotificationWithTitleAndMessage(title, e.message, autoclose);
};

export const fetchErrorString = (e: any) : string => {
  if (e != null && e.response != null) {
    if (e.response.data?.payload?.error != undefined) {
      return e.response.data.payload.error
    } else if (e.response.data != undefined) {
      return e.response.data
    }
  }

  return e.message
};

export const addTokenToCookies = (token: string, email: string) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 14);
  const cookieOptions = {
    expires: expirationDate,
  };
  const cookieOptionsTemp: CookieSetOptions = cookieOptions;

  cookie.set(
    "token",
    JSON.stringify({
      token: token,
      email: email,
    }),
    cookieOptionsTemp,
  );
};

export const removeVerificationTokenFromCookies = () => {
  cookie.remove("verification-status");
};

export const removeTokenFromCookies = () => {
  cookie.remove("token");
};

export const Logout = () => {
  removeVerificationTokenFromCookies();
  removeTokenFromCookies();
};

export const getTokenAndUsernameFromCookie = () => {
  let storedData = cookie.get("token");
  if (storedData === undefined) {
    return [undefined, undefined];
  }
  let data = storedData;
  return [data.token, data.email];
};

export const generateToken = async (
  host: string,
  port: string,
  username: string,
  password: string,
  width: number,
  height: number,
  mode: string,
  email: string,
) => {
  const payload = {
    host: host,
    port: port.toString(),
    username: username,
    password: password,
    height: height.toString(),
    width: width.toString(),
    mode: mode,
    iat: Math.floor(Date.now() / 1000), // Issued at timestamp
    email: email,
  };

  const secretKey = "pierre"; // Replace with your actual secret key
  const alg = "HS256";
  const secret = new TextEncoder().encode(secretKey);

  const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer("urn:example:issuer")
    .setAudience("urn:example:audience")
    .setExpirationTime("2h")
    .sign(secret);

  return jwt;
};

export const removeItemsFromLocalStorage = () => {
  localStorage.removeItem("logs_quantity");
  localStorage.removeItem("console_active");
  localStorage.removeItem("logs_quantity_sm");
}

export const truncate = (str: string, l: number) => {
  return str.length > l ? str.substring(0, l) + "..." : str;
}

export function formatPhoneNumber(phoneNumberString: string): string {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}