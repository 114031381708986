import { IconChevronsDown } from "@tabler/icons-react";
import "./BottomArrow.css";

const BottomArrow = ({ clicker, bottomChevronBgColor, color }: any) => {
  return (
    <div
      style={{ backgroundColor: bottomChevronBgColor }}
      onClick={() => clicker()}
      className="down-arrow"
    >
      <IconChevronsDown size={32} strokeWidth={2} color={ color } />
    </div>
  );
};

export default BottomArrow;
