export let ENV = "prod"

export let BASE_URL: string
export let FRONTEND_URL: string
export let DOCS_URL: string
export let WS_ENDPOINT: string
// @ts-ignore
if (ENV != "dev") {
    BASE_URL = "https://karaokehq.ai";
    FRONTEND_URL = "https://neural-logic.com"
    DOCS_URL = "https://docs.neural-logic.com"
    WS_ENDPOINT = "wss://karaokehq.ai"
} else {
    BASE_URL = "http://localhost:5002"
    FRONTEND_URL = "http://192.168.0.2:3000"
    DOCS_URL = "https://docs.neural-logic.com"
    WS_ENDPOINT = "ws://localhost:5002"
}
export const SUPPORT_EMAIL = "pierre@neural-logic.com";
export const DISCORD_SERVER_INVITE_LINK = "https://discord.gg/Qxz7YcDw"
export const REACT_APP_S3_ACCESS_KEY = "AKIA2QN6SP66E2NEGWNB"
export const REACT_APP_AWS_KEY = "o4BgStos2hvy/oN9OCNsEE+I1lOiCbo4bWki31+2"
export const CREDIT_AMOUNT = 15
export const HIRING_EMAIL = "recruiting@neural-logic.com"