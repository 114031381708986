import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const useQueryParam = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useLocalStorageState = (key: string, defaultValue : any = '') => {
  // Initialize state with value from localStorage or default
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
