import axios from "axios"
import { BASE_URL } from "../constants"

export const authenticateAdmin = async (secret: string) => {
    const { data } = await axios.post(`${BASE_URL}/admin/authenticate`, {
        secret
    })

    
    return data.payload
}

export const getListOfUsers = async () => {
    const { data } = await axios.post(`${BASE_URL}/admin/users/list`)
    return data.payload
}

export const getUserInfo = async ({ queryKey }: any) => {
    const [_, email] = queryKey

    const { data } = await axios.post(`${BASE_URL}/admin/user/info/${ email }`)
    return data.payload
}

export const getUserPaymentInfo = async ({ queryKey }: any) => {
    const [_, email] = queryKey

    const { data } = await axios.post(`${BASE_URL}/admin/users/cards/list/${ email }`)
    return data.payload
}

export const invoiceUser = async (email: string) => {
    
    
    
    const { data } = await axios.get(`${BASE_URL}/admin/user/invoice/do/${email}`)
    return data.payload; 
}

export const getUserPastInvoices = async (email: string) => {
    const { data } = await axios.get(`${BASE_URL}/admin/user/invoices/get/${email}`)
    return data.payload; 
}