import {
  Burger,
  Center,
  Drawer,
  Menu,
  Text as MantineText,
  Badge,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import {
  Box,
  Divider,
  Icon,
  Text,
  Tooltip
} from "gestalt";
import { useContext } from "react";
import ContextProvider from "../../../ContextProvider";
import "./ConsoleNavWrapper.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorNotificationWithTitleAndMessage } from "../../../utils";
import AuthModal from "../../auth/AuthModal";
import SmallerPendingStatusUpdater from "../../console/console-segments/machines/SmallerPendingStatusUpdater";
import MantineCustomizedButton from "../../custom-items/MantineCustomizedButton";
// @ts-ignore
import classes from "./ConsoleHeaderMenu.module.css";
import ConsoleNavAvatarMenu from "./ConsoleNavAvatarMenu";
import { useViewportSize } from "@mantine/hooks";

export function ConsoleNav() {
  const [headerColor, setHeaderColor] = useState("white");
  const [headerButtonColor, setHeaderButtonColor] = useState<
    | "white"
    | "blue"
    | "gray"
    | "red"
    | "transparent"
    | "semiTransparentWhite"
    | "transparentWhiteText"
    | undefined
  >("gray");
  const { token } = useContext(ContextProvider);
  const { tracker, setTracker } =
    useContext(ContextProvider);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [currentlyActive, setCurrentlyActive] = useState(0);
  const [authIsLoginNotRegister, setAuthIsLoginNotRegister] = useState(true);
  const navigate = useNavigate();
  const [isMachines, setIsMachines] = useState(true);
  const { width } = useViewportSize()

  const backgroundColorToDrawerColor = new Map();
  backgroundColorToDrawerColor.set("#5680ff", "black");
  backgroundColorToDrawerColor.set("#a4bfba", "white");
  backgroundColorToDrawerColor.set("#f9f9f9", "white");

  const handleSwitchClick = () => {
    setIsMachines((isMachine) => !isMachine);
    closeDrawer();
  };

  return (
    <header
      style={{
        color: "black",
        zIndex: 200,
        position: "fixed",
        top: "0px",
        height: "65px",
      }}
      className={classes.headerConsole}
    >
      {
        tracker != null && (
          <div style={{ position: "fixed", zIndex: 10, backgroundColor: "white", bottom: 20, right: 20, display: "flex", flexDirection: "column", gap: "20px" }}>
          { tracker.map((d, i) => < SmallerPendingStatusUpdater i={i} />) }
          </div>
        )
      }
      <div className={classes.inner}>
        <div
          onClick={() => navigate("/home")}
          className="neural-logic-logo gap-6px noselect centered-flex"
        >
          neural-logic <Badge fw={ 900 } style={{ fontStyle: "normal" }}  size="sm" bg={ "black" } c={ "white" }>< Tooltip text="Public Beta" >BETA</Tooltip></Badge>
        </div>
        { width < 600 &&
        < Box maxWidth={140}>
          <MantineText style={{ textAlign: "center" }} size={"14px"}  fw="600" c="red">
            The console works best at larger screens ({" >"}600 pixels)
          </MantineText>
        </Box> } 
        <div
          className="hidden-mobile"
          style={{ gap: "20px", marginLeft: "auto", alignItems: "center" }}
        >
          <ConsoleNavAvatarMenu />
        </div>
        <Burger
          size="md"
          color={"black"}
          opened={drawerOpened}
          onClick={toggleDrawer}
          hiddenFrom="sm"
        />
        <DrawerForSmallerViewport
          drawerOpened={drawerOpened}
          closeDrawer={closeDrawer}
          bgColor={"white"}
          isMachines={isMachines}
          headerButtonColor={headerButtonColor}
          textColor={"black"}
          handleSwitchClick={handleSwitchClick}
        />
      </div>
      <AuthModal isLogin={authIsLoginNotRegister} />
    </header>
  );
}

const ConsoleNavWrapper = ({ children }: { children: any }) => {

  return (
    <>
      <ConsoleNav />
      {children}
    </>
  );
};

const DrawerForSmallerViewport = ({
  drawerOpened,
  closeDrawer,
  bgColor,
  handleSwitchClick,
  isMachines,
  textColor,
}: any) => {
  const { token, logout } = useContext(ContextProvider);
  const [expandResources, setExpandResources] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;

  const handleProfileClick = () => {
    if (currentPath == "/verification-page") {
      showErrorNotificationWithTitleAndMessage("Can't navigate without verifying", "You must verify your profile to navigate to the profiles section.")
      return
    }
    navigate("/console?active=profile")
    closeDrawer()
  }

  return (
    <Drawer
      opened={drawerOpened}
      onClose={closeDrawer}
      padding="md"
      className={classes.hiddenDesktop}
      zIndex={199}
      styles={{
        content: {
          width: "100vw",
          height: "100dvh",
          maxHeight: "100%",
          paddingTop: "55.95px",
          backgroundColor: bgColor,
        },
        body: {
          width: "100vw",
          height: "100%",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "24px",
        },
        header: { display: "none" },
      }}
      closeButtonProps={{ size: "lg", color: "black" }}
      position="top"
      transitionProps={{ duration: 450 }}
    >
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          color: textColor,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: "15px",
          }}
        >

          <div
            onClick={() => handleProfileClick()}
            className="drawer-text-link"
          >
            Profile
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: "12px",
          }}
        >
          <MantineCustomizedButton
            color="black"
            clicker={() => logout()}
            styles={{}}
            className={""}
          >
            Logout
          </MantineCustomizedButton>
        </div>
      </div>
    </Drawer>
  );
};

export default ConsoleNavWrapper;
