import { IconChevronsUp } from "@tabler/icons-react";
import "./TopArrow.css";

const TopArrow = ({ chevronNavigator }: any) => {
  return (
    <div
      style={{ backgroundColor: "white" }}
      onClick={() => chevronNavigator()}
      className="top-arrow-first-page hidden-mobile"
    >
      <IconChevronsUp size={32} strokeWidth={2} color="black" />
    </div>
  );
};

export default TopArrow;
