import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { BASE_URL, SUPPORT_EMAIL } from "../../constants";
import {
    addTokenToCookies,
    showErrorNotifications,
    showSuccessNotification
} from "../../utils";
import CustomLoader from "../custom-items/Loader";
import { useQueryParam } from "./Hooks";

const cookies = new Cookies();

const VerificationLandingPage = () => {
  let query = useQueryParam();
  const navigate = useNavigate()

  useEffect(() => {
    if (query.get("token") != null) {
      let token = query.get("token");
      let verificationType = query.get("verification_type");
      if (verificationType == "email") {
        (async () => {
          try {
            let resp = await axios.get(
              `${BASE_URL}/email-verification?token=${token}`,
            );

            const tokenAndEmail = cookies.get("token");
            if (tokenAndEmail != null) {
              showSuccessNotification("Email verified!", "Please verify your phone number for complete access")
              navigate("/verification-page");
            } else {
              showSuccessNotification("Email verified!", "Please login.")
              navigate("/auth")
            }
          } catch (e) {
            navigate("/home")
            showErrorNotifications(
              e,
              `Could not verify email. Please contact support at ${SUPPORT_EMAIL}`,
            );
          }
        })();
      } else if (verificationType == "google") {
        
        let email = query.get("email")
        addTokenToCookies(token!, email!)
        navigate("/console")
      }
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90dvh",
        width: "100vw",
      }}
    >
      <CustomLoader message={"HOLD ON..."} animate={false} />
    </div>
  );
};

export default VerificationLandingPage;
