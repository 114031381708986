import { Accordion, Avatar, Group, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
// @ts-ignore
import classes from "./CustomAccordion.module.css";

interface AccordionLabelProps {
  label: string;
  image: string;
  description: string;
}

function AccordionLabel({ image, label, description }: AccordionLabelProps) {
  return (
    <Group wrap="nowrap">
      <Avatar src={image} radius="xl" size="md" />
      <div>
        <Text>{label}</Text>
        <Text size="xs" c="dimmed" fw={400}>
          {description}
        </Text>
      </div>
    </Group>
  );
}

function CustomAccordion({ item, show, fn }: any) {
  const items = [
    <Accordion.Item
      value={"additional_configuration"}
      key={"additional_configuration"}
    >
      <Accordion.Control style={{ backgroundColor: "#f9f9f9" }}>
        <AccordionLabel
          label={item.label}
          image={item.image}
          description={item.description}
        />
      </Accordion.Control>
      <Accordion.Panel>{item.content}</Accordion.Panel>
    </Accordion.Item>,
  ];

  return (
    <Accordion
      onChange={(e) => fn(e)}
      transitionDuration={500}
      styles={{
        root: {
          backgroundColor: "white",
          borderRadius: "4px",
          border: "0.5px solid rgb(210, 210, 215)",
        },
        item: {
          backgroundColor: "white",
          borderRadius: "4px",
        },
      }}
      classNames={{ chevron: classes.chevron }}
      chevronPosition="right"
      variant="contained"
      chevron={
        <IconPlus
          width="32"
          className={classes.chevron}
          color="#666666"
          strokeWidth={2}
        />
      }
    >
      {items}
    </Accordion>
  );
}

export default CustomAccordion;
