import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../constants";

const PaymentStatus = ({ setShowPaymentStatusPage, setSearchParams, setClientSecret, setMessage }: any ) => {
  const updateMutation = useMutation({
    mutationFn: () => {
        return axios.get(`${BASE_URL}/customer/cards/default/first-time`)
    },
    retry: false,
})

  const [ textColor, setTextColor ] = useState< "success" | "error" | "warning" >("success")
  const stripe = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (clientSecret == null) {
      navigate("/console")
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret!)
      .then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent!.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            setShowPaymentStatusPage(false)
            setClientSecret("")
            navigate("/console")
            updateMutation.mutate()
            break;

          case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            setShowPaymentStatusPage(false)
            setClientSecret("")
            navigate("/console")
            updateMutation.mutate()
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            setShowPaymentStatusPage(false)
            navigate("/console")
            break;
        }
      });
  }, [stripe]);

  return (
    <></>
  )
}

export default PaymentStatus;