import Guacamole from "guacamole-client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  showErrorNotificationWithTitleAndMessage
} from "../../utils";
import "./RDPLandingPage.css";

function dispatchClickEvent(element: any, button: number) {
  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    button: button, // 0 for left click, 2 for right click
    clientX: 65,
    clientY: 71,
  });
  element.dispatchEvent(event);
};

const RDPLandingPage = () => {
  const navigate = useNavigate();
  const { machineId, token, mode } = useParams();
  const [loading, setLoading] = useState(true);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { state } = useLocation();

  useEffect(() => {
    var display = document.getElementById("display");
    setLoading(true);
    
    var token;
    try{
      var { token } = state;
    } catch (e) {
      showErrorNotificationWithTitleAndMessage("Invalid", "You did not send the right parameters to connect.")
    }

    if (machineId == null || token == null) {
      showErrorNotificationWithTitleAndMessage(
        "Error",
        "Did you get to this page from the console. If not please do so.",
      );
      navigate("/console");
      return;
    }
    
    // Instantiate client, using an HTTP tunnel for communications.
    let guacTunnel = new Guacamole.WebSocketTunnel(
      `ws://127.0.0.1:8080/rps/websocketendpoint/${token}`,
    );
    var guac = new Guacamole.Client(guacTunnel);

    // Add client to display div
    display?.appendChild(guac.getDisplay().getElement());

    // Error handler
    guac.onerror = function (error) {
      console.log(error)
      if (error?.message?.substring(0, 7) == "Aborted") {
        showErrorNotificationWithTitleAndMessage("Something went wrong", "Are you sure you typed the right password?");
      } else {
        showErrorNotificationWithTitleAndMessage("Error", error?.message || "");
      }
      navigate(-1)
    };

    // Connect
    guac.connect();

    // Disconnect on close
    window.onunload = function () {
      guac.disconnect();
    };

    guac.onstatechange = (state) => {
      if (state == 3) {
        const divWithCanvas = document.querySelector('.rdp-page-wrapper');
        if (!divWithCanvas) return;

        setTimeout(() => {
          // @ts-ignore
          // guac.sendMouseState({
          //   "x": 9,
          //   "y": 9,
          //   "left": false,
          //   "middle": false,
          //   "right": false,
          //   "up": false,
          //   "down": false
          // })
          // guac.sendKeyEvent(1, 97);
          // guac.sendKeyEvent(1, 65288);
          // guac.sendSize(window.innerWidth - 1, window.innerHeight - 60);
        }, 1000)
      
        setLoading(() => false);
  }
    };

    window.onresize = function () {

      guac.sendSize(
        window.innerWidth,
        window.innerHeight - 60,
      );
    };

    // Mouse
    var mouse = new Guacamole.Mouse(guac.getDisplay().getElement());

    // @ts-ignore
    mouse.onEach(
      ["mousedown", "mouseup", "mousemove"],
      function sendMouseEvent(e: any) {
        
        guac.sendMouseState(e.state);
      },
    );

    // Keyboard
    var keyboard = new Guacamole.Keyboard(document);

    keyboard.onkeydown = function (keysym) {
      
      guac.sendKeyEvent(1, keysym);
    };

    keyboard.onkeyup = function (keysym) {
      guac.sendKeyEvent(0, keysym);
    };

    return () => {
      keyboard.onkeydown = null;
      keyboard.onkeyup = null;
      guac.disconnect();
    };
  }, []);

  return (
    <div className="rdp-page-wrapper">
      <div style={{ zIndex: 200 }} id="display" />
    </div>
  );
};

export default RDPLandingPage;
