import { createContext } from "react";
import { ConsleContextI } from "../../types";

const consoleContext: ConsleContextI = {
  active: "machines",
  setActive: () => {},
  showCreateMachinePage: false,
  setShowCreateMachinePage: () => {},
  filtered: "M4000",
  setFiltered: () => {},
  progressing: false,
  setProgressing: () => {},
};

const ConsoleContext = createContext(consoleContext);

export default ConsoleContext;
