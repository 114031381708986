import { Button, Input, Loader, Stack } from "@mantine/core";
import '@mantine/core/styles.layer.css';
import { DatePicker } from "@mantine/dates";
import '@mantine/dates/styles.css';
import { useDebouncedValue } from '@mantine/hooks';
import axios from "axios";
import { CompositeZIndex, FixedZIndex, Flex, Text as GestaltText, Heading, IconButton, Layer, Modal } from "gestalt";
import sortBy from 'lodash/sortBy';
import { DataTable, type DataTableSortStatus } from 'mantine-datatable';
import 'mantine-datatable/styles.layer.css';
import moment from "moment";
import { Fragment, useEffect, useState } from 'react';
import { QueryClient, useMutation, useQuery } from "react-query";
import { getUserInfo, getUserPastInvoices, getUserPaymentInfo } from "../../apis/admin";
import { BASE_URL } from "../../constants";
import { AcitivtyDataI } from "../../types";
import { fetchErrorString, showErrorNotifications } from "../../utils";
import { ControlsHeader } from '../console/home-segments/Machines';
import Card from "../console/home-segments/payment/Card";
import { formatMoney } from "../console/home-segments/payment/PaymentsAndSub";
import { AlternativeErrorSectionLg } from "../custom-items/ErrorSection";
import CustomLoader from "../custom-items/Loader";
import UsageBarChart from "../custom-items/bar-chart/UsageBarChart";
import "./UsersDataTable.css";


interface UsersForAdminTableI {
    id: string;
    email: string;
    phone: string;
    prev_invoice_date: string;
}

const PAGE_SIZE = 10;

export const UsersDataTable = ({ data }: { data: UsersForAdminTableI[] }) => {
    const [records, setRecords] = useState<UsersForAdminTableI[]>(data);
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<UsersForAdminTableI>>({
        columnAccessor: 'prev_invoice_date',
        direction: 'asc',
    });
    const [page, setPage] = useState<number>(1);
    const [ showUserUsageModal, setShowUserUsageModal ] = useState(false);
    const [ user, setUser ] = useState({ id: "", email: "" });

    // for filters
    const [emailQuery, setEmailQuery] = useState('');
    const [prevInvoiceDateSearchRange, setPrevInvoiceDateSearchRange] = useState<[Date | null, Date | null]>([null, null]);
    const [debouncedQuery] = useDebouncedValue(emailQuery, 200);
    const [idQuery, setIdQuery] = useState("");

    const applySort = (dataToSort: UsersForAdminTableI[]) => {
        const d = sortBy(dataToSort, sortStatus.columnAccessor)
        if (sortStatus.direction === 'asc') {
            return d.reverse();
        } else {
            return d;
        }
    }

    const DataTableColumns = [
        {
            accessor: 'id',
            width: '30%',
            sortable: true,
            filter: (
                <Input value={idQuery} onChange={(e) => setIdQuery(e.currentTarget.value)} />
            )
        },
        {
            accessor: 'email',
            width: '20%',
            sortable: true,
            filter: (
                <Input value={emailQuery} onChange={(e) => setEmailQuery(e.currentTarget.value)} />
            )
        },
        {
            accessor: 'phone',
            width: 160,
            sortable: true
        },
        { 
            accessor: 'prev_invoice_date', 
            textAlign: 'right', 
            sortable: true, 
            filter:  ({ close }: any) => (
                <Stack>
                    <DatePicker 
                        type="range"
                        maxDate={new Date()}
                        value={prevInvoiceDateSearchRange}
                        onChange={setPrevInvoiceDateSearchRange}
                    />
                    <Button
                        disabled={!prevInvoiceDateSearchRange}
                        variant="light"
                        onClick={() => {
                            setPrevInvoiceDateSearchRange([ null, null ]);
                            close();
                        }}
                    >
                        Clear
                    </Button>
                </Stack>
            ),
            render: (record: UsersForAdminTableI) => {
                return moment(record.prev_invoice_date).fromNow();
            }
        }
    ]

    

    const getSliceOfData = (data: UsersForAdminTableI[], start: number, end: number) => {
        return data.slice(start, end);
    }

    useEffect(() => {
        let sortedData = applySort(data);
        

        let remainingRecords;
        if (debouncedQuery != "") {
            remainingRecords = sortedData.filter(r => r.email.toLowerCase().includes(debouncedQuery.toLowerCase()));
        } else {
            remainingRecords = sortedData;
        }

        if (idQuery != "") {
            remainingRecords = remainingRecords.filter(r => r.id.toLowerCase().includes(idQuery.toLowerCase()));
        }

        if (prevInvoiceDateSearchRange &&
            prevInvoiceDateSearchRange[0] &&
            prevInvoiceDateSearchRange[1]) {
            remainingRecords = remainingRecords.filter(r => moment(r.prev_invoice_date).isBetween(prevInvoiceDateSearchRange[0], prevInvoiceDateSearchRange[1], null))
        }

        

        remainingRecords = getSliceOfData(remainingRecords, (page - 1) * PAGE_SIZE, page * PAGE_SIZE)
        

        setRecords(remainingRecords)
    }, [sortStatus, page, debouncedQuery, prevInvoiceDateSearchRange, idQuery]);

    const openModal = (userId: string, recordId: string) => {
        setUser({ id: userId, email: recordId });
        setShowUserUsageModal(true);
    }

    return (
        <>
        <div className="padding-5vw-and-2vw">
            <div style={{ marginBottom: "10px" }}>
                < ControlsHeader text="Users list"/>
            </div>
            <DataTable
                striped={true}
                withTableBorder
                withColumnBorders
                records={records}
                // @ts-ignore
                columns={
                    DataTableColumns
                }
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}

            // for pagination
            totalRecords={data.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={[10, 20, 30]}
            onRecordsPerPageChange={() => { }}

            onRowClick={({ record, index, event }) => {
                openModal(
                    record.id,
                    record.email,
                );
              }}
            />
        </div>
        < UserUsageModal showModal={showUserUsageModal} setShowModal={setShowUserUsageModal} user={ user } />
    </>
    );
}

interface UserUsageData {
    data: {
        datasets:
        {
          label: string;
          data: number[];
          backgroundColor: string;
          minBarLength: number;
        }[],
        max_value: number
    };
    title: string;
}

interface UserUsageDataI {
    usageData: UserUsageData;
    logs: AcitivtyDataI[];
}

const PresentRecentUserLogs = ({ usage }: any) => {
    const convertDBDateToHumanReadableDate = (d: string) => {
        return moment(d).format('MMM D, h:mm A');
    }

    

    return (
        <>
            { 
                Object.entries(usage).map(([key, value ]) => {
                    // @ts-ignore
                    let val: {  logs: string[][], gpu: string; seconds: number } = value;

                    return (
                        <div style={{ paddingLeft: "20px" }}>
                            < GestaltText size="200" >Machine ID: { key.substring(0, 8) }{`  ***  `} GPU: { val.gpu } {`  ***  `} Total minutes this period: < span style={{ fontWeight: 600 }}>{ (val.seconds/60).toFixed(2) } minutes</span>
                            {`  ***  `}Total hours:< span style={{ fontWeight: 600 }}>{ (val.seconds/3600).toFixed(2) } hours</span>
                            </GestaltText>
                            {
                                val.logs.map((v) => <div style={{ paddingLeft: "20px" }}>< GestaltText size="100" > Started at { convertDBDateToHumanReadableDate(v[0]) } and ended at { convertDBDateToHumanReadableDate(v[1]) } for a duration of < span style={{ fontWeight: "600" }}> { moment(v[1]).diff(moment(v[0]), "seconds") }</span> seconds </GestaltText> </div>)
                            }
                        </div>
                    )
                })
            }
        </>
    )
}

const ShowCredits = ({ credits_that_can_be_applied, credits_remaining, total_cost_net_credits }: any) => {
    return (
        <div>
            <div>Credits that can be applied this period: { credits_that_can_be_applied }</div>
            <div>Credits that will be left: { credits_remaining }</div>
            <div>Total cost after credits are applied: { total_cost_net_credits }</div>
        </div>
    )
}

const PresentUserData = ({ data }: { data: any, }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: "30px" }}>
            < Heading size="500" >${ data.total_cost.toFixed(2) } this period. { formatMoney(data.total_cost_net_credits) } after credits.
            <p> { moment(data?.start_date).format('MMM D, h:mm A') } to { moment(data?.end_date).format('MMM D, h:mm A') }</p></Heading>
            < UsageBarChart data={ data.bar_chart_data } title={ `Usage for: ${ data!.user_info!.email }` } />
            < PresentRecentUserLogs usage={ data.usage_pattern_for_all_machines } />
            < ShowCredits credits_that_can_be_applied={ data.credits_that_can_be_applied } credits_remaining={ data.credits_remaining } total_cost_net_credits={ data.total_cost_net_credits } />
        </div>
    )
}

const UserUsageModal = ({ showModal, setShowModal, user }: any) => {
  const HEADER_ZINDEX = new FixedZIndex(10);
  const zIndex = new CompositeZIndex([HEADER_ZINDEX]);
  const [ invoicing, setInvoicing ] = useState(false);

    const queryClient = new QueryClient();

  const {
    isLoading: userDataIsLoading,
    error: userDataError,
    data: userData,
    refetch: refetchUserData,
    isFetching
  } = useQuery({
    queryKey: [ "ADMIN_GET_USER_INFO", user.email],
    queryFn: getUserInfo,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: false,
  });

  const invoiceMutation = useMutation({
    mutationFn: () => {
        setInvoicing(true);
        return axios.get(`${BASE_URL}/admin/user/invoice/do/${user.email}`)
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [ "ADMIN_GET_USER_INFO", user.email],
        refetchInactive: true,
      });
      setInvoicing(false);
    },
    onError: (e) => {
        setInvoicing(false);
        showErrorNotifications(e, "Failed to invoice user. Try again!");
            return;
        },
    retry: false,
  });
  

  return (
    <Fragment>
      {showModal ? (
        <Layer zIndex={zIndex}>
        <div className="modal-modification">
          <Modal
            accessibilityModalLabel="Choose how to claim site"
            align="start"
            _dangerouslyDisableScrollBoundaryContainer={true}
            // @ts-ignore
            size="xl"
            heading={
              <Flex justifyContent="between">
                <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                    <Heading size="300" accessibilityLevel={1}>
                        { `Email: ${ userData?.user_info.email } *** Phone: ${ userData?.user_info.phone } *** Verified: ${ userData?.user_info.phone_verified && userData?.user_info.verified } *** Admin status: ${ userData?.user_info?.is_admin }` }
                    </Heading>

                    <div style={{ marginLeft: "auto", }}>< Button size="xs" onClick={ () => refetchUserData() }>{ isFetching ? < Loader size="xs" color="white" /> : "Refetch" }</Button></div>
                </div>
                <IconButton
                  accessibilityLabel="Dismiss modal"
                  bgColor="white"
                  icon="cancel"
                  iconColor="darkGray"
                  onClick={() => setShowModal(false)}
                  size="sm"
                />
              </Flex>
            }
            onDismiss={() => {
              setShowModal(false);
            }}
            footer={
              <Flex justifyContent="end" gap={2}>
                <Button color="gray" onClick={ () => setShowModal(false) } >Cancel</Button>
                <Button color="red" onClick={ () => invoiceMutation.mutate() } >Invoice</Button>
              </Flex>
            }
        >
            < div>
                {
                    userDataIsLoading ? <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <div className="machine-loading-wrapper">
                            <CustomLoader message={"FETCHING USER DATA..."} animate={true} />
                        </div>
                    </div>
                    : userDataError ? < AlternativeErrorSectionLg message={ fetchErrorString(userDataError) } />
                    : 
                    <div>
                        < PresentUserData data={userData} />
                        < PresentPaymentInformations email={user.email}  />
                        < PresentPastInvoices email={user.email} />
                    </div>
                }
            </div>
          </Modal>
          </div>
        </Layer>
      ) : null}
    </Fragment>
  )
}

const PresentPastInvoices = ({ email }: { email: string }) => {
    const {
        isLoading,
        error,
        data,
        isFetching
    } = useQuery({
        queryKey: [ "ADMIN_GET_PAST_INVOICES", email],
        queryFn: () => getUserPastInvoices(email),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        keepPreviousData: false,
    });

    
    
    return  (
        <div>

        </div>
    )
}

const PresentPaymentInformations = ({ email }: { email: string }) => {
    const {
        isLoading: paymentInfoIsLoading,
        error: paymentInfoError,
        data: paymentData,
        isFetching: paymentInfoIsFetching,
    } = useQuery({
        queryKey: [ "ADMIN_GET_USER_PAYMENT_INFO", email],
        queryFn: getUserPaymentInfo,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        keepPreviousData: false,
    });

    const [ selectedCard, setSelectedCard ] = useState("");

    useEffect(() => {
        if (paymentData != null) {
            let defaultCardId : string = paymentData.find((d: any) => d.default == true)?.id;

            setSelectedCard(defaultCardId)
        }
    }, [paymentData])

    const getLast4FromCardNum = (cardId : string) => {
        if (cardId == "" || !cardId){
            return ""
        }
        let defaultCardId : string = paymentData.find((d: any) => d.id == cardId).last4;

        return defaultCardId
    }

    if (paymentInfoError) {
        return (
            < AlternativeErrorSectionLg message={ fetchErrorString(paymentInfoError) } />
        )
    }

    if (paymentInfoIsLoading) {
        return (
            < div style={{ width: "100%" }}>
                < Loader size="sm" color="black" />
            </div>
        )
    }
    
    
    

    return (
        <div style={{ marginTop: "40px" }}>
            < ControlsHeader text="Payment methods" />
            <div className="cards-grid-wrapper">

            < div>currently selected card is <span style={{ fontWeight: "600" }}>{ getLast4FromCardNum(selectedCard) }</span> </div>
            {paymentData.map((d: any) => 
                <Card
                    isLoading={false}
                    fn={() => setSelectedCard(d.id)
                    }
                    isDefault={d.default}
                    isFake={false}
                    id={d.id}
                    cardHolder={email!}
                    last4={d.last4}
                    cardMonth={d.exp_month}
                    cardYear={d.exp_year}
                    cardCvv={"333"}
                    cardBrand={d.brand}
                />
            )}

            </div>
        </div>
    )
}