import {
  Button,
  Loader,
  Modal,
  Table,
  Tooltip
} from "@mantine/core";
import Anchor from "../../../custom-items/CustomAnchor";
import "@mantine/dates/styles.css";
import { useDisclosure } from "@mantine/hooks";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconPhoto,
  IconPlayerPlay,
  IconPlus,
  IconRefresh,
  IconTrash,
  IconTrashFilled
} from "@tabler/icons-react";
import axios from "axios";
import {
  Text as GestaltText
} from "gestalt";
import { DataTable } from "mantine-datatable";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContextProvider from "../../../../ContextProvider";
import {
  getListOfPaymentCards,
  getPastInvoices
} from "../../../../apis/machines";
import { BASE_URL, FRONTEND_URL, SUPPORT_EMAIL } from "../../../../constants";
import { fetchErrorString, showErrorNotificationWithTitleAndMessage, showErrorNotifications } from "../../../../utils";
import AlternativeErrorSection from "../../../custom-items/ErrorSection";
import { ControlsHeader } from "../Machines";
import Card from "./Card";
import PaymentStatus from "./PaymentStatus";
import "./PaymentsAndSub.css";
import StripeWrapper from "./StripeWrapper";

const mapOfCommandToImages = new Map<string, React.ReactNode>();
mapOfCommandToImages.set(
  "create",
  <IconCirclePlus stroke={1.5} size="16" color="black" />
);
mapOfCommandToImages.set(
  "delete",
  <IconTrash stroke={1.5} size="16" color="black" />
);
mapOfCommandToImages.set(
  "shutdown",
  <IconCircleMinus stroke={1.5} size="14" color="black" />
);
mapOfCommandToImages.set(
  "reboot",
  <IconRefresh stroke={1.5} size="12" color="black" />
);
mapOfCommandToImages.set(
  "start",
  <IconPlayerPlay stroke={1.5} size="16" color="black" />
);
mapOfCommandToImages.set(
  "Command",
  <IconPhoto stroke={1.5} size="16" color="black" />
);

const PaymentAndSub = () => {
  return (
    <div
      className="padding-5vw-and-2vw"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "48px",
        paddingBottom: "150px"
      }}
    >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <ControlsHeader text="Payment Methods" />
          <PaymentMethods />
        </div>

        <div className="half-and-full">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ControlsHeader text={"Past Invoices"} />
            <PastInvoices />
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ControlsHeader text={"Billing"} />
            <InfoSection />
          </div>
        </div>
    </div>
  );
};

const PastInvoices = () => {
  const [ opened, { open, close }] = useDisclosure(false);
  const [ activeInvoice, setActiveInvoice ] = useState("");

  const { isLoading, error, data } = useQuery({
    queryKey: "GET_PAST_INVOICES",
    queryFn: () => getPastInvoices(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const currentlySelectedInvoice = data != null ? data.find((d: any) => d?.payment_id == activeInvoice) : null;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100px",
          width: "100%",
        }}
      >
        <Loader size="sm" color="black" />
      </div>
    );
  }

  if (error) {
    const errorStr = fetchErrorString(error);

    return (
      <div>
        <AlternativeErrorSection
          message={`Error: Could not get user's payment options. ${errorStr}.`}
        />
      </div>
    );
  }

  const handleClose = () => {
    setActiveInvoice("")
    close()
  }

  return (
    <>
      <DataTable
        highlightOnHover
        columns={[{ accessor: 'payment_id', textAlign: "left", render: ({ payment_id }) => payment_id.substring(5, 12).toLowerCase() }, { accessor: 'end_period', textAlign: "center", render: ({ end_period }) => { return moment(end_period).format('MMM D, h:mm A') } }, { accessor: 'total', textAlign: "center", render: ({ total }) => `${ formatMoney(total) }` }, { accessor: "", textAlign: "right", render: ({ payment_id }) => { return < Button style={{ fontWeight: "550", textDecoration: "underline" }} size="xs" color="black" variant="transparent" >Details</Button> } }]}
        records={data}
        onRowClick={({ record, index, event }) => {
          setActiveInvoice(record.payment_id)
          open()
        }}
        rowStyle={({ payment_id }, index) => { 
          if (index % 2) {
            return { backgroundColor: '#f0f0f0' }
          }
          return {};
        }}


      />

      < Modal 
      withCloseButton={false}
      centered
      size="md"
      styles={{
        overlay: { background: "rgba(0,0,0,0.9)" },
        content: { backgroundColor: "#f8fafc", padding: "10px", },
        header: { backgroundColor: "#f8fafc", paddingLeft: "20px" },
        title: { fontWeight: 400, color: "black" },
      }}
      opened={opened}
      onClose={() =>
        handleClose()
      }
      title={""}
      >
        < ShowInvoiceDetails invoice={currentlySelectedInvoice} handleClose={handleClose} />
      </Modal>
    </>
  )
}

export const formatMoney = (amount: number, locale = 'en-US', currency = 'USD') => {
  let formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(amount);

  return formatted.replace("$", '$ ');
};

const ShowInvoiceDetails = ({ invoice, handleClose }: { invoice: any, handleClose: () => void }) => {
  
  if (!invoice) {
    return <></>
  }

  return (
    <div style={{ display:"flex", flexDirection: "column", gap: "16px" }}>
      < GestaltText weight="bold" size="300">
        Invoice Summary
      </GestaltText>
      
      <Table withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr key={1}>
            <Table.Td pl={0} align="left" >From</Table.Td>
            <Table.Td pl={0} align="right">{ moment(invoice.start_period).format('MMM D, h:mm A')}</Table.Td>
          </ Table.Tr>
          <Table.Tr key={2}>
            <Table.Td pl={0} align="left" >To</Table.Td>
            <Table.Td pl={0} align="right">{ moment(invoice.end_period).format('MMM D, h:mm A')}</Table.Td>
          </ Table.Tr>
          <Table.Tr key={3}>
            <Table.Td pl={0} align="left" >Billed at</Table.Td>
            <Table.Td pl={0} align="right">{ moment(invoice.end_period).format('MMM D, h:mm A')}</Table.Td>
          </ Table.Tr>
          <Table.Tr key={4}>
            <Table.Td pl={0} align="left" >Payment id</Table.Td>
            <Table.Td pl={0} align="right">{ invoice.payment_id.substring(5,12).toLowerCase()}</Table.Td>
          </ Table.Tr>
          <Table.Tr key={5}>
            <Table.Td pt={30} pl={30} align="left" >Add Ons Cost</Table.Td>
            <Table.Td pt={30} align="right">{ formatMoney(invoice?.total_add_ons.toFixed(2)) }</Table.Td>
          </ Table.Tr>
          <Table.Tr style={{ paddingLeft: "10px" }}  key={6}>
            <Table.Td pl={30} align="left" >Compute cost</Table.Td>
            <Table.Td align="right">{ formatMoney(invoice?.total_compute_cost.toFixed(2)) }</Table.Td>
          </ Table.Tr>
          <Table.Tr key={7}>
            <Table.Td pl={0} align="left" >Total</Table.Td>
            <Table.Td pl={0} fw={600} align="right">{ formatMoney(invoice?.total.toFixed(2)) }</Table.Td>
          </ Table.Tr>
        </Table.Tbody>
      </Table>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px", justifyContent: "flex-end" }}>
        <GestaltText size="100" color="dark">
          *Card ending in { invoice.card_used } was charged successfully.
        </GestaltText>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "10px", justifyContent: "flex-end" }}>
        < Button onClick={ handleClose } color="#005C62" >
          Close
        </Button>
      </div>
    </div>
  )
}

const PaymentMethods = () => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [clientSecret, setClientSecret] = useState("");
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPaymentStatusPage, setShowPaymentStatusPage] = useState(false);
  const { email } = useContext(ContextProvider);
  const [cardToModify, setCardToModify] = useState({
    show: false,
    d: null,
  });

  const { isLoading, error, data } = useQuery({
    queryKey: "GET_PAYMENT_CARDS",
    queryFn: () => getListOfPaymentCards(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const createCheckoutMutation = useMutation({
    mutationFn: () => {
      setLoading(true);
      return axios.get(`${BASE_URL}/create-checkout-session`);
    },
    onSuccess: ({ data }) => {
      
      setClientSecret(data.payload);
      setLoading(false);
    },
    onError: (e) => {
      setLoading(false);
      showErrorNotifications(e, "Failed to invoice user. Try again!");
    },
    retry: false,
  });

  useEffect(() => {
    const success = searchParams.get("redirect_status");
    
    if (success != null) {
      setShowPaymentStatusPage(true);
      setClientSecret(searchParams.get("setup_intent_client_secret")!);
    }

    document.body.style.backgroundColor = "#fdfdfd";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  
  if (showPaymentStatusPage) {
    return (
      <StripeWrapper
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
      >
        <PaymentStatus
          setClientSecret={setClientSecret}
          setShowPaymentStatusPage={setShowPaymentStatusPage}
          setSearchParams={setSearchParams}
          setMessage={setMessage}
        />
      </StripeWrapper>
    );
  }

  if (error != null) {
    const errorStr = fetchErrorString(error);

    return (
      <div className="half-page-sections">
        <AlternativeErrorSection
          message={`Error: Could not get user's payment options. ${errorStr}.`}
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <div className="cards-grid-wrapper">
        { !isLoading &&
          data.map((d: any) => (
            <Card
              fn={() =>
                setCardToModify((curr) => {
                  return {
                    show: true,
                    d: d,
                  };
                })
              }
              isDefault={d.default}
              isFake={false}
              id={d.id}
              cardHolder={email!}
              last4={d.last4}
              cardMonth={d.exp_month}
              cardYear={d.exp_year}
              cardCvv={"333"}
              cardBrand={d.brand}
              isLoading={isLoading}
            />
          )) }
          <Card
          fn={() => createCheckoutMutation.mutate()}
          isDefault={false}
          last4={"0000"}
          cardMonth={"11"}
          cardYear={"2024"}
          cardCvv={"333"}
          id={""}
          cardHolder={email}
          cardBrand={"visa"}
          isFake={true}
          isLoading={isLoading}
        />
      </div>
      {message !== "" && (
        <GestaltText weight="bold" color="success" size="200">
          Your card was added successfully.
        </GestaltText>
      )}

      <DeleteAndDefaultConfirmationModal
        cardToModify={cardToModify}
        setCardToModify={setCardToModify}
      />

      {clientSecret != "" ? (
        <AddPaymentMethodModal
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          createPaymentMutation={createCheckoutMutation}
        >
          <StripeWrapper
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
          >
            <div>
              <SetupForm setClientSecret={setClientSecret} />
            </div>
          </StripeWrapper>
        </AddPaymentMethodModal>
      ) : null}
    </div>
  );
};

const DeleteAndDefaultConfirmationModal = ({
  cardToModify: showDeleteAndDefaultModal,
  setCardToModify: setShowDeleteAndDefaultModal,
}: {
  cardToModify: { show: boolean; d: any };
  setCardToModify: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      d: any;
    }>
  >;
}) => {
  const [consentValue, setConsentValue] = useState("");
  const { email } = useContext(ContextProvider);

  const [ loading, setLoading ] = useState({
    removing: false,
    settingDefault: false,
  })

  const queryClient = useQueryClient();

  const setDefaultMutation = useMutation({
    mutationFn: () => {
      setLoading((curr) => {
        return { ...curr, settingDefault: true, }
      })
      return axios.get(`${BASE_URL}/customer/cards/default/set/${ showDeleteAndDefaultModal.d.id }`);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: "GET_PAYMENT_CARDS",
        refetchInactive: true,
      })
      setLoading((curr) => {
        return { ...curr, settingDefault: false, }
      })
      setShowDeleteAndDefaultModal(() => {
        return { show: false, d: null }
      })
    },
    onError: (e) => {
      setLoading((curr) => {
        return { ...curr, settingDefault: false, }
      })
      showErrorNotifications(e, "Failed to set card as default. Please try again later!");
    },
    retry: false,
  });

  const detachPaymentMutation = useMutation({
    mutationFn: () => {
      setLoading((curr) => {
        return { ...curr, removing: true, }
      })
      return axios.get(`${BASE_URL}/customer/cards/detach/${ showDeleteAndDefaultModal.d.id }`);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: "GET_PAYMENT_CARDS",
        refetchInactive: true,
      })
      setShowDeleteAndDefaultModal(() => {
        return { show: false, d: null }
      })
      setLoading((curr) => {
        return { ...curr, removing: false, }
      })
    },
    onError: (e) => {
      setLoading((curr) => {
        return { ...curr, removing: false, }
      })
      showErrorNotifications(e, "Failed to detach payment card. Please try again!");
    },
    retry: false,
  });

  

  if (!showDeleteAndDefaultModal.show) {
    return <div></div>;
  }

  return (
    <Modal
      withCloseButton={false}
      centered
      size="auto"
      styles={{
        overlay: { background: "rgba(0,0,0,0.9)" },
        content: { backgroundColor: "#f8fafc", padding: "10px", },
        header: { backgroundColor: "#f8fafc", paddingLeft: "20px" },
        title: { fontWeight: 400, color: "black" },
      }}
      opened={showDeleteAndDefaultModal.show}
      onClose={() =>
        setShowDeleteAndDefaultModal(() => {
          return { show: false, d: null };
        })
      }
      title={""}
    >
      <div style={{ display: "flex", }}>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            height: "200px",
          }}
        >
          <Card
            id={""}
            fn={() => {}}
            cardHolder={email!}
            last4={showDeleteAndDefaultModal.d.last4}
            cardMonth={showDeleteAndDefaultModal.d.exp_month}
            cardYear={showDeleteAndDefaultModal.d.exp_year}
            cardCvv={""}
            cardBrand={showDeleteAndDefaultModal.d.brand}
            isFake={false}
            isDefault={showDeleteAndDefaultModal.d.default}
            isLoading={loading.removing}
          />
        </div>

          <div style={{ marginLeft: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "5px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            < Tooltip label={ !showDeleteAndDefaultModal.d.default ? "Set as default payment option." : "This is the default payment option." }>
                < Button disabled={ showDeleteAndDefaultModal.d.default || loading.settingDefault } color="#008753" 
                rightSection={
                  loading.settingDefault ? null : < IconCirclePlus size="14" />
                } 
                onClick={() => setDefaultMutation.mutate()}
                size="xs" >
                  { loading.settingDefault ? < Loader size="xs" color="black" /> : "Default" }
                </Button>
              </Tooltip>    


              < Tooltip label={ !showDeleteAndDefaultModal.d.default ? "Remove card from profile." : "This is the default payment option and cannot be deleted." }>
                < Button autoFocus={false} disabled={ showDeleteAndDefaultModal.d.default || loading.removing } size="xs" color="#B60000" rightSection={
                  loading.removing ? null : < IconTrashFilled size="14"  />
                } 
                onClick={() => detachPaymentMutation.mutate()}
                >
                  { loading.removing ? < Loader size="xs" color="black" /> : "Remove" }
                </Button>
              </Tooltip>

            </div>

            < Button autoFocus={true} onClick={(curr) => setShowDeleteAndDefaultModal((curr) =>
              { return { d: null, show: false } }
            )} color="#4A4A4A" size="xs" >
                Close
            </Button>

            </div>
      </div>
    </Modal>
  );
};

const AddPaymentMethodModal = ({
  clientSecret,
  setClientSecret,
  createPaymentMutation,
  children,
}: {
  children: any;
  clientSecret: string;
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
  createPaymentMutation: any;
}) => {
  const [invoicing, setInvoicing] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const handleClose = () => {
    setClientSecret("");
    close();
  };

  return (
    <Modal
      withCloseButton={false}
      centered
      size="md"
      styles={{
        overlay: { background: "rgba(0,0,0,0.9)" },
        content: { backgroundColor: "#212d63", padding: "20px", borderRadius: "8px" },
        header: { backgroundColor: "#212d63", paddingLeft: "20px" },
        title: { fontWeight: 400, color: "black" },
      }}
      opened={clientSecret != ""}
      onClose={handleClose}
      title=""
    >
      {children}
    </Modal>
  );
};

const brandNameToClassName = new Map();
brandNameToClassName.set("visa", "visa");
brandNameToClassName.set("Mastercard", "mastercard");
brandNameToClassName.set("American Express", "amex");
brandNameToClassName.set("Discover", "discover");
brandNameToClassName.set("JCB", "jcb");
brandNameToClassName.set("Diners Club", "diners");

const SetupForm = ({ setClientSecret }: { setClientSecret: any }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${FRONTEND_URL}/console`,
      },
    });

    

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // @ts-ignore
      setErrorMessage(error.message);
      showErrorNotificationWithTitleAndMessage("Failed", error.message!)
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setLoading(false);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
      <PaymentElement />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <Button onClick={() => setClientSecret("")} color="gray" size="sm">
          Cancel
        </Button>{" "}
        <Button
          type="submit"
          disabled={!stripe}
          gradient={{ from: "blue", to: "cyan", deg: 90 }}
          variant="gradient"
          size="sm"
          leftSection={!loading && <IconPlus size={16} />}
        >
          {loading ? <Loader size="xs" color="white" /> : "Add Card"}
        </Button>
      </div>
      {errorMessage && <GestaltText weight="bold" color="error">{errorMessage}</GestaltText>}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          marginTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-end", width: "100%", alignItems: "center", marginTop: "10px" }}>
          < img style={{ backgroundColor: "white", height: "15px" }} src="https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/powered-by-stripe.jpg" />
        </div>
      </div>
    </form>
  );
};

const InfoSection = () => {
  return (
    <div className="info-section">
      Our billing cycle ends every 15 days. You will be charged for the amount you use during these 15 days minus any credits that you have received from referral. You will be charged to the card you have on file. If you have multiple cards on file, you will be charged to your default card (the card which has a default label to its top right above). 
      <p>
        All payments are made through Stripe and hence are secure.
      </p>

      <Anchor 
      isHref={true} className="must-underline" 
        // TODO: add link to billing in the docs
      href={``}>Learn more about billing</Anchor>
    </div>
  );
};

export default PaymentAndSub;
