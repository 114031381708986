import { Avatar, Menu, rem } from "@mantine/core";
import {
    IconLogout,
    IconUserCircle
} from "@tabler/icons-react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContextProvider from "../../../ContextProvider";
import { showErrorNotificationWithTitleAndMessage } from "../../../utils";
import "./ConsoleNavAvatarMenu.css";

function ConsoleNavAvatarMenu() {
  const { logout, email } = useContext(ContextProvider)
  const navigate = useNavigate();

  const location  = useLocation();
  const currentPath = location.pathname

  const handleProfileClick = () => {
    if (currentPath == "/verification-page") {
      showErrorNotificationWithTitleAndMessage("Can't navigate without verifying", "You must verify your profile to navigate to the profiles section.")
      return
    }
    navigate("/console?active=profile")
  }

  const avatarPlaceholder = email?.charAt(0).toUpperCase();
  return (
    <Menu
      styles={{
        item: {
          fontSize: "16px",
        },
        label: {
          fontSize: "12px",
        },
      }}
      openDelay={100}
      closeDelay={200}
      position="top-end"
      trigger="hover"
      shadow="md"
      width={250}
    >
      <Menu.Target >
        <Avatar className="user-profile-avatar" styles={{ placeholder: { fontSize: "22px" } }} color="white" radius="sm">
          {avatarPlaceholder}
        </Avatar>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label style={{ fontSize: "16px" }}>Account</Menu.Label>
        <Menu.Item
          onClick={() => handleProfileClick()}
          style={{ fontSize: "18px" }}
          leftSection={
            <IconUserCircle style={{ width: rem(18), height: rem(18) }} />
          }
        >
          Profile
        </Menu.Item>
        <Menu.Item
          onClick={logout}
          color="red"
          style={{ fontSize: "18px" }}
          leftSection={
            <IconLogout style={{ width: rem(18), height: rem(18) }} />
          }
        >
          Logout
        </Menu.Item>

        < Menu.Divider />
        < Menu.Label>
          { 
          email
          }
        </Menu.Label>
      </Menu.Dropdown>
    </Menu>
  );
}

export default ConsoleNavAvatarMenu;
