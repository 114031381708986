import { ActionIcon, } from "@mantine/core";
import { IconInfoSquare, IconX } from "@tabler/icons-react";
import { Text as GestaltText } from "gestalt";
import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContextProvider from "../../../../ContextProvider.tsx";
import { SUPPORT_EMAIL, WS_ENDPOINT } from "../../../../constants.ts";
import {
  showErrorNotificationWithTitleAndMessage,
  showErrorNotifications
} from "../../../../utils.tsx";
import CustomLoader from "../../../custom-items/Loader.tsx";
import "./PendingMachineStatusUpdate.css";

const PendingMachineStatusUpdate = ({}: {}) => {
  const { token, email, setTracker } = useContext(ContextProvider);
  const [socket, setSocket] = React.useState<WebSocket | null>(null);
  const navigate = useNavigate();
  const [loaderMessage, setLoaderMessage] = useState("creating");
  const queryClient = useQueryClient();
  const { vmId, nodeId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [ isFree, setIsFree ] = useState(false);

  useEffect(() => {
    if (vmId == null) {
      showErrorNotificationWithTitleAndMessage(
        "Error",
        `Something went wrong. Wait on the homepage. If the machine is not created within 5 minutes, reach out to ${SUPPORT_EMAIL}`,
      );
      return;
    }
    let newSocket = new WebSocket(`${WS_ENDPOINT}/status-update/${ vmId }`);

    if (searchParams.get("free") == "yes") {
      setIsFree(true)
    }

    newSocket.onopen = () => {
      newSocket.send(
        JSON.stringify({
          type: "token-handshake",
          message: token,
        }),
      );
    };

    newSocket.onclose = () => {
      newSocket.close();
    };

    newSocket.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message.status == "failed") {
        showErrorNotificationWithTitleAndMessage("Error", message.message, 10000);
        navigate("/console")
      } else if (message.status === "success") {
        if (message.message == "completed") {
          setLoaderMessage("completed");
          setTimeout(() => {
            navigate(`/machine/${vmId}/${ nodeId }`, {
              state: {
                justCreated: true,
              }
            });
          }, 400);
        } else if (message.message == "connected") {
          newSocket.send(
            JSON.stringify({
              message: vmId,
              type: "update",
            }),
          );
        } else {
          setLoaderMessage(message.message);
        }
      } else {
        
      }
    };
    newSocket.onerror = (e) => {
      showErrorNotifications(
        e,
        "Could not fetch status of the machine. Deployment is ongoing and you will be notified soon.",
        4000,
      );
    };

    setSocket(newSocket);

    return () => {
      if (socket?.readyState === WebSocket.OPEN) {
        socket.close();
      }
      queryClient.invalidateQueries({
        queryKey: "GET_MACHINES",
        refetchInactive: true,
      });
    };
  }, []);

  const handleCloseClick = () => {
    setTracker((curr) => {
      return [
        ...curr,
        {
          resourceType: "machine",
          trackingId: vmId!,
          postSuccessFn: () => {
            setTimeout(() => {
              navigate(`/machine/${vmId}/${ nodeId }`, {
                state: {
                  justCreated: true,
                }
              });
            }, 400);
            queryClient.invalidateQueries({
              queryKey: "GET_MACHINES",
              refetchInactive: true,
            });
          },
          postFailureFn: () => console.log("hi"),
          unmountFn: () => console.log("hi"),
        }
      ]
    })
    navigate("/console")
  }

  return (
    <div style={{ height: "90dvh", width: "100vw", position: "relative" }}>
      < ActionIcon onClick={() => handleCloseClick()} variant="light" size="lg" color="dark" style={{ position: "absolute", top: "80px", right: "25px", zIndex: 40 }}>
        <IconX></IconX>
      </ActionIcon>

      <CustomLoader showStayOnPageFlag showHomeButton={true} message={loaderMessage} animate={true} />

      {
        isFree ?
        < FreeMachineBannerBottom message={"Note: This is a complimentary machine. You can use the machine to learn more about our platform. The machine will auto-delete in 3 days unless you upgrade the machine from the console."} /> :
        < FreeMachineBannerBottom message={`It takes about 5 minutes to create a new machine. You can navigate away from this page and the machine will appear in your homepage once deployment has completed.`} />
      }
    </div>
  );
};

export const FreeMachineBannerBottom = ({ message }: { message: string }) => {

  return (
    <div className="centered-flex bottom-banner-outside-console">

        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
          <div style={{ width: "20px", height: "20px" }}>
            < IconInfoSquare size="20" />
          </div>
          <GestaltText color="dark" size="200" weight="bold">{ message }</GestaltText>
        </div>
    </div>
  )
}

export default PendingMachineStatusUpdate;
