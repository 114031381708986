export const ubuntuImage = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/company-logos/ubuntu-logo.png";
export const windowsLogo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/company-logos/windows-logo.png";
export const pyTorchLogo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/company-logos/pytorch-logo-1.png";
export const tensorflowLogo = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/company-logos/tensorflow-logo.png";
export const AmericanFlag = "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/company-logos/american-flag-sm.png";

export const AllAvailableImages = [
  {
    id: "a3fb64d8-8bd3-11ee-8855-26459c21d41e",
    value: "ubuntu_22.04",
    name: "Ubuntu 22.04",
    category: "Linux",
    baseOS: "Ubuntu",
    img: (
      <img
        style={{ backgroundColor: "transparent", borderRadius: "50%" }}
        src={ubuntuImage}
        width="40px"
      />
    ),
    description: "Ubuntu Desktop 22.04 with minimal preconfigured installation",
    access: ["SSH", "RDP", "Browser"],
  },
  {
    id: "a988631e-8c67-11ee-8d67-26459c21d41e",
    value: "ubuntu_18.04",
    name: "Ubuntu 18.04",
    baseOS: "Ubuntu",
    img: (
      <img
        style={{ backgroundColor: "transparent", borderRadius: "50%" }}
        src={ubuntuImage}
        width="40px"
      />
    ),
    category: "Linux",
    description: "Ubuntu Desktop 18.04 with minimal preconfigured installation",
    access: ["SSH", "RDP", "Browser"],
  },
  {
    id: "c97ae522-8bea-11ee-8855-26459c21d41e",
    value: "windows_10",
    name: "Windows 10",
    baseOS: "Windows",
    img: (
      <img
        style={{ backgroundColor: "transparent", borderRadius: "40%" }}
        src={windowsLogo}
        width="40px"
      />
    ),
    category: "Windows",
    description: "Windows 10. Bring your own license.",
    access: ["SSH", "RDP", "Browser"],
  },
  {
    id: "ca2fd730-8c68-11ee-8d67-26459c21d41e",
    value: "tensorflow",
    name: "Tensorflow",
    baseOS: "Ubuntu",
    img: (
      <img
        style={{ backgroundColor: "transparent", borderRadius: "40%" }}
        src={tensorflowLogo}
        width="40px"
      />
    ),
    category: "ML Packages",
    description: "All the required packages in tensorflow",
    access: ["SSH", "RDP", "Browser"],
  },
  {
    id: "cbc172f4-8c69-11ee-8d67-26459c21d41e",
    value: "pytorch",
    name: "PyTorch",
    baseOS: "Ubuntu",
    img: (
      <img
        style={{ backgroundColor: "transparent", borderRadius: "40%" }}
        src={pyTorchLogo}
        width="40px"
      />
    ),
    category: "ML Packages",
    description:
      "All the required packages in Pytorch. Comes with CUDA installed",
    access: ["SSH", "RDP", "Browser"],
  },
  {
    id: "d1b1136e-8bd4-11ee-8855-26459c21d41e",
    value: "tensorflow_pytorch",
    name: "Essentials",
    baseOS: "Ubuntu",
    img: <div className="ai-text">ml</div>,
    category: "ML Packages",
    description:
      "Pytorch, Tensorflow, and the most important datasets and libraries",
    access: ["SSH", "RDP", "Browser"],
  },
];

export const AllStorageSizes = [
  "50 GB",
  "100 GB",
  "250 GB",
  "500 GB",
  "1000 GB",
  "2000 GB",
];
