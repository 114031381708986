import { Button as MantineButton } from "@mantine/core";
import "./MantineCustomizedButton.css";

const MantineCustomizedButton = ({
  clicker,
  color,
  styles,
  rightSection = null,
  leftSection = null,
  children,
  className,
  size = "md",
  disabled = false,
}: {
  clicker: () => void;
  color: string;
  styles: Object;
  rightSection?: React.ReactNode | null;
  leftSection?: React.ReactNode | null;
  children: any;
  className: string;
  size?: string;
  disabled?: boolean,
}) => {
  return (
    <MantineButton
      onClick={clicker}
      color={color}
      className={`${className} switch-button`}
      style={{ ...styles }}
      rightSection={rightSection}
      leftSection={leftSection}
      size={size}
      disabled={disabled}
    >
      {children}
    </MantineButton>
  );
};

export default MantineCustomizedButton;
