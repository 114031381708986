import { Text as GestaltText } from "gestalt";
import "./Loader.css";

const CustomLoader = ({
  message,
  animate = false,
  showStayOnPageFlag = false,
  showHomeButton = false,
}: {
  message: string;
  animate?: boolean;
  showStayOnPageFlag?: boolean;
  showHomeButton?: boolean;
}) => {
  return (
    <div className="custom-loader-wrapper">
      <div className="custom-loader-container">
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className="custom-loader-base">
          <span></span>
          <div className="custom-loader-face"></div>
        </div>
      </div>
      <div className="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`custom-loader-text`}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
          className={ `${ animate ? "blink-animation" : "" }` }
        >
          {message}
        </div>
        {
          showStayOnPageFlag && 
          <div
          style={{ width: "80%", marginTop: "20px", display: "flex", justifyContent: "center" }}
        >
          < GestaltText align="center" size="200" color="subtle">
            You can minimize this page and explore the console while you wait. Please do not close the browser tab entirely.
          </GestaltText>
        </div>
        }
        {/* {
          showHomeButton && 
          < Button color="black" style={{ marginTop: "20px" }}>
            Open console
          </Button>
        } */}
      </div>
    </div>
  );
};

export default CustomLoader;
