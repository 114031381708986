import { Button } from "@mantine/core"
import { Text as GestaltText } from "gestalt"

export const AlternativeErrorSection = ({ message }: { message: string }) => {
  return (
    <div style={{ paddingBottom: "3%", display: "flex", justifyContent:"start", alignItems:"start", flexDirection: "column" }}>
      < GestaltText weight="bold" color="error" size="600" >
        500
      </GestaltText>
      < GestaltText size="500">
        < span style={{ fontSize: "24px", fontWeight: "500" }}>
          Something went wrong
        </span>
      </GestaltText>
      < GestaltText color="dark" >
        < span style={{ fontSize: "16px", fontWeight: "400" }}>
          { message }
        </span>
      </GestaltText>
      <Button style={{ maxWidth: "150px", marginTop: "5px" }} fullWidth={false} onClick={ () => window.location.reload() } color="black" variant="outline" size="xs">
        Refresh the page
      </Button>
    </div>
  )
}

export const AlternativeErrorSectionLg = ({ message }: { message: string }) => {
  return (
    <div style={{ paddingBottom: "3%", display: "flex", minHeight:"50vh", justifyContent:"center", alignItems:"center", flexDirection: "column" }}>
      < GestaltText color="error" size="600" >
        < span style={{ fontSize: "64px", fontWeight: "600", marginBottom: "-10px" }}> 500 </span>
      </GestaltText>
      < GestaltText color="dark" size="600" >
        < span style={{ fontSize: "48px", fontWeight: "500", paddingBottom: "10px" }}>
          Something went wrong
        </span>
      </GestaltText>
      < GestaltText color="dark" >
        < span style={{ fontSize: "24px", fontWeight: "400" }}>
          { message }
        </span>
      </GestaltText>
      <Button style={{ maxWidth: "150px", marginTop: "10px" }} fullWidth={false} onClick={ () => window.location.reload() } color="black" variant="outline" size="xs">
        Refresh the page
      </Button>
    </div>
  )
}

export default AlternativeErrorSection;