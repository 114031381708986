
import { useIntersection } from "@mantine/hooks";
import { Button, Icon, Text } from "gestalt";
import { useContext, useEffect, useRef, useState } from "react";
import ContextProvider from "../../ContextProvider";
import BottomArrow from "./BottomArrow";
import BottomArrowFirstPage from "./BottomArrowFirstPage";
import RuntimesCollection from "./RuntimeCollection";
import "./ScrollSnapper.css";
import SignUp from "./SignUp";
import { motion } from "framer-motion";
import { AtCapacityBannerBottom } from "./AtCapacity";
import { useNavigate } from "react-router-dom";
import { DOCS_URL } from "../../constants";
const { detect } = require('detect-browser');
const browser = detect();

const ScrollSnapper = () => {
    const signUpFormRef = useRef(null);
    const cheapGPUsRef = useRef(null);
    const runtimeCollectionRef = useRef(null);
    const browserAccessRef = useRef(null);

    const { setShowTopHeaderMenu } = useContext(ContextProvider)

    useEffect(() => {
        setShowTopHeaderMenu(false)
        document.body.style.backgroundColor = "#004653"

        if (browser) {
            if (browser.name != "chrome" && browser.name != "safari"){
                const elements = document.getElementsByClassName('viewer');
                const specificElement = elements[0] as HTMLElement;

                if (specificElement) {
                    (specificElement.style as any).scrollSnapType = 'none';
                }
            }
        }

        return () => { 
            setShowTopHeaderMenu(true)
            document.body.style.backgroundColor = ""
        }
    }, [])

    return (
        <article className="viewer">
            <section className="slide">
                < CheapGPU oneStepBelowRef={browserAccessRef} refProp={cheapGPUsRef} />
                {/* @ts-ignore */}
                < BottomArrowFirstPage clicker={() => browserAccessRef?.current.scrollIntoView({ behavior: 'smooth' })} />
                
                {/* <div
                    // @ts-ignore
                    onClick={() => browserAccessRef?.current.scrollIntoView({ behavior: 'smooth' })}
                    className="bottom-bar on-hover-pointer"
                    style={{
                        position: "absolute",
                        height: "40px",
                        width: "100%",
                        bottom: 0,
                        backgroundColor: "#ffe4c1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <Text weight="bold">Explore what we offer</Text>
                    <Icon
                        icon="arrow-down"
                        size={12}
                        accessibilityLabel="Pin"
                        color="default"
                    />
                </div> */}
            </section>
            <section className="slide">
                < BrowserAccess refProp={browserAccessRef} />
                <BottomArrow
                    // @ts-ignore
                    clicker={() => runtimeCollectionRef?.current.scrollIntoView({ behavior: 'smooth' })}
                    bottomChevronBgColor={"#FFFFFF"} color="black"
                />
            </section>
            <section className="slide">
                <RuntimeCollection refProp={runtimeCollectionRef} />
                <BottomArrow
                    // @ts-ignore
                    clicker={() => signUpFormRef?.current.scrollIntoView({ behavior: 'smooth' })}
                    bottomChevronBgColor={"#000000"} color="white"
                />
            </section>
            <section className="slide">
                < SignUp chevronNavigator={() =>
                    // @ts-ignore
                    cheapGPUsRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    })
                }
                    signUpFormRef={signUpFormRef} />
            </section>
        </article>
    )
}

const CheapGPU = ({ refProp, oneStepBelowRef }: { refProp: any, oneStepBelowRef: any }) => {
    const btnRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: 1,
    });
    const { setCurrBackgroundColor, setCurrentTextColor, setTopConsoleButtonText } = useContext(ContextProvider);
    const [ loaded, setLoaded ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (entry?.isIntersecting) {
            setCurrBackgroundColor("#004653")
            setCurrentTextColor("#ffe4c1")
            setTopConsoleButtonText("white")
        }
    }, [entry?.isIntersecting])

    useEffect(() => {
        if (btnRef != null && btnRef.current != null) {
            // @ts-ignore
            btnRef.current.style.backgroundColor = "#ffe4c1";;
        }
    }, [loaded])


    return (
        <div ref={refProp} className="homepage-main-wrapper">
            { entry?.isIntersecting &&
                < AtCapacityBannerBottom isInHomepage={true} />
            }
            
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="bigger-container">
                < img onLoad={() => setLoaded(true)} className="cheap-gpu-img" src="https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/8aaaf46b-ab95-446e-77f1-a59690d28900/homepage" />
            </motion.div>
            {
                loaded && 
                <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }} ref={ref} className="smaller-container">
                    <div className="big-header">
                        Cheap Cloud GPUs
                    </div>
                    <div className="mid-header flex-col">
                        P400s from $0.32/hour
                    </div>
                    < Button onClick={() => window.open(`${ DOCS_URL }/docs/pricing/machine-pricing`, "_blank")} ref={btnRef} size="lg" text="See Pricing" color="transparent" />
                </motion.div>
            }
        </div>
    )
}

const BrowserAccess = ({ refProp }: { refProp: any }) => {
    const btnRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: 1,
    });
    const { setCurrBackgroundColor, setCurrentTextColor, setTopConsoleButtonText } = useContext(ContextProvider);

    useEffect(() => {
        if (entry?.isIntersecting) {
            setCurrBackgroundColor("#000000")
            setCurrentTextColor("#FFFFFF")
            setTopConsoleButtonText("white")
        }
    }, [entry?.isIntersecting])

    useEffect(() => {
        if (btnRef != null && btnRef.current != null) {
            // @ts-ignore
            btnRef.current.style.backgroundColor = "#FFFFFF";;
        }
    }, [])
    const navigate = useNavigate();

    return (
        <div ref={refProp} className="homepage-main-wrapper browser-access-flex-reverse">
            <div ref={ref} className="smaller-container">
                <div className="big-header">
                    Browser Access
                </div>
                <div className="mid-header flex-col">
                    SSH + Desktop access from browser
                </div>
                < Button onClick={() => window.open("https://docs.neural-logic.com/docs/Machines/how-tos/connect-to-desktop", "_blank")} ref={btnRef} size="lg" text="Learn more" color="transparent" />
            </div>
            <div className="bigger-container">
                <img className="browser-access-img"
                    src="https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/8253b9c4-cd7d-4652-ba80-914fb2b31f00/chromeubuntu"
                    sizes="(min-width: 949px) 949px, 100vw"
                    alt="Your Image Description" />
            </div>
        </div>
    )
}

const RuntimeCollection = ({ refProp }: { refProp: any }) => {
    const btnRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: 1,
    });
    const { setCurrBackgroundColor, setCurrentTextColor, setTopConsoleButtonText, setNavVisible } = useContext(ContextProvider);

    useEffect(() => {
        if (entry?.isIntersecting) {
            setNavVisible(true)
            setCurrBackgroundColor("#f9f9f9")
            setCurrentTextColor("#000000")
            setTopConsoleButtonText("blue")
        }
    }, [entry?.isIntersecting])

    useEffect(() => {
        if (btnRef != null && btnRef.current != null) {
            // @ts-ignore
            btnRef.current.style.backgroundColor = "#000000";;
        }
    }, [])
    
    const navigate = useNavigate()

    return (
        <div ref={refProp} className="homepage-main-wrapper runtime-collection-wrapper">
            <div className="bigger-container height-80dvh">
                < RuntimesCollection />
            </div>
            <div ref={ref} className="smaller-container push-up-lg">
                <div className="big-header">
                    Popular runtimes
                </div>
                <div className="mid-header flex-col">
                    Pytorch, Tensorflow, CUDA and more
                </div>
                < Button onClick={() => window.open(`${ DOCS_URL }/docs/Machines/runtime-templates#ml-templates`, "_blank")} ref={btnRef} size="lg" text="Check libraries" color="transparentWhiteText" />
            </div>
        </div>
    )
}

export default ScrollSnapper;