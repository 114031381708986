import {
    IconHome,
    IconUser
} from '@tabler/icons-react';
import { Text as GestaltText } from "gestalt";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getListOfUsers } from "../../apis/admin";
import { fetchErrorString } from "../../utils";
import { AlternativeErrorSectionLg } from "../custom-items/ErrorSection";
import CustomLoader from "../custom-items/Loader";
import AdminContext from "../local-context/AdminContext";
// @ts-ignore
import classes from './AdminConsole.module.css';
import { UsersDataTable } from "./UsersDataTable";

const AdminConsole = () => {
  const { authenticated, setAuthenticated, } = useContext(AdminContext);

  if (!authenticated) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          width: "100vw",
        }}
      >
        < GestaltText weight="bold" size="600">Please authenticate!</GestaltText>
      </div>
    )
  }

  return (
    <div>
      < NavbarSimple />
      <div style={{ paddingLeft: "300px" }}>
        < Switcher />
      </div>
    </div>
  )
}


const Switcher = () => {
  const { active } = useContext(AdminContext);

  if (active === "Home") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        < GestaltText weight="bold" size="600">Please use the tabs to navigate!</GestaltText>
      </div>
    )
  } else if (active === "Users") {
    return < Users />
  }
}

const Users = () => {
  const {
    isLoading: usersDataIsLoading,
    error: usersListError,
    data: usersData,
  } = useQuery({
    queryKey: "GET_LIST_OF_USERS",
    queryFn: () => getListOfUsers(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (usersDataIsLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="machine-loading-wrapper">
          <CustomLoader message={"FETCHING..."} animate={true} />
        </div>
      </div>
    );
  }

  if (usersListError) {
    let errorStr = fetchErrorString(usersListError);

    return (
      <AlternativeErrorSectionLg
        message={`Error: Could not get past templates. ${errorStr}.`}
      />
    );
  }

  return (
    < UsersDataTable data={usersData} />)
}

const d = [
  { link: '', label: 'Home', icon: IconHome },
  { link: '', label: 'Users', icon: IconUser },
];

export function NavbarSimple() {
  const { active, setActive } = useContext(AdminContext);

  const links = d.map((item) => (
    <a
      className={classes.link}
      data-active={item.label === active || undefined}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        {links}
      </div>
    </nav>
  );
}

export default AdminConsole;