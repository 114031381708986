import { IconChevronsDown } from "@tabler/icons-react";
import "./BottomArrowFirstPage.css";

const BottomArrowFirstPage = ({ clicker }: any) => {
  return (
    <div onClick={() => clicker()} className="down-arrow-first-page">
      <IconChevronsDown size={32} strokeWidth={2} color="black" />
    </div>
  );
};

export default BottomArrowFirstPage;
