import { Text as MantineText } from "@mantine/core";

const CustomLabel = ({
  label,
  required = true,
  fontSize = "12px",
}: {
  label: string;
  required?: boolean;
  fontSize?: string;
}) => {
  return (
    <MantineText
      className="font-12"
      style={{
        fontWeight: "600",
        fontSize: fontSize,
        color: "#505050"
      }}
    >
      {label}
      {` `}
      {required && <span style={{ color: "#FA5252" }}>*</span>}
    </MantineText>
  );
};

export default CustomLabel;
