import { Button, Datapoint, Text } from "gestalt";
import {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import Authentication from "../auth/Authentication";
import "./SignUp.css";
import TopArrow from "./TopArrow";

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      ),
    [ref],
  );

  useEffect(() => {
    // @ts-ignore
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}

const SignUp = ({
  signUpFormRef,
  chevronNavigator,
}: {
  signUpFormRef: React.MutableRefObject<null>;
  chevronNavigator: () => {};
}) => {
  const { setNavVisible } = useContext(ContextProvider);
  const navigate = useNavigate();
  const { token } = useContext(ContextProvider);

  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
        {
          threshold: 1,
        }
      ),
    [signUpFormRef],
  );

  useEffect(() => {
    // @ts-ignore
    observer.observe(signUpFormRef.current);
    return () => { 
      observer.disconnect();
      setNavVisible(true)
    }
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setNavVisible(false)
    }
  }, [isIntersecting]);

  return (
    <div className="sign-up-page">
      <TopArrow chevronNavigator={chevronNavigator} />
      <div style={{ position: "absolute", top: "50%", left: "50%", width: "20px", height: "20px", }} ref={signUpFormRef} />
      <div className="auth-wrapper">
        {
          token == undefined ?
            <Authentication logIn={false} />
            : <div style={{ position: "relative" }} >
              < img className="auth-wrapper form-wrapper auth-form-outside-modal-wrapper blur-heavy" src="https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/38175040-59ba-4463-de04-44a333a91f00/authblur" />
              <div className="absolute-and-centered flex-col" >
                <Text size="400" weight="bold" color="error" align="center">You are already signed in</Text>
                < Button  onClick={() => navigate("/console")} name="homepage-auth-sign-in" fullWidth={false} text="Visit console" color="red" />
              </div>
            </div>
        }
      </div>
      <div
        className="hidden-mobile"
        style={{
          marginRight: "auto",
          flex: 1,
          marginLeft: "auto",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          < div style={{ color: "white", marginBottom: "-20px" }}>
            < Text color="light" size="300" weight="bold">Stats this week</Text>
          </div>
          <div className="data-point-tile">
            <div className="data-point-wrapper">
              <Datapoint
                size="lg"
                title="Total compute hours"
                tooltipText="The number of compute hours users have spent this week on neural-logic"
                trend={{ value: 39, accessibilityLabel: "Trending up" }}
                value="5200"
              />
            </div>
            <div className="data-point-wrapper">
              <Datapoint
                size="lg"
                title="Total notebooks"
                tooltipText="Total notebooks created this week. Notebooks have not been released to the public."
                trend={{ value: 0, accessibilityLabel: "Trending up" }}
                value="0"
              />
            </div>
          </div>
          <div className="data-point-tile">
            <div className="data-point-wrapper">
              <Datapoint
                size="lg"
                title="Number of Machines"
                tooltipText="Number of Machines launched"
                trend={{ value: 40, accessibilityLabel: "Trending up" }}
                value="1400"
              />
            </div>
            <div className="data-point-wrapper">
              <Datapoint
                size="lg"
                title="Users"
                tooltipText="Number of user signups"
                trend={{ value: 60, accessibilityLabel: "Trending up" }}
                value="300"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-bar"
        style={{
          position: "absolute",
          height: "40px",
          width: "100%",
          bottom: 0,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <div className="bottom-nav-link" onClick={() => navigate("/privacy")}>
          Privacy
        </div>
        <div style={{ color: "white" }}>&#8226;</div>
        <div className="bottom-nav-link" onClick={() => navigate("/tos")}>
          Terms of Service
        </div>
        <div style={{ color: "white" }}>&#8226;</div>
        <div className="bottom-nav-link" onClick={() => navigate("/aup")}>
          Acceptable Use Policy
        </div>
        <div className="hidden-mobile" style={{ color: "white" }}>&#8226;</div>
        <div className="bottom-nav-link hidden-mobile" onClick={() => navigate("/compliance")}>
          Compliance
        </div>
        <div className="hidden-mobile" style={{ color: "white" }}>&#8226;</div>
        <div className="bottom-nav-link hidden-mobile" onClick={() => navigate("/data-processing-agreement")}>
          Data Processing Agreement
        </div>
      </div>
    </div>
  );
};

export default SignUp;
