import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Cookies from "universal-cookie";
import ContextProvider from "./ContextProvider";
import ConsoleNavWrapper from "./components/nav/console-header-menu/ConsoleNavWrapper";
import NavWrapper from "./components/nav/console-header-menu/NavWrapper";
import {
  getTokenAndUsernameFromCookie,
  removeItemsFromLocalStorage,
  removeTokenFromCookies,
  removeVerificationTokenFromCookies,
  showErrorNotificationWithTitleAndMessage,
  showSuccessNotification
} from "./utils";
// @ts-ignore
import { ColorSchemeProvider } from "gestalt";
import AdminConsole from "./components/admin/AdminConsole";
import AuthSinglePage from "./components/auth/AuthSinglePage";
import ForgotPassword from "./components/auth/ForgotPassword";
import LoginAndVerificationLandingPage from "./components/auth/VerificationLandingPage";
import PendingMachineStatusUpdate from "./components/console/console-segments/machines/PendingMachineStatusUpdate";
import VerificationPage from "./components/console/verification/VerificationPage";
import AboutPage from "./components/homepage/AboutPage";
import ScrollSnapper from "./components/homepage/ScrollSnapper";
import Support from "./components/miscellanous/Support";
import ProtectedRoutes from "./components/wrappers/ProtectedRoutes";
import { BASE_URL, FRONTEND_URL } from "./constants";
import RDPNavWrapper from "./components/nav/rdp-minimal-nav-bar/RDPMinimalNavBar";
import RDPLandingPage from "./components/rdp/RDPLandingPage";
import AdminRoutes from "./components/wrappers/AdminRoutes";
// Create a client
const queryClient = new QueryClient({});

const cookies = new Cookies();

axios.interceptors.request.use(
  (config) => {
    const tokenFromCookies = cookies.get("token");
    if (tokenFromCookies != null && tokenFromCookies.token !== undefined) {
      config.headers.Token = tokenFromCookies.token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <NavWrapper>
        < ScrollSnapper />
      </NavWrapper>
    ),
  },
  {
    path: "/",
    element: (
      < NavWrapper>
        < ScrollSnapper />
      </NavWrapper>
    ),
  },
  {
    path: "/home",
    element: (
      < NavWrapper>
        < ScrollSnapper />
      </NavWrapper>
    ),
  },
  {
    path: "/auth",
    element: < NavWrapper><AuthSinglePage /></NavWrapper>,
  },
  {
    // Keep this right here. I need this route to show people the register page instead of the sign in page
    path: "/register",
    element: < NavWrapper><AuthSinglePage /></NavWrapper>,
  },
  {
    path: "/email-verification",
    element: <LoginAndVerificationLandingPage />,
  },
  {
    path: "/verification-page",
    element:
      <ProtectedRoutes>
        <ConsoleNavWrapper>
          < VerificationPage />
        </ConsoleNavWrapper>
      </ProtectedRoutes>
  },
  {
    path: "/console",
    async lazy() {
      let CentralHub = await import("./components/console/index");
      return { Component: CentralHub.default };
    },
  },
  {
    path: "/status/:vmId/:nodeId",
    element: (
      <ProtectedRoutes>
        <ConsoleNavWrapper>
          <PendingMachineStatusUpdate />
        </ConsoleNavWrapper>
      </ProtectedRoutes>
    ),
  },
  {
    path: "/machine/:machineId/:machineNodeId",
    async lazy() {
      let MachineDetailPage = await import("./components/console/console-segments/machines/index");
      return { Component: MachineDetailPage.default };
    },
  },
  {
    path: "/screen/:machineId/:nodeId/:mode",
    element: (
      <ProtectedRoutes>
        <RDPNavWrapper>
          <RDPLandingPage />
        </RDPNavWrapper>
      </ProtectedRoutes>
    ),
  },
  {
    path: "/nl-admin/admin",
  element: (
  <AdminRoutes>
  <AdminConsole />
  </AdminRoutes>),
  },
  {
    path: "/forgot-password",
    element: (
      < NavWrapper>
        < ForgotPassword />
      </NavWrapper>
    )
  },
  {
    path: "/about",
    element: (
      <NavWrapper>
        <AboutPage />
      </NavWrapper>
    )
  },
  {
    path: "/support",
    element: (
      < Support />
    )
  },
  {
    path: "/privacy",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/WrappedPrivacy");
      return { Component: YourComponent.default };
    },
  },
  {
    path: "/aup",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/WrappedAUP");
      return { Component: YourComponent.default };
    },
  },
  {
    path: "/tos",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/WrappedTOS");
      return { Component: YourComponent.default };
    },
  },
  {
    path: "/compliance",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/WrappedSecurityAndCompliance");
      return { Component: YourComponent.default };
    },
  },
  {
    path: "/data-processing-agreement",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/WrappedDataProcessingAgreement");
      return { Component: YourComponent.default };
    },
  },
  {
    path: "/docs",
    async lazy() {
      let YourComponent = await import("./components/miscellanous/UnderConstruction.tsx");
      return { Component: YourComponent.default };
    },
  }
]);

function App() {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [navVisible, setNavVisible] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [currBackgroundColor, setCurrBackgroundColor] = useState("#5680ff");
  const [currTextColor, setCurrentTextColor] = useState<string>("#FFFFFF");
  const [showSelectGPUModal, setShowSelectGPUModal] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState<
    | {
      phoneNumber: string;
      phoneVerified: boolean;
      emailVerified: boolean;
    }
    | undefined
  >({
    phoneNumber: "0000000000",
    phoneVerified: true,
    emailVerified: true,
  });
  const [scheme, setScheme] = useState<"dark" | "light">('light');
  const [tracker, setTracker] = useState<{
    resourceType: string;
    trackingId: string;
    postSuccessFn: () => void;
    postFailureFn: () => void;
    unmountFn: () => void
  }[]>([]);
  const [topConsoleButtonText, setTopConsoleButtonText] = useState<"blue" | "white">("white");
  const [showTopHeaderMenu, setShowTopHeaderMenu] = useState(false);

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          showErrorNotificationWithTitleAndMessage("Unauthorized", "You do not have permission to view this page.")
          logout();
        }
        return Promise.reject(error);
      },
    );

    (
      async () => {
        try {
          let resp = await axios.get(`${BASE_URL}/capacity/check`);
          setShowAtCapacityBanner(resp.data.payload)
        } catch (e) {
          setShowAtCapacityBanner(false);
        }
      }
    )()

    let storedData = getTokenAndUsernameFromCookie();
    const [storedToken, storedEmail] = storedData;
    setToken(storedToken);
    setEmail(storedEmail);

  }, []);
  const [showAtCapacityBanner, setShowAtCapacityBanner] = useState(false);

  const logout = () => {
    // Step 1: Logging out sequence begins

    // Step 2: Removing queries from queryClient
    queryClient.removeQueries();

    // Step 5: Removing verification token from cookies
    removeVerificationTokenFromCookies();

    // Step 6: Removing token from cookies
    removeTokenFromCookies();

    // Step 7: Setting verified status to undefined
    setVerifiedStatus(undefined);

    // Step 8: Removing items from local storage
    removeItemsFromLocalStorage();

    // Step 9: Redirecting to the authentication page
    // Step 3: Setting token to undefined
    setToken(undefined);

    // Step 4: Setting email to undefined
    setEmail(undefined);


    window.location.href = `${FRONTEND_URL}/auth`;
  };

  return (
    <MantineProvider theme={{
      fontFamily: 'Urbanist, sans-serif'
    }}>
      <ColorSchemeProvider colorScheme={scheme} id="docsExample">
        <Notifications zIndex={2000} position="top-center" />
        <ContextProvider.Provider
          value={{
            token: token,
            email: email,
            setToken: setToken,
            setEmail: setEmail,
            navVisible: navVisible,
            setNavVisible: setNavVisible,
            showAuthModal: showAuthModal,
            setShowAuthModal: setShowAuthModal,
            currBackgroundColor: currBackgroundColor,
            setCurrBackgroundColor: setCurrBackgroundColor,
            showSelectGPUModal: showSelectGPUModal,
            setShowSelectGPUModal: setShowSelectGPUModal,
            verifiedStatus: verifiedStatus,
            setVerifiedStatus: setVerifiedStatus,
            logout: logout,
            tracker: tracker,
            setTracker: setTracker,
            currTextColor: currTextColor,
            setCurrentTextColor: setCurrentTextColor,
            topConsoleButtonText: topConsoleButtonText,
            setTopConsoleButtonText: setTopConsoleButtonText,
            showTopHeaderMenu: showTopHeaderMenu,
            setShowTopHeaderMenu: setShowTopHeaderMenu,
            showAtCapacityBanner: showAtCapacityBanner,
            setShowAtCapacityBanner: setShowAtCapacityBanner,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ContextProvider.Provider>
      </ColorSchemeProvider>
    </MantineProvider>
  );
}

export default App;
