import { Button, Divider, Icon, Text, Tooltip } from "gestalt";
import { useContext, useState } from "react";
import ContextProvider from "../../../ContextProvider";
import "./NavWrapper.css";
import { Burger, Center, Drawer, Menu, Badge } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// @ts-ignore
import classes from "./HeaderMenu.module.css";
import { DOCS_URL } from "../../../constants";

export function Nav() {
  const [headerButtonColor, setHeaderButtonColor] = useState<
    | "white"
    | "blue"
    | "gray"
    | "red"
    | "transparent"
    | "semiTransparentWhite"
    | "transparentWhiteText"
    | undefined
  >("gray");
  const { token, currTextColor, showTopHeaderMenu } = useContext(ContextProvider);
  const {
    showAuthModal,
    setShowAuthModal,
    currBackgroundColor,
    setVerifiedStatus,
    logout,
    topConsoleButtonText,
  } = useContext(ContextProvider);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [currentlyActive, setCurrentlyActive] = useState(0);
  const [authIsLoginNotRegister, setAuthIsLoginNotRegister] = useState(true);
  const navigate = useNavigate();

  const links = [
    { fn: () => navigate("/home"), label: "Home" },
    { fn: () => navigate("/about"), label: "About" },
    {
      link: "#2",
      label: "Resources",
      links: [
        { fn: () => navigate("/support"), label: "Support" },
        { fn: () => window.open(`${DOCS_URL}`, "_blank"), label: "Docs" },
        { fn: () => window.open(`${ DOCS_URL }/docs/pricing/machine-pricing`, "_blank"), label: "Pricing" },
      ],
    },
  ];

  const handleLoginClick = () => {
    if (token == undefined) {
      closeDrawer();
      setAuthIsLoginNotRegister(() => true);
      // setShowAuthModal(() => true);
      navigate("/auth")
    } else {
      navigate("/console");
      closeDrawer();
    }
  };

  const useHandleSignupClick = () => {
    if (token === undefined) {
      navigate("/register")
    } else {
      logout();
      closeDrawer();
    }
  };

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item
        onClick={ item.fn }
        style={{ paddingRight: "12px" }}
        key={item.label}
      >
        <Text weight="bold" size="300">
          {item.label}
        </Text>
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => event.preventDefault()}
            >
              <Center>
                <span style={{ color: currTextColor }} className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown color={ currTextColor } size="0.9rem" stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={link.label}
        onClick={(e) => {
          e.preventDefault();
          // @ts-ignore
          link.fn()
        }}
        className={classes.link}
      >
        {link.label}
      </a>
    );
  });

  return (
    <header
      style={{
        color: currTextColor,
        backgroundColor: showTopHeaderMenu ? currBackgroundColor : "transparent",
        zIndex: 500,
        position: "fixed",
      }}
      className={classes.header}
    >
      <div className={`${ classes.inner } nav-inner`}>
        <div
          onClick={() => { 
            closeDrawer()
            navigate("/home") 
          }}
          className="neural-logic-logo gap-6px noselect centered-flex"
        >
          neural-logic <Badge fw={ 900 } style={{ fontStyle: "normal" }}  size="sm" bg={ currTextColor } c={ currBackgroundColor }>< Tooltip text="Public Beta" >BETA</Tooltip></Badge>
        </div>
        <div
          className="hidden-mobile"
          style={{ gap: "25px", marginLeft: "auto", alignItems: "center" }}
        >
          {items}
          <Button
            color={
              topConsoleButtonText
            }
            onClick={handleLoginClick}
            text={token == undefined ? "Login" : "Console"}
          />
          <Button
            onClick={useHandleSignupClick}
            color={"red"}
            text={token === undefined ? "Sign up" : "Logout"}
          />
        </div>
        <Burger
          size="md"
          color={currTextColor}
          opened={drawerOpened}
          onClick={toggleDrawer}
          hiddenFrom="sm"
        />
        <DrawerForSmallerViewport
          drawerOpened={drawerOpened}
          closeDrawer={closeDrawer}
          bgColor={currBackgroundColor}
          headerButtonColor={headerButtonColor}
          textColor={currTextColor}
          handleLoginClick={handleLoginClick}
          handleSignupClick={useHandleSignupClick}
        />
      </div>
      {/* <AuthModal isLogin={authIsLoginNotRegister} /> */}
    </header>
  );
}

const NavWrapper = ({ children }: { children: any }) => {
  const { navVisible } = useContext(ContextProvider);

  return (
    <div>
      {navVisible && <Nav />}
      {children}
    </div>
  );
};

const DrawerForSmallerViewport = ({
  drawerOpened,
  closeDrawer,
  bgColor,
  headerButtonColor,
  handleLoginClick,
  handleSignupClick,
  textColor,
}: any) => {
  const { token } = useContext(ContextProvider);
  const [expandResources, setExpandResources] = useState(false);
  const navigate = useNavigate();

  const functionToExecute = (fn: () => void) => {
    closeDrawer()
    fn()
  }

  return (
    <Drawer
      opened={drawerOpened}
      onClose={closeDrawer}
      padding="md"
      className={ `${ classes.hiddenDesktop }`}
      zIndex={499}
      styles={{
        content: {
          width: "100vw",
          height: "100dvh",
          maxHeight: "100%",
          paddingTop: "55.95px",
          backgroundColor: bgColor,
        },
        body: {
          width: "100vw",
          height: "100%",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "24px",
        },
        header: { display: "none" },
      }}
      closeButtonProps={{ size: "lg" }}
      position="top"
      transitionProps={{ duration: 450 }}
    >
      <Divider />
      <div
      className="top-drawer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          color: textColor,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: "15px",
          }}
        >

          <div
            onClick={() => functionToExecute(() => navigate("/home"))}
            className="drawer-text-link"
          >
            Home
          </div>
          <div
            onClick={() => functionToExecute(() => navigate("/about"))}
            className="drawer-text-link"
          >
            About
          </div>
          <div
            onClick={() => functionToExecute(() => window.open(`${DOCS_URL}/docs/pricing/machine-pricing`, "_blank"))}
            className="drawer-text-link"
          >
            Pricing
          </div>
          <div
            onClick={() => setExpandResources((expand) => !expand)}
            className="drawer-text-link"
          >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>Resources < Icon accessibilityLabel="down-arrow" icon="arrow-circle-down" color={ headerButtonColor } /></div>
          </div>
          {expandResources && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              {/* <div
                onClick={() => functionToExecute(() => navigate("/blog"))}
                className="drawer-text-sublink"
              >
                Blog
              </div> */}
              <div
                onClick={() => functionToExecute(() => navigate("/support"))}
                className="drawer-text-sublink"
              >
                Support
              </div>
              <div
                onClick={() => functionToExecute(() => window.open(`${DOCS_URL}`, "_blank"))}
                className="drawer-text-sublink"
              >
                Docs
              </div>
            </motion.div>)}
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: "12px",
          }}
        >
          <Button
            size="lg"
            color={headerButtonColor}
            onClick={handleLoginClick}
            text={token == undefined ? "Login" : "Console"}
          />
          {token === undefined && (
            <Button
              size="lg"
              onClick={handleSignupClick}
              color={"blue"}
              text="Sign up"
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default NavWrapper;
