import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import "./UsageBarChart.css";

import { defaults } from 'chart.js';
defaults.font.family = "Urbanist";
defaults.color = "white";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UsageBarChart = ({ data, title }: {
  data: {
    datasets:
    {
      label: string;
      data: number[];
      backgroundColor: string;
      minBarLength: number;
    }[],
    max_value: number
  };
  title: string;
}) => {

  const plugin = {
    id: 'customCanvasBackgroundColor',
    // @ts-ignore
    beforeDraw: (chart, args, options) => {
      const {ctx} = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || 'black';
      ctx.fillRect(0, 0, chart.width + 50, chart.height + 50);
      ctx.restore();
    }
  };

  const formatDate = (dateStr: any) => {
    const [month, day] = dateStr.split('-');
    const date = new Date(new Date().getFullYear(), month - 1, day);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
  
  const options = {
    maintainAspectRatio: false,
    color: "black",
    plugins: {
      customCanvasBackgroundColor: 'black',
      tooltip: {
        padding: 14,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          title: (val) => {
            return formatDate(val[0].label)
          },
          // @ts-ignore
          label: (val) => {
            
            return  `  ${val.dataset.label}:   $${val.formattedValue}`
          }
        },
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        }
      },
      legend: {
        display: true,
        labels: {
          color: "white",
          boxHeight: 12,
          boxWidth: 12,
          font: {
            size: 16,
          },
        },
        position: "bottom",
        layout: { padding: 10 },
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "white",
          font: {
            size: 13,
          }
        },
        font: { 
          color: "white",
        },
        grid: {
          color: "rgba(255,255,255, 0.15)",
          lineWidth: 1,
        }
      },
      y: {
        stacked: true,
        title: {
          display: false
        },
        ticks: {
          stepSize: 0.5,
          callback: (value: number) => {
            return "$" + value;
          },
          color: "white",
          font: {
            size: 13,
          }
        },
        grid: {
          color: "rgba(255,255,255, 0.15)",
          lineWidth: 1,
        },
        min: 0,
        max: data.max_value,
      },
    },
  } as ChartOptions;

  return (
    <div className="light-raised-shadow">
      <Bar style={{ height: "380px", width: "auto", boxShadow: "rgb(87 87 87) 2px 3px 6px", padding: "20px" }} id="bar-chart" 
      // @ts-ignore
      options={options} data={data} plugins={[plugin]} />
    </div>
  )
}

export default UsageBarChart;