import { IconAlertTriangleFilled, IconInfoSquare, IconUsers } from "@tabler/icons-react"
import { Box, CompositeZIndex, FixedZIndex, Flex, Text as GestaltText, Heading, IconButton, Layer, Modal, Button as GestaltButton, TextField, Dropdown } from "gestalt";
import { Button, Loader, Select } from "@mantine/core";
import { useMutation, useQueryClient } from "react-query";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { fetchErrorString, showErrorNotifications, showSuccessNotification } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import SelectGPUModal, { SelectOptionComponent } from "../custom-items/CustomSelectGPUModal";
import ContextProvider from "../../ContextProvider";
import { motion } from "framer-motion";

export const AtCapacityBannerBottom = ({ isInHomepage }: { isInHomepage: boolean }) => {
    const [waitlisting, setWaitlisting] = useState(false);
    const queryClient = useQueryClient();
    const [showWaitlistModal, setShowWaitlistModal] = useState(false);

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className={ `${ isInHomepage ? "bottom-banner-homepage nav-inner" : "bottom-banner-console padding-5vw-and-2vw-no-pt" } between-flex bottom-banner-inside-console` }>
            <div style={{ display: "flex", flexDirection: "row", gap: "7px", alignItems: "center" }}>
                <div style={{ width: "20px", height: "20px" }}>
                    < IconUsers color="black" size="20" /></div>
                <GestaltText color="dark" size="200" weight="bold">
                    We're sold out! Please join the waitlist for our upcoming batch of GPUs and receive $15 in compute credits.
                </GestaltText>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end" }}>
                <Button disabled={waitlisting} onClick={() => setShowWaitlistModal(curr => !curr)} c="light" color="#e60023" size="xs">
                    <GestaltText color="light" size="200" weight="bold">{waitlisting ? < Loader size="xs" color="black" /> : "Join Waitlist"}</GestaltText>
                </Button>
            </div>
            < WaitlistModal setShowComponent={setShowWaitlistModal} showComponent={showWaitlistModal} />
        </motion.div>
    )
}

export default function WaitlistModal({ showComponent, setShowComponent }: { showComponent: boolean, setShowComponent: React.Dispatch<React.SetStateAction<boolean>> }) {
    const HEADER_ZINDEX = new FixedZIndex(10);
    const zIndex = new CompositeZIndex([HEADER_ZINDEX]);
    const [selectedGPU, setSelectedGPU] = useState("CPU");
    const { showSelectGPUModal, setShowSelectGPUModal } = useContext(ContextProvider);
    const [waitlisting, setWaitlisting] = useState(false);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState({
        email: "",
        gpu: "",
    });

    const waitlistMutation = useMutation({
        mutationFn: () => {
            setWaitlisting(true);
            return axios.get(`${BASE_URL}/user/waitlist/join/${email}/${selectedGPU}`)
        },
        onSuccess: ({ data }) => {
            setWaitlisting(false);
            showSuccessNotification("Successfully waitlisted", `Your email at ${ email } has been successfully waitlisted.`)
            setEmail("")
            setShowComponent(false);
        },
        onError: (e) => {
            setWaitlisting(false);
            const errorStr = fetchErrorString(e);
            setErrorMessage({
                email: errorStr,
                gpu: errorStr,
            })
            return;
        },
        retry: false,
    });

    const resetAndClose = () => {
        setSelectedGPU("CPU");
        setShowComponent(false);
    }

    const handleSubmit = () => {
        if (!email.length ||
            /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(email) ===
            false) {
                setErrorMessage((curr) => {
                    return {
                        ...curr,
                        email: "Email is not valid",
                    }
                })
                return
        }
        waitlistMutation.mutate()
    }

    useEffect(() => {
        setErrorMessage({
            email: "",
            gpu: "",
        })
    }, [email, selectedGPU])

    if (showComponent) {
        return (
            <Layer zIndex={zIndex}>
                <Modal
                    accessibilityModalLabel="Choose how to claim site"
                    align="start"
                    heading={
                        <Flex justifyContent="between">
                            <Heading size="500" accessibilityLevel={1}>
                                Join waitlist
                                { waitlisting && <Loader style={{ marginLeft: "8px" }} size="sm" color="black" /> }
                            </Heading>
                            <IconButton
                                accessibilityLabel="Dismiss modal"
                                bgColor="white"
                                icon="cancel"
                                iconColor="darkGray"
                                onClick={() => setShowComponent(false)}
                                size="sm"
                            />
                        </Flex>
                    }
                    onDismiss={() => {
                        setShowComponent(false);
                    }}
                    footer={
                        <Flex justifyContent="end" gap={2}>
                            <GestaltButton onClick={resetAndClose} color="gray" text="Cancel" />
                            <GestaltButton disabled={waitlisting} onClick={handleSubmit} color="blue" text="Join waitlist" />
                        </Flex>
                    }
                    size="sm"
                >
                    < Flex direction="column" gap={4}>
                        
                        <Box width={"100%"}>
                            <TextField
                                autoComplete="email"
                                errorMessage={errorMessage.email}
                                id="waitlist-email"
                                label="Email"
                                onChange={({ value }) => {
                                    setEmail(value);
                                }}
                                helperText="Please enter a helpful email where we can easily reach you."
                                placeholder="johndoe@gmail.com"
                                type="email"
                                value={email}
                            />
                        </Box>

                        <Box width={"100%"}>
                            <Box marginBottom={2} width={"100%"}>
                                < GestaltText color="dark" size="100" >
                                    Desired machine type
                                </GestaltText>
                            </Box>
                            < SelectOptionComponent selectedGPU={selectedGPU} setSelectedGPU={setSelectedGPU} />
                            <SelectGPUModal
                                setSelectedGPU={setSelectedGPU}
                                selectedGPU={selectedGPU}
                            />
                            {
                                errorMessage.gpu != "" &&
                                < GestaltText size="100" color="error">
                                    {
                                        errorMessage.gpu
                                    }
                                </GestaltText>
                            }
                        </Box>

                        < GestaltText color="subtle" size="100">
                            We are excited that you are considering joining our cloud. At the moment, we are at capacity and working hard to increase our cluster. We apologize for the inconvenience caused and would like to compensate for it by providing early access to our next batch of GPUs and a $15 credit.
                        </GestaltText>
                    </Flex>
                </Modal>
            </Layer>
        )
    } else {
        return null;
    }
}