import { createContext } from "react";
import { ContextProviderI } from "./types";

const sample: ContextProviderI = {
  token: undefined,
  email: undefined,
  setToken: () => {},
  setEmail: () => {},
  navVisible: true,
  setNavVisible: () => {},
  showAuthModal: false,
  setShowAuthModal: () => {},
  currBackgroundColor: "#5680ff",
  setCurrBackgroundColor: () => {},
  setShowSelectGPUModal: () => {},
  showSelectGPUModal: false,
  verifiedStatus: {
    phoneNumber: "",
    phoneVerified: true,
    emailVerified: true,
  },
  setVerifiedStatus: () => {},
  logout: () => {},
  tracker: [],
  setTracker: () => [],
  currTextColor: "",
  setCurrentTextColor: () => {},
  topConsoleButtonText: "white",
  setTopConsoleButtonText: () => {},
  showTopHeaderMenu: false,
  setShowTopHeaderMenu: () => {},
  showAtCapacityBanner: true, 
  setShowAtCapacityBanner: () => {},
};

const ContextProvider = createContext(sample);

export default ContextProvider;
