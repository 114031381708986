import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_51NcpHBCxPsnd6lGCbt1ZgFbDAMzWqFLfmPSN3XsI9ETvfjLwBXPbBH5TaqR3Psey8Cg4EL2otHzPoBma5xMFfZU400iYjf8ikt")

const StripeWrapper = ({ clientSecret, setClientSecret, children }: { clientSecret: string, setClientSecret: any, children: React.ReactElement }) => {
  if (!clientSecret) {
    return <div> missing client secret </div>
  }

  const appearance = {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: '#0A2540',
      colorPrimary: '#EFC078',
      accessibleColorOnColorPrimary: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#727F96',
      tabIconColor: 'white',
      logoColor: 'dark'
    },
    rules: {
      '.Input, .Block': {
        backgroundColor: 'transparent',
        border: '1.5px solid var(--colorPrimary)'
      }
    }
  }  
  
  const options = {
    clientSecret: clientSecret,
    appearance : appearance,
  }

  return (
    // @ts-ignore
    <Elements stripe={stripePromise} options={options}>
      { children }
    </Elements>
  )
}

export default StripeWrapper;