import { Avatar, Container, Image, Paper, SimpleGrid, Text, ThemeIcon, Timeline, Title, ActionIcon } from "@mantine/core";
import Anchor from "../custom-items/CustomAnchor";
import { IconAnchor, IconBook2, IconBrandLinkedin, IconCloud } from "@tabler/icons-react";
import { Button } from "gestalt";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContextProvider from "../../ContextProvider";
import { DISCORD_SERVER_INVITE_LINK, DOCS_URL, HIRING_EMAIL } from "../../constants";
import "./AboutPage.css";
// @ts-ignore
import footerClasses from './FooterLinks.module.css';
// @ts-ignore
import classes from "./AboutPage.module.css";

function getBrightnessFromHex(hex: string) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (0.299 * r) + (0.587 * g) + (0.114 * b);

    return brightness;
}

const teamMembers = [
    {
        name: "Pierre",
        title: "CEO",
        email: "pierre@neural-logic.com",
        description: "Pierre was a Software Engineer at Tesla and Twilio. He was experimenting with GPU clouds, when he realized that the service could be offered at a much lower cost.",
        image: "https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/profile-pic-homepage.jpeg",
        linkedin: "https://www.linkedin.com/in/prabuddharb"
    },
]

const About = () => {
    const navigate = useNavigate();
    const { setCurrBackgroundColor, currBackgroundColor, currTextColor, setCurrentTextColor, setShowTopHeaderMenu, setTopConsoleButtonText } = useContext(ContextProvider);
    const firstContainerRef = useRef(null);
    const secondContainerRef = useRef(null);
    const thirdContainerRef = useRef(null);
    const forthContainerRef = useRef(null);

    const cheapGPUBtnRef = useRef(null);

    const [firstContainerIntersecting, setFirstContainerIntersecting] = useState(false);
    const [secondContainerIntersecting, setSecondContainerIntersecting] = useState(false);
    const [thirdContainerIntersecting, setThirdContainerIntersecting] = useState(false);
    const [forthContainerIntersecting, setForthContainerIntersecting] = useState(false);

    useEffect(() => {
        setCurrBackgroundColor("#640000")
        setCurrentTextColor("#ffe4c1")
    }, [])

    useEffect(() => {
        if (cheapGPUBtnRef != null && cheapGPUBtnRef.current != null) {
            // @ts-ignore
            cheapGPUBtnRef.current.style.backgroundColor = "#ffe4c1"
        };;

        document.body.style.backgroundColor = "#003440";

        return () => { 
            document.body.style.backgroundColor = "" 
        }
    }, [])

    const firstContainerObserver = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setFirstContainerIntersecting(entry.isIntersecting),
            ),
        [firstContainerRef],
    );

    const secondContainerObserver = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setSecondContainerIntersecting(entry.isIntersecting),
            ),
        [secondContainerRef],
    );

    const thirdContainerObserver = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setThirdContainerIntersecting(entry.isIntersecting),
            ),
        [thirdContainerRef],
    );

    const forthContainerObserver = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setForthContainerIntersecting(entry.isIntersecting),
            ),
        [forthContainerRef],
    );

    useEffect(() => {
        // @ts-ignore
        firstContainerObserver.observe(firstContainerRef.current)
        // @ts-ignore
        secondContainerObserver.observe(secondContainerRef.current);
        // @ts-ignore
        thirdContainerObserver.observe(thirdContainerRef.current);
        // @ts-ignore
        forthContainerObserver.observe(forthContainerRef.current);

        setShowTopHeaderMenu(true)

        return () => secondContainerObserver.disconnect();
    }, []);

    useEffect(() => {
        if (firstContainerIntersecting) {
            setCurrBackgroundColor("#003440")
            setCurrentTextColor("#ffe4c1")
        }
    }, [firstContainerIntersecting]);

    useEffect(() => {
        if (secondContainerIntersecting) {
            setCurrBackgroundColor("#f3f9ff")
            setCurrentTextColor("#111111")
            setTopConsoleButtonText("blue")
        }
    }, [secondContainerIntersecting]);

    useEffect(() => {
        if (thirdContainerIntersecting) {
            setCurrBackgroundColor("#000000")
            setCurrentTextColor("#FFFFFF")
        }
    }, [thirdContainerIntersecting]);

    useEffect(() => {
        if (forthContainerIntersecting) {
            setCurrBackgroundColor("#003440")
            setCurrentTextColor("#ffe4c1")
        } else {
            setCurrBackgroundColor("#000000")
            setCurrentTextColor("#FFFFFF")
        }
    }, [forthContainerIntersecting]);

    const secondaryColor = getBrightnessFromHex(currBackgroundColor) >= 128 ? "#000000" : "#dadada";

    return (
        <div className="entire-page-wrapper" style={{ backgroundColor: currBackgroundColor, color: currTextColor }}>
            <div className="first-page-container">
                <div className="about-text" style={{ flex: 0.6, display: "flex", alignItems: "flex-end" }}>
                    < div ref={firstContainerRef} className="about-header">
                        Democratizing AI
                    </div>
                </div>
                <div className="about-text smaller-text" style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    We want to make GPUs cheaper to rent and make them more accessible over the browser. We aim to stay extremely lean - hiring a new person for every 3,000 active users or so - and pass on the cost savings to our users. This ensures a sustainable business model, where users enjoy low cost and we enjoy sustainable profits.
                    < Button ref={ cheapGPUBtnRef } size="lg" text="Join today" name="about-page-sign-in-btn" color="transparent" />
                </div>
            </div>
            <div className="abt-second-container">
                <div ref={secondContainerRef} style={{ position: "absolute", width: "40px", height: "40px", top: "50%" }}>
                </div>
            </div>
            <div className="abt-third-container">
                <div style={{ flexDirection: "column", gap: "30px" }} className="centered-flex max-width-90vw">
                    <Title className={classes.title} order={2}> Roadmap </Title>
                    <Timeline color="black" active={0} radius={"md"} bulletSize={48} lineWidth={2}>
                        <Timeline.Item styles={{ itemTitle: { fontSize: "22px", color: currTextColor }, itemBullet: { border: "2px solid white" } }} bullet={<IconCloud size={32} />} title="GPU Cloud">
                            <Text c={secondaryColor} size="lg">Audience: ML/AI Researchers, Gamers, and Enterprises </Text>
                            <Text size="xs" c='cyan' mt={4}>Public Beta</Text>
                        </Timeline.Item>

                        <Timeline.Item styles={{ itemTitle: { fontSize: "22px", color: currTextColor }, itemBullet: { backgroundColor: "white", border: "2px solid black" } }} bullet={<IconBook2 color="black" size={32} />} title="Notebooks">
                            <Text c={secondaryColor} size="lg">Audience: ML/AI Model trainers, fine-tuners</Text>
                            <Text size="xs" c="cyan" mt={4}>In development</Text>
                        </Timeline.Item>

                        <Timeline.Item styles={{ itemTitle: { fontSize: "22px", color: currTextColor }, itemBullet: { backgroundColor: "white", border: "2px solid black" } }} title="Deployments" bullet={<IconAnchor color="black" size={28} />} lineVariant="dashed">
                            <Text ref={thirdContainerRef} c={secondaryColor} size="lg">Audience: Enterprises</Text>
                            <Text size="xs" c="cyan" mt={4}>In development</Text>
                        </Timeline.Item>
                    </Timeline>

                </div>
                < FeaturesImages secondaryColor={secondaryColor} />

                <div>
                    <Title style={{ marginTop: 0, marginBottom: "20px" }} className={classes.title} order={2}>Team</Title>
                    <Container style={{ position: "relative" }} size={800} className={`${ classes.wrapper }`}>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "30px", justifyContent: "center" }}>
                            {
                                teamMembers.map((member, i) => < TeamsDisplay secondaryColor={secondaryColor} member={member} />)
                            }
                        </div>
                    </Container>
                </div>
            </div>
            <div>
                <FooterLinks secondaryColor={secondaryColor} textColor={currTextColor} reference={forthContainerRef} />
            </div>
        </div>
    )
}

const data = [
    {
        image: 'https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/hammer.png',
        title: 'Do what it takes',
        description: "We do not hire domain-specific people. There are character traits (open-mindedness, risk taking etc.) that work in every domain and we are looking for those.",
    },
    {
        image: 'https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/habits.png',
        title: "Demonstrated history",
        description: 'Changing habits takes a lot of time. If you have been consistent in delivering on your promises in the past, you will fit well here.',
    },
    {
        image: 'https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/motivated.png',
        title: 'Internally motivated',
        description: 'We do not believe that people can be motivated by external factors. You are either motivated on your own or you are not.',
    },
    {
        image: 'https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/risk-taking.png',
        title: 'Calculated risk taking',
        description: 'It is not a gamble if you know how to count the cards. Great products are not an art but a science - the science of understanding what people and enterprises want.',
    },
];

export function FeaturesImages({ reference, secondaryColor }: any) {
    const items = data.map((item, e) => (
        <div className={classes.item} key={item.image}>
            <ThemeIcon variant="light" className={classes.itemIcon} size={60} radius="md">
                <Image className="recruitingQualities" src={item.image} />
            </ThemeIcon>

            <div>
                <Text fw={700} fz="lg" className={classes.itemTitle}>
                    {item.title}
                </Text>
                <Text c={secondaryColor}>{item.description}</Text>
            </div>
        </div>
    ));

    return (
        <Container style={{ position: "relative" }} size={700} className={`${ classes.wrapper}`}>
            <div ref={reference} style={{ position: "absolute", width: "40px", height: "40px", top: "50%" }}></div>
            <Text className={classes.supTitle}>WE'RE GROWING FAST. GROW WITH US</Text>

            <Title className={classes.title} order={2}>
                We are doubling our infrastructure every <span className={classes.highlight}>three weeks</span>
            </Title>

            <Container size={660} p={0}>
                <Text c={secondaryColor} className={classes.description}>
                    That means we need a lot more help to scale up. We do not advertise like other companies because we like to hire people that we know. If you are interested, please send your resume to <Anchor
                        style={{
                            fontSize: "inherit",
                            color: secondaryColor,
                            textDecoration: "underline",
                        }}
                        href={`mailto:${HIRING_EMAIL}`}
                        isHref={true}
                    >
                        {HIRING_EMAIL}
                    </Anchor>. Here's what we look for:
                </Text>
            </Container>

            <SimpleGrid cols={{ base: 1, xs: 2 }} spacing={50} mt={30}>
                {items}
            </SimpleGrid>
        </Container>
    );
}

const TeamsDisplay = ({ member, secondaryColor }: any) => {
    return (
        <Paper radius="md" withBorder p="lg" className="team-container-wrapper">
            <Avatar
                src={member.image}
                size={120}
                radius={120}
                mx="auto"
            />
            <Text ta="center" fz="lg" fw={500} mt="md">
                {member.name}
            </Text>
            <Text ta="center" c="orange" fz="sm" mb={10}>
                {member.email} • {member.title}
            </Text>

            <Text className="description-wrapper" ta="center" c={secondaryColor} fz="sm">
                {member.description}
            </Text>

            { member.linkedin != null ?
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    < ActionIcon onClick={() => window.open(member.linkedin, "_blank")} style={{ textAlign: "center", marginTop: "4px", backgroundColor: "#0077b5" }}>
                        < IconBrandLinkedin />
                    </ActionIcon>
                </div>
            : null }
        </Paper>
    )
}

const FooterLinks = ({ reference, textColor, secondaryColor }: any) => {
    const navigate = useNavigate();

    const footerData = [
        {
            title: 'Community',
            links: [
                // TODO: add discord link
                { label: 'Join Discord', link: () => window.open(DISCORD_SERVER_INVITE_LINK, "_blank") },
            ],
        },
        {
            title: 'About',
            links: [
                { label: 'Pricing', link: () => window.open(`${ DOCS_URL }/docs/category/pricing`, "_blank") },
                { label: 'Support', link: () => navigate("/support") },
            ],
        },
        {
            title: 'Legal',
            links: [
                { label: 'Privacy Policy', link: () => navigate("/privacy") },
                { label: 'AUP', link: () => navigate("/aup") },
                { label: 'Compliance', link: () => navigate("/tos") },
                { label: 'Data Processing Agreement', link: () => navigate("/compliance") },
                { label: 'Terms of Service', link: () => navigate("/data-processing-agreement") },
            ],
        },
    ];

    const groups = footerData.map((group) => {
        const links = group.links.map((link, index) => (
            <Text
                key={index}
                className={`${footerClasses.link} on-hover-pointer`}
                component="a"
                onClick={link.link}
            >
                {link.label}
            </Text>
        ));

        return (
            <div className={footerClasses.wrapper} key={group.title}>
                <Text className={footerClasses.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={footerClasses.footer}>
            <Container style={{ borderTop: `1px solid ${textColor}` }} className={footerClasses.inner}>
                <div className={footerClasses.logo}>
                    <Text size="xs" c={secondaryColor} className={footerClasses.description}>
                        Neural Logic - the cheaper way to access cloud GPUs.
                    </Text>
                </div>
                <div className={footerClasses.groups}>{groups}</div>
            </Container>
            <Container className={footerClasses.afterFooter}>
                <Text ref={reference} c={secondaryColor} size="sm">
                    ©2023 Neural Logic LLC. All rights reserved.
                </Text>
            </Container>
        </footer>
    );
}

export default About;