export const AllAvailableGPUS = [
  {
    name: "CPU",
    memoryBandwidth: 0,
    cpu: "4",
    ram: "8",
    price: 0.08,
    generation: "Ryzen",
    cuda_cores: 0,
    gpuMemory: 0,
    tensorCore: 0,
    tflops: "-",
  },
  {
    name: "M4000",
    memoryBandwidth: 192,
    cpu: "8",
    ram: "30",
    price: 0.36,
    generation: "Maxwell",
    cuda_cores: 1664,
    gpuMemory: 8,
    tensorCore: 2.6,
    tflops: "-",
  },
  {
    name: "P4000",
    memoryBandwidth: 243,
    cpu: "8",
    ram: "30",
    price: 0.42,
    generation: "Pascal",
    cuda_cores: 1792,
    gpuMemory: 8,
    tensorCore: 5.3,
    tflops: "-",
  },
  {
    name: "P6000",
    memoryBandwidth: 432,
    cpu: "8",
    ram: "30",
    price: 0.82,
    generation: "Pascal",
    cuda_cores: 3840,
    gpuMemory: 24,
    tensorCore: 12.0,
    tflops: "-",
  },
  {
    name: "RTX4000",
    memoryBandwidth: 416,
    cpu: "8",
    ram: "30",
    price: 0.40,
    generation: "Turing",
    cuda_cores: 2304,
    gpuMemory: 8,
    tensorCore: 7.1,
    tflops: "57 TFLOPS (FP32)",
  },
  {
    name: "RTX5000",
    memoryBandwidth: 448,
    cpu: "8",
    ram: "30",
    price: 0.65,
    generation: "Turing",
    cuda_cores: 3072,
    gpuMemory: 16,
    tensorCore: 11.2,
    tflops: "89 TFLOPS (FP32)",
  },
  {
    name: "A4000",
    memoryBandwidth: 448,
    cpu: "8",
    ram: "45",
    price: 0.5,
    generation: "Ampere",
    cuda_cores: 6144,
    gpuMemory: 16,
    tensorCore: 19.2,
    tflops: "153 TFLOPS (FP16)",
  },
  // {
  //   name: "A5000",
  //   memoryBandwidth: 768,
  //   cpu: "8",
  //   ram: "45",
  //   price: 1.38,
  //   generation: "Ampere",
  //   cuda_cores: 8192,
  //   gpuMemory: 24,
  //   tensorCore: 27.8,
  //   tflops: "222 TFLOPS (FP16)",
  // },
  // {
  //   name: "A6000",
  //   memoryBandwidth: 768,
  //   cpu: "8",
  //   ram: "45",
  //   price: 1.89,
  //   generation: "Ampere",
  //   cuda_cores: 10752,
  //   gpuMemory: 48,
  //   tensorCore: 38.7,
  //   tflops: "309 TFLOPS (FP16)",
  // },
  // {
  //   name: "A100",
  //   memoryBandwidth: 1555,
  //   cpu: "12",
  //   ram: "90",
  //   price: 3.09,
  //   generation: "Ampere",
  //   cuda_cores: 6912,
  //   gpuMemory: 40,
  //   tensorCore: 19.5,
  //   tflops: "156 / 312 (FP32/16) TFLOPS",
  // },
];
