import { Anchor } from "@mantine/core";

const CustomAnchor = ({ children, style, onClick, href, className, isHref = false }: { children: any, style ?: Object, onClick ?: () => void, href ?: string, className?: string, isHref ?: boolean }) => {
    if (isHref) {
        return  (
            <Anchor className={ `on-hover-pointer ${ className }` } target="_blank" href={ href } style={ style }>
                { children }
            </Anchor>
        )
    } else {
        return (
            <span className={ `on-hover-pointer ${ className }` } onClick={ onClick } style={ style }>
                { children }
            </span>
        )
    }
}

export default CustomAnchor;