import { createContext } from "react";
import { RDPPagePageContextI } from "../../types";

const rdpPageContext: RDPPagePageContextI = {
  machineId: "",
  nodeId: "",
};

const RDPDetailPageContext = createContext(rdpPageContext);

export default RDPDetailPageContext;
