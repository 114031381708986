import "./VerificationPage.css";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Stepper,
  Button,
  Group,
  rem,
  Input,
  ActionIcon,
  Loader,
  Text as MantineText,
  Title,
  List,
  Text,
} from "@mantine/core";
import Anchor from "../../custom-items/CustomAnchor"
import ContextProvider from "../../../ContextProvider";
import {
  IconExclamationCircle,
  IconMail,
  IconMessage,
  IconPhone,
  IconRecordMail,
  IconRepeat,
  IconTrash,
} from "@tabler/icons-react";
import CustomLabel from "../../custom-items/CustomLabel";
import MantineCustomizedButton from "../../custom-items/MantineCustomizedButton";
import { Label, Tooltip } from "gestalt";
// @ts-ignore
import errorClasses from "../../../notifications/ErrorNotifications.module.css";
import { notifications } from "@mantine/notifications";
import { BASE_URL, SUPPORT_EMAIL } from "../../../constants";
import axios from "axios";
import { showErrorNotifications } from "../../../utils";
import {
  addVerifiedStatusToCookies,
  showErrorNotificationWithTitleAndMessage,
  showSuccessNotification,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { initVerification } from "../../../apis/machines";
import CustomLoader from "../../custom-items/Loader";
import { getHotkeyHandler } from '@mantine/hooks';

const VerificationPage = () => {
  const navigate = useNavigate();
  const { setVerifiedStatus } = useContext(ContextProvider);
  const { isLoading, error } = useQuery({
    queryKey: "GET_INIT_VERIFICATION_STATUS",
    queryFn: () => initVerification(),
    onSuccess: (d) => {
      setVerifiedStatus(() => {
        return d;
      });
      addVerifiedStatusToCookies(d);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div className="machine-loading-wrapper">
          <CustomLoader message={"FETCHING VERIFICATION STATUS..."} animate={false} />
        </div>
      </div>
    );
  }

  if (error) {
    navigate("/console")
    return
  }

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f3f3f3",
      }}
    >
      <div className="console-home-wrapper">
        <div className="stepper-wrapper">
          <VerificationStepper />
        </div>
      </div>
    </div>
  );
};

const formatToPhone = (inputNumber: string) => {
  let val = inputNumber.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = val.substring(0, 3);
  const middle = val.substring(3, 6);
  const last = val.substring(6, 10);

  if (val.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  } else if (val.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (val.length > 0) {
    return `(${areaCode}`;
  }
  return "";
};

function VerificationStepper() {
  const [active, setActive] = useState(1);
  const { verifiedStatus, setVerifiedStatus, email } =
    useContext(ContextProvider);
  const [isLoading, setIsLoading] = useState({
    phoneIsEmpty: false,
    phoneVerified: false,
    emailVerified: false,
  });
  const [OTPValues, setOTPValues] = useState(["", "", "", ""]);
  const inputRefCollection = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [freeMachineConfig, setFreeMachineConfig] = useState({
    region: "NY",
    os: "ubuntu",
  })
  const [freeVmMachineInfo, setFreeVMMachineInfo] = useState({
    nodeID: "",
    uniqueID: "",
  })

  useEffect(() => {
    if (verifiedStatus?.phoneVerified) {
      navigate("/console")
    }

    if (verifiedStatus?.emailVerified == false) {
      setActive(0);
    } else if (verifiedStatus?.phoneNumber == "" || !verifiedStatus?.phoneVerified) {
      setActive(1);
    }

    if (verifiedStatus?.phoneNumber != "") {
      setPhoneNumber(() => formatToPhone(verifiedStatus!.phoneNumber));
    }
  }, []);

  let phoneInputRef = useRef(null);

  function fetchNumeric(inputString: string) {
    // Use a regular expression to match all numeric characters
    const numericCharacters = inputString.match(/\d/g);

    // Join the matched numeric characters into a string
    if (numericCharacters) {
      return numericCharacters.join("");
    } else {
      return ""; // Return an empty string if no numeric characters are found
    }
  }

  useLayoutEffect(() => {
    let windowHeight = window.innerHeight;
    if (windowHeight > 700) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  }, []);

  const handleOTPVerify = () => {
    let otpInString = OTPValues.join("");
    if (otpInString.length != 4) {
      notifications.show({
        title: "Failed to send OPT",
        message: "OTP length is invalid",
        color: "red",
        icon: <IconExclamationCircle />,
        autoClose: 3000,
        classNames: errorClasses,
      });
      return;
    }
    setIsLoading((curr) => {
      return {
        ...curr,
        phoneVerified: true,
      };
    });

    (async () => {
      try {
        let resp = await axios.post(`${BASE_URL}/verify-otp`, {
          otp: otpInString,
          ...freeMachineConfig,
        });
        // @ts-ignore
        setVerifiedStatus((curr) => {
          return { ...curr, phoneVerified: resp?.data?.payload?.phoneVerified };
        });

        // @ts-ignore
        addVerifiedStatusToCookies({
          ...verifiedStatus,
          phoneVerified: resp.data.payload.phoneVerified,
        });
        let unique_id = resp?.data?.payload?.unique_id;
        let node_id = resp?.data?.payload?.node_id;


        showSuccessNotification("Creating complimentary machine", "When you sign up for the first time, we give you a complimentary machine that you can use for 48 hours. You can use the machine to familiarize yourself with the platform.", 5000)
        navigate(`/status/${unique_id}/${node_id}?free=yes`)
      } catch (e) {
        showErrorNotifications(e, "Could not verify phone number");
        setIsLoading((curr) => {
          return {
            ...curr,
            phoneVerified: false,
          };
        });
        return;
      }

      setIsLoading((curr) => {
        return {
          ...curr,
          phoneVerified: false,
        };
      });
    })();
  };

  const submitPhoneNumber = (numericNumber: string) => {
    if (numericNumber.length != 10) {
      notifications.show({
        title: "Wrong phone number",
        message: "Enter a valid 10 digit phone number",
        color: "red",
        icon: <IconExclamationCircle />,
        autoClose: 4000,
        classNames: errorClasses,
      });

      return;
    }

    setIsLoading((curr) => {
      return {
        ...curr,
        phoneIsEmpty: true,
      };
    });

    (async () => {
      try {
        let resp = await axios.post(`${BASE_URL}/verification-status`, {
          phone_number: numericNumber,
        });
        // @ts-ignore
        addVerifiedStatusToCookies({
          ...verifiedStatus,
          phoneNumber: resp.data.payload.phoneNumber,
        });
        // @ts-ignore
        setVerifiedStatus((curr) => {
          return { ...curr, phoneNumber: resp.data.payload.phoneNumber };
        });
        setActive(() => 2);
      } catch (e) {
        showErrorNotifications(e, "Could not verify phone number");
        setIsLoading((curr) => {
          return {
            ...curr,
            phoneIsEmpty: false,
          };
        });
        return;
      }

      setIsLoading((curr) => {
        return {
          ...curr,
          phoneIsEmpty: false,
        };
      });
    })();
  };

  const handlePhoneChange = (e: any) => {
    e.preventDefault();
    let val = e.target.value;
    let formattedNumber = formatToPhone(val);
    setPhoneNumber(() => formattedNumber);
    if (fetchNumeric(formattedNumber).length == 10) {
      submitPhoneNumber(fetchNumeric(formattedNumber));
    }
  };

  const handleEmailConfirmed = () => {
    (async () => {
      try {
        let resp = await axios.get(`${BASE_URL}/init-verification-status`);
        setVerifiedStatus(() => {
          return resp.data.payload;
        });
        addVerifiedStatusToCookies(resp.data.payload);

        if (resp.data.payload.emailVerified == false) {
          showErrorNotificationWithTitleAndMessage(
            "Email has not been verified yet",
            `Please verify your email at ${email}`,
          );
        } else {
          setActive(1)
        }
      } catch (e) {
        setActive(0)
        showErrorNotifications(e, "Could not validate email verification");
      }
    })();
  };

  // @ts-ignore
  const currNumber = formatToPhone(verifiedStatus.phoneNumber);

  const moveBackOneEach = (val: string, idx: number) => {
    let newStr = OTPValues;
    let updatedArr = newStr.map((e) => (e != "" ? e : " "));
    updatedArr[idx] = val;

    let newJoined = updatedArr.join("");
    let newArr: string[] = [];
    for (let i = 0; i < 4; i++) {
      newArr.push(newJoined[i] != " " ? newJoined[i] : "");
    }
    setOTPValues(() => {
      return newArr;
    });
  };

  const deleteAndBackspace = (val: string, idx: number) => {
    let newStr = OTPValues;
    let updatedArr = newStr.map((e) => (e != "" ? e : " "));
    updatedArr[idx] = " ";
    let newJoined = updatedArr.join("");
    let newnew = newJoined.split("");
    setOTPValues(() => {
      return newnew;
    });
  };

  const handleEmailResend = () => {
    (async () => {
      try {
        let resp = await axios.get(`${BASE_URL}/resend-email-verification`);
        showSuccessNotification(
          "Email resent",
          `Please verify your email at ${email}`,
        );
      } catch (e: any) {
        showErrorNotifications(e, "Could not resend email");
        return;
      }
    })();
  };

  return (
    <>
      <Stepper
        radius="md"
        styles={{
          root: {
            width: "100%!important",
            height: "100dvh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "60px",
            paddingLeft: "20px",
            paddingRight: "20px",
          },
          content: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          },
        }}
        active={active}
        onStepClick={setActive}
        orientation={window.innerWidth > 768 ? "horizontal" : "vertical"}
      >
        <Stepper.Step
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          label="Email"
          description="Verify email"
          icon={<IconRecordMail style={{ width: rem(18), height: rem(18) }} />}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: rem(6) }}
          >
            <div
              className="otp-verification-wrapper"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: "600px",
                paddingTop: "12px",
                gap: rem(2),
              }}
            >
              <Title order={1}>Please verify your email</Title>
              <List>
                <List.Item>
                  Check your email for a message from
                  pierre@neural-logic.com.
                </List.Item>
                <List.Item>Click the link in the email to verify it.</List.Item>
                <List.Item>
                  The email's subject begins with [neural-logic]
                </List.Item>
                <List.Item>
                  If you don't see it in your main inbox, look in your spam or
                  trash folder, as it might be there.
                </List.Item>
                <List.Item>
                  If nothing works out, email support at <Anchor isHref={true} className="must-underline" href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL} </Anchor>
                </List.Item>
              </List>

              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div style={{ marginRight: "auto", paddingTop: "12px" }}>
                  {isLoading.emailVerified ? (
                    <Button variant="outline" size="md" color="#FFFFFF">
                      <Loader color="#FFFFFF" size="xs" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleEmailResend}
                      variant="outline"
                      className="secondary-button"
                      size="md"
                      color="black"
                    >
                      Resend
                    </Button>
                  )}
                </div>

                <div style={{ marginLeft: "auto", paddingTop: "12px" }}>
                  {isLoading.emailVerified ? (
                    <Button variant="outline" size="md" color="#000000">
                      <Loader color="#000000" size="xs" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleEmailConfirmed}
                      className="submit-btn"
                      variant="outline"
                      size="md"
                      color="#FFFFFF"
                    >
                      Already verified
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Stepper.Step>

        <Stepper.Step
          styles={{}}
          label="Phone"
          description="Enter phone number"
          icon={
            <IconPhone
              color="black"
              strokeWidth={2}
              style={{ width: rem(24), height: rem(24) }}
            />
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: rem(12),
              width: "100%",
              maxWidth: "440px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ marginRight: "auto", fontWeight: 500 }}>
                <Label htmlFor="phone-number-input">Phone number</Label>
              </div>
              <div
                className="phone-num-input"
                style={{ display: "flex", gap: "10px" }}
              >
                <Input
                  ref={phoneInputRef}
                  /* @ts-ignore */
                  placeholder={
                    verifiedStatus!.phoneNumber != currNumber
                      ? currNumber
                      : "(341) 204-7713"
                  }
                  id="phone-number-input"
                  autoFocus={active === 0}
                  value={phoneNumber}
                  onChange={(e) => handlePhoneChange(e)}
                  size="lg"
                  styles={{ input: { fontSize: "24px" } }}
                  style={{ width: "100%" }}
                  onKeyDown={getHotkeyHandler([
                    ['mod+Enter', () => submitPhoneNumber(fetchNumeric(phoneNumber))],
                  ])}
                />
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {isLoading.phoneIsEmpty ? (
                <Button variant="outline" size="md" color="#000000">
                  <Loader color="#000000" size="xs" />
                </Button>
              ) : (
                <Button
                  onClick={() => submitPhoneNumber(fetchNumeric(phoneNumber))}
                  className="submit-btn"
                  variant="outline"
                  size="md"
                  color="#FFFFFF"
                >
                  Send token
                </Button>
              )}
            </div>
          </div>
        </Stepper.Step>

        <Stepper.Step
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          label="Verify"
          description="Verify phone number"
          icon={<IconMessage style={{ width: rem(18), height: rem(18) }} />}
        >
          < OTPVerification OTPValues={OTPValues} deleteAndBackspace={deleteAndBackspace} moveBackOneEach={moveBackOneEach} inputRefCollection={inputRefCollection} setOTPValues={setOTPValues} active={active} isLoading={isLoading} handleOTPVerify={handleOTPVerify} freeMachineConfig={freeMachineConfig} setFreeMachineConfig={setFreeMachineConfig} />
        </Stepper.Step>

      </Stepper>
    </>
  );
}

const OTPVerification = ({ OTPValues, deleteAndBackspace, moveBackOneEach, inputRefCollection, setOTPValues, active, isLoading, handleOTPVerify, freeMachineConfig, setFreeMachineConfig }: any) => {
  useEffect(() => {
    inputRefCollection[0].current.focus()
  }, [])

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: rem(12) }}
    >
      <div
        className="otp-verification-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "440px",
          gap: rem(12),
        }}
      >
        <div>
          <div style={{ marginRight: "auto", fontWeight: 500 }}>
            <Label htmlFor="otp-code-verification">4 digit code</Label>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                maxWidth: "530px",
                width: "100%",
              }}
            >
              {["0", "1", "2", "3"].map((num: string, i: number) => {
                return (
                  <div id="otp-code-verification" className="otp-input">
                    <input
                      value={OTPValues[i]}
                      onKeyDown={(e) => {
                        if (e.key == "ArrowRight") {
                          if (i != 3) {
                            // @ts-ignore
                            inputRefCollection[i + 1]!.current!.focus();
                          }
                          return;
                        }
                        if (e.key == "ArrowLeft") {
                          if (i != 0) {
                            // @ts-ignore
                            inputRefCollection[i - 1]!.current!.focus();
                            // @ts-ignore
                            const length =
                              /* @ts-ignore */
                              inputRefCollection[i - 1]!.current!.value!
                                .length;
                            // @ts-ignore
                            inputRefCollection[
                              i - 1
                            ]!.current.setSelectionRange(length, length);
                          }
                          return;
                        }
                        if (e.key == "Delete" || e.key == "Backspace") {
                          deleteAndBackspace(" ", i);
                          if (i != 0) {
                            // @ts-ignore
                            inputRefCollection[i - 1]!.current!.focus();
                          }
                        }
                        if (e.key == "Enter") {
                          handleOTPVerify()
                        }
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        let val = e.target.value.replace(/\s/g, "");
                        if (val == "") {
                          return;
                        }
                        if (i == 3) {
                          val = val.split("").reverse().join("");
                        }
                        moveBackOneEach(val, i);
                        if (i != 3) {
                          // @ts-ignore
                          inputRefCollection[i + 1]!.current!.focus();
                        }
                      }}
                      autoFocus={active == 1 && i == 0}
                      ref={inputRefCollection[i]}
                      className="otp-input otp-input-border"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="otp-button-wrapper"
          style={{ display: "flex" }}
        >
          <div style={{ marginRight: "auto" }}>
            <Tooltip text="Reset">
              <ActionIcon
                onClick={() => {
                  {
                    setOTPValues(() => ["", "", "", ""]);
                  }
                  // @ts-ignore
                  inputRefCollection[0].current.focus();
                }}
                color="yellow"
                variant="outline"
                style={{ height: "40px", width: "40px" }}
              >
                <IconRepeat strokeWidth={1.9} color="indigo" />
              </ActionIcon>
            </Tooltip>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {isLoading.phoneVerified ? (
              <Button variant="outline" size="md" color="#000000">
                <Loader color="#000000" size="xs" />
              </Button>
            ) : (
              <Button
                onClick={handleOTPVerify}
                className="submit-btn"
                variant="outline"
                size="md"
                color="#FFFFFF"
              >
                Verify
              </Button>
            )}
          </div>
        </div>
        < InfoAboutFreeVMs freeMachineConfig={freeMachineConfig} setFreeMachineConfig={setFreeMachineConfig} />
      </div>
    </div>
  )
}

interface FreeMachineConfigI {
  region: string;
  os: string
}

const InfoAboutFreeVMs = ({ freeMachineConfig, setFreeMachineConfig }: { freeMachineConfig: FreeMachineConfigI, setFreeMachineConfig: React.Dispatch<React.SetStateAction<FreeMachineConfigI>> }) => {
  const SelectMachine = (val: any) => {
    setFreeMachineConfig((curr) => {
      return { ...curr, region: val }
    })
  }

  const SelectOS = (val: any) => {
    setFreeMachineConfig((curr) => {
      return { ...curr, os: val }
    })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", }}>
      <div style={{ fontSize: "12px" }}>
        < Text size="sm" c="dimmed">Once your code is verified, we create a complimentary Machine for you. You can learn what is possible to do with our platform using this machine.
          <p>Please use the section below to configure the region and the Operating System of the machine.</p>
        </Text>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", gap: "15px" }}>
        < Text size="sm" c="dark">Region:</Text>
        <Button onClick={() => SelectMachine("SF")} style={{ padding: 0, }} variant="transparent" color="pink" radius="sm"><Text fs="xs" fw={freeMachineConfig.region == "SF" ? 600 : 400} c={freeMachineConfig.region == "SF" ? "black" : "dimmed"} style={{ fontSize: "14px" }}>SF</Text></Button>
        <Button onClick={() => SelectMachine("NY")} style={{ padding: 0, }} variant="transparent" color="pink" radius="sm"><Text fs="xs" fw={freeMachineConfig.region == "NY" ? 600 : 400} c={freeMachineConfig.region == "NY" ? "black" : "dimmed"} style={{ fontSize: "14px" }}>NY</Text></Button>
      </div>

      <div style={{ display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", gap: "15px", marginTop: "-10px" }}>
        < Text size="sm" c="dark">OS:</Text>
        <Button onClick={() => SelectOS("windows")} style={{ padding: 0, }} variant="transparent" color="pink" radius="sm"><Text fs="xs" fw={freeMachineConfig.os == "windows" ? 600 : 400} c={freeMachineConfig.os == "windows" ? "black" : "dimmed"} style={{ fontSize: "14px" }}>Windows</Text></Button>
        <Button onClick={() => SelectOS("ubuntu")} style={{ padding: 0, }} variant="transparent" color="pink" radius="sm"><Text fs="xs" fw={freeMachineConfig.os == "ubuntu" ? 600 : 400} c={freeMachineConfig.os == "ubuntu" ? "black" : "dimmed"} style={{ fontSize: "14px" }}>Linux</Text></Button>
      </div>
    </div>
  )
}

export default VerificationPage;
