import {
  ActionIcon,
  Button,
  FileButton,
  Loader,
  ScrollArea,
  Tooltip
} from "@mantine/core";
import Anchor from "../custom-items/CustomAnchor";
import { useForm } from "@mantine/form";
import {
  IconTrashFilled,
  IconUpload
} from "@tabler/icons-react";
import axios from "axios";
import {
  FixedZIndex,
  Box as GestaltBox,
  Button as GestaltButton,
  Text as GestaltText,
  Label,
  TextArea,
  TextField
} from "gestalt";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  BASE_URL,
  DISCORD_SERVER_INVITE_LINK,
  DOCS_URL,
  REACT_APP_AWS_KEY,
  REACT_APP_S3_ACCESS_KEY,
  SUPPORT_EMAIL,
} from "../../constants";
import {
  fetchErrorString,
  showErrorNotificationWithTitleAndMessage,
  showErrorNotifications,
  showSuccessNotification
} from "../../utils";
import { ControlsHeader } from "../console/home-segments/Machines";
import "./Support.css";
// @ts-ignore
import classes from "./Support.module.css";

const SupportOutsideConsole = () => {
  const navigate = useNavigate();
  const [progressing, setProgressing] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "black"

    return (() => {
      document.body.style.backgroundColor = ""
    })
  }, [])

  return (
    <div style={{ paddingBottom: "100px", paddingTop: "80px", }} className="auth-single-page background-gpu">

      <div
        className="auth-single-page-navbar"
        onClick={() => navigate("/home")}
      >
        <div className="neural-logic-logo noselect">neural-logic</div>
        <GestaltButton text="Home" color="red" onClick={() => navigate("/home")} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          backgroundColor: "white",
          paddingTop: "40px",
          borderRadius: "16px",
          marginTop: "30px",
        }}
        className="half-page-sections"
      >
        <ScrollArea classNames={{ viewport: classes.scrollareaViewport }} h={"70dvh"}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div>
              <ControlsHeader text="Support" />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                <GestaltText weight="normal" size="300">
                  Report an issue
                </GestaltText>
                {progressing && < Loader size="sm" color="#008753" />}
              </div>
            </div>
            <SubmitIssueForm progressing={progressing} setProgressing={setProgressing} />
          </div>

          <div className="half-and-full">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ControlsHeader text="Connect" />
              <InfoSection />
            </div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

const InfoSection = () => {
  return (
    <div className="info-section">
      If you are facing any issues with the Neural Logic platform, please file
      an issue using the form above. Also remember to read our FAQ and Docs <Anchor 
        isHref={true}
        href={ `${ DOCS_URL }/docs/faqs` }
        className="must-underline">here</Anchor>. They contain solutions to some of the most common problems that you might face.
      <p></p>You can also reach out to us on our Discord server{" "}
      <Anchor
        className="must-underline"
        href={DISCORD_SERVER_INVITE_LINK}
      >
        here.
      </Anchor>
      <p>
        If the issue is super critical, you can reach out to us at{" "}
        <Anchor
          isHref={true}
          className="must-underline"
          href={`mailto:${SUPPORT_EMAIL}`}
        >
          {SUPPORT_EMAIL}.
        </Anchor>{" "}
        We typically respond back in less than three hours for a critical issue.
      </p>
    </div>
  );
};

export function TopicDropdown({ values, setValues, open, setOpen }: any) {
  const PAGE_HEADER_ZINDEX = new FixedZIndex(10);
  const [currentlyActiveDropdownItem, setCurrentlyActiveDropdownItem] =
    useState(0);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (
      anchorRef != null &&
      document.getElementById("demo-dropdown-example-item-0")
    ) {
      // @ts-ignore
      document.getElementById("demo-dropdown-example-item-0").style.width = anchorRef?.current?.offsetWidth!;
    }
  }, []);

  const handleChangeDropdownSelectedItem = (val: string, index: number) => {


    setValues((curr: any) => {
      return {
        ...curr,
        topic: val,
      };
    });
    setCurrentlyActiveDropdownItem(index);
    setOpen(false);
  };

  return (
    <div id="dropdown-wrapper-id">
      <GestaltBox
        display="flex"
        justifyContent="center"
        direction="column"
        width="100%"
      >
        <div className="EP3">
          <Label htmlFor="topic-dropdown">
            <GestaltText size="100">Topic</GestaltText>
          </Label>
        </div>
        <GestaltButton
          name="topic-dropdown-id"
          fullWidth={true}
          accessibilityControls="demo-dropdown-example"
          accessibilityExpanded={open}
          accessibilityHaspopup
          iconEnd="arrow-down"
          color="transparent"
          onClick={() => setOpen((curr: boolean) => !curr)}
          ref={anchorRef}
          selected={open}
          size="lg"
          text="Select Topic "
        />
      </GestaltBox>

      {open && (
        <div style={{ position: "relative", width: "100%", zIndex: 11 }}>
          <GestaltBox
            borderStyle="shadow"
            dangerouslySetInlineStyle={{
              __style: { backgroundColor: "white" },
            }}
            position="absolute"
            marginTop={4}
            id="dropdown-wrapper"
            width="100%"
          >
            <GestaltButton
              onClick={() => handleChangeDropdownSelectedItem("Account", 0)}
              selected={currentlyActiveDropdownItem == 0}
              color="transparent"
              fullWidth={true}
              text="Account"
            ></GestaltButton>
            <GestaltButton
              onClick={() => handleChangeDropdownSelectedItem("Billing", 1)}
              selected={currentlyActiveDropdownItem == 1}
              color="white"
              fullWidth={true}
              text="Billing"
            ></GestaltButton>
            <GestaltButton
              onClick={() =>
                handleChangeDropdownSelectedItem("Technical Issue", 2)
              }
              selected={currentlyActiveDropdownItem == 2}
              color="white"
              fullWidth={true}
              text="Technical Issue"
            ></GestaltButton>
            <GestaltButton
              onClick={() =>
                handleChangeDropdownSelectedItem("Feature Request", 3)
              }
              selected={currentlyActiveDropdownItem == 3}
              color="white"
              fullWidth={true}
              text="Feature Request"
            ></GestaltButton>
            <GestaltButton
              onClick={() =>
                handleChangeDropdownSelectedItem("User experience", 4)
              }
              selected={currentlyActiveDropdownItem == 4}
              color="white"
              fullWidth={true}
              text="User experience"
            ></GestaltButton>
          </GestaltBox>
        </div>
      )}
    </div>
  );
}

const SubmitIssueForm = ({ progressing, setProgressing }: { progressing: any, setProgressing: any }) => {
  const [open, setOpen] = useState(false);
  const resetRef = useRef<() => void>(null);
  const subjectRef = useRef(null);
  const emailRef = useRef(null);
  const [values, setValues] = useState({
    topic: "",
    email: "",
    subject: "",
    description: "",
    machine_id: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    subject: "",
    description: "",
    machine_id: "",
    topic: "",
  });

  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {
      email: "",
      topic: "",
      machine_id: "",
      ticket_subject: "",
      description: "",
      file_upload: [],
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      machine_id: (value) =>
        value.length == 0 || (value.length == 8 && /^[a-z0-9]+$/i.test(value))
          ? null
          : "This should either be empty or should contain a 8 letter alphanumeric machine id.",
      description: (value) =>
        value.length > 25
          ? null
          : "The description must be longer than 25 characters long.",
    },
  });

  const submitIssueMutation = useMutation({
    mutationFn: (params: { imageURLs: string[] }) => {
      setProgressing(true);

      return axios.post(`${BASE_URL}/user/support/create`, {
        ...values,
        image_urls: params.imageURLs,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: "GET_SUPPORT_LIST",
        refetchInactive: true,
      });
      setProgressing(false);
      showSuccessNotification(
        "Successfully filed support",
        "We typically respond back in 2 to 3 hours."
      );
      form.reset();
      setValues((curr) => { return { topic: "", email: "", subject: "", description: "", machine_id: "" } })
    },
    onError: (e) => {
      let errorStr = fetchErrorString(e)

      setErrorMessages((curr) => {
        return {
          ...curr,
          subject: errorStr,
          description: errorStr,
          machine_id: errorStr,
          topic: errorStr,
        }
      })
      setProgressing(false);
      showErrorNotifications(
        e,
        "Could not file support issue. Please try again!"
      );
      return;
    },
    retry: false,
  });

  const handleSubmit = () => {
    if (progressing) {
      showErrorNotificationWithTitleAndMessage(
        "A request is already being sent to the server",
        "Please wait for that to complete"
      );
      return;
    }

    let isError = false;
    if (/^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(values.email) === false) {
      setErrorMessages((curr) => { return { ...curr, email: "Please enter a valid email." } })
      isError = true;
    }

    if (values.description.length < 25) {
      setErrorMessages((curr) => { return { ...curr, description: "Description must be longer that 25 characters in length." } })
      isError = true;
    }
    if (values.subject.length == 0) {
      setErrorMessages((curr) => { return { ...curr, subject: "You must provide a subject." } })
      isError = true;
    }
    if (values.topic == "") {
      setErrorMessages((curr) => { return { ...curr, topic: "Please select a topic. This helps us respond faster by directing issues to the right departments." } })
      isError = true;
    }

    if (isError) {
      return
    }

    setProgressing(true);
    submitIssueMutation.mutate({ imageURLs: [] });
  };

  const resetForm = () => {
    form.reset()
    setValues(() => {
      return {
        topic: "",
        email: "",
        subject: "",
        description: "",
        machine_id: "",
      }
    })
  }

  const clearFile = () => {
    form.setValues((val) => {
      return { ...val, file_upload: [] };
    });
  };

  useEffect(() => {
    let elem = document.getElementById("dropdown-wrapper-id");
    document.addEventListener("mouseup", (e) => {
      // @ts-ignore
      if (!elem?.contains(e?.target)) {

        setOpen(false);
      }
    });

    if (emailRef != null) {
      // @ts-ignore
      emailRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    setErrorMessages(() => {
      return {
        subject: "",
        description: "",
        machine_id: "",
        topic: "",
        email: "",
      }
    })
  }, [values])

  const handleKeyDownInsideForm = (keyCode: string) => {
    if (keyCode == 'Enter') {
      handleSubmit()
    }
  }

  return (

    <>
      <GestaltBox width={"100%"}>
        <TextField
          errorMessage={errorMessages.email}
          helperText="This will be our only way of getting back to you. So please enter an email you frequently monitor."
          id={"email-id"}
          onChange={(e) =>
            setValues((curr) => {
              return { ...curr, email: e.value };
            })
          }
          onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)}
          ref={emailRef}
          placeholder="Examples: john.doe@gmail.com"
          value={values.email}
          label="Email"
          maxLength={{ characterCount: 60, errorAccessibilityLabel: "Email must be less than 60 characters." }}
        />
      </GestaltBox>

      <GestaltBox width={"100%"}>
        <TextField
          errorMessage={errorMessages.subject}
          helperText="Briefly describe your issue. Helpful subjects lower response time."
          id={"subject-id"}
          onChange={(e) =>
            setValues((curr) => {
              return { ...curr, subject: e.value };
            })
          }
          onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)}
          ref={subjectRef}
          placeholder="Examples: Can't create machine, machine unresponsive."
          value={values.subject}
          label="Subject"
          maxLength={{ characterCount: 60, errorAccessibilityLabel: "Subject must be less than 60 characters" }}
        />
      </GestaltBox>

      {
        values.subject != "" &&
        <GestaltBox width={"100%"}>
          <TextField
            errorMessage={errorMessages.machine_id}
            helperText="This field is optional. If possible, please add the machine you are having issues with."
            id={"id-id"}
            onChange={(e) =>
              setValues((curr) => {
                return { ...curr, machine_id: e.value };
              })
            }
            placeholder="Optional. Example: eb0e45bc"
            value={values.machine_id}
            label="Machine ID"
          />
        </GestaltBox>
      }

      <GestaltBox width={"100%"}>
        <TopicDropdown
          open={open}
          setOpen={setOpen}
          values={values}
          setValues={setValues}
        />
        {
          errorMessages.topic != "" &&
          <div style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}>

            <GestaltText color="error" size="100" >
              {errorMessages.topic}
            </GestaltText>
          </div>
        }
        {
          errorMessages.topic == "" && values.topic != "" &&
          <div style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}>
            <GestaltText color="subtle" size="100" >
              Currently selected: <span style={{ fontWeight: 500, color: "black" }}>{values.topic}</span>
            </GestaltText>
          </div>
        }
      </GestaltBox>

      <GestaltBox marginTop={3} width={"100%"}>
        <TextArea
          errorMessage={errorMessages.description}
          helperText="The best descriptions tend to describe the problems in the highest detail."
          id="description-id"
          onChange={(e) =>
            setValues((curr) => {
              return { ...curr, description: e.value };
            })
          }
          placeholder="Tell us your problem"
          label="Description"
          value={values.description}
          rows={4}
          onKeyDown={(v) => handleKeyDownInsideForm(v.event.key)}
        />
      </GestaltBox>

      {/* <div
        style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}
      >
        <GestaltText size="100" >
          Upload images
        </GestaltText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
          }}
          className="file-input-wrapper"
        >
          <div style={{ width: "100%" }} id="file-btn">
            <FileButton
              // @ts-ignore
              leftSection={<IconUpload strokeWidth={"1.5"} size={16} />}
              color="black"
              variant="transparent"
              // styles={{ root: { border: "2x solid #cdcdcd" } }}
              style={{ width: "100%" }}
              multiple
              {...form.getInputProps("file_upload")}
              resetRef={resetRef}
              accept="image/*"
            >
              {(props) => (
                <Button {...props}>
                  <GestaltText size="200">Upload image</GestaltText>
                </Button>
              )}
            </FileButton>
          </div>
          <ActionIcon
            onClick={clearFile}
            color="#CC0000"
            variant="transparent"
            style={{ height: "44px" }}
          >
            <Tooltip label="Delete uploaded files">
              <IconTrashFilled
                style={{ marginTop: "1px" }}
                onClick={clearFile}
                strokeWidth={2}
                size="20px"
              />
            </Tooltip>
          </ActionIcon>
        </div>

        {form.values.file_upload.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "20px",
            }}
          >
            <div style={{ minWidth: "100px" }}>
              <GestaltText color="subtle" size="100" >
                Picked files:
              </GestaltText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "20px",
                rowGap: "5px",
                flexWrap: "wrap",
              }}
            >
              {form.values.file_upload.map((e: File) => (
                <GestaltText color="dark" weight="bold" size="100">
                  {e!.name}
                </GestaltText>
              ))}
            </div>
          </div>
        )}
        <GestaltText size="100" color="subtle" >
          Upload screenshots that will help us understand the problem better. Helpful images lower response time by as much as 500%. Examples of good images are: logs from machines facing problems, image showing an unresponsive machine, a screenshot of the dialog box in a windows machine that describes the problem.
        </GestaltText>
      </div> */}

      <div className="light-raised border-radius-bt-16" style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 65, backgroundColor: "#c3c0d2", bottom: 0, left: 0, }}>
        <div id="btn-groups-id" style={{ display: "flex", width: "80%", gap: "10px",  }}>
          <GestaltButton name="support-reset-btn" color="gray" onClick={() => resetForm()} fullWidth={true} text="Reset" />
          <GestaltButton onClick={() => handleSubmit()} name="support-submit-btn" fullWidth={true} text="Submit issue" />
        </div>
      </div>
    </>
  );
};

export default SupportOutsideConsole;
