import { Badge, Button } from "@mantine/core";
import { IconPlus } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import './Card.css';
import { Loader } from "@mantine/core";

const CARDS = {
    visa: '^4',
    amex: '^(34|37)',
    mastercard: '^5[1-5]',
    discover: '^6011',
    unionpay: '^62',
    troy: '^9792'
};

const cardBackgroundName = (last4: string) => {
    let last4int = Number(last4);


    return `${last4int % 25 + 1}.jpeg`;
};

const Card = ({
    id,
    fn,
    cardHolder,
    last4,
    cardMonth,
    cardYear,
    cardCvv,
    cardBrand,
    isFake,
    isDefault,
    isLoading,
}: {
    id: string,
    fn: () => void,
    cardHolder: string | undefined,
    last4: string,
    cardMonth: string,
    cardYear: string,
    cardCvv: string,
    cardBrand: string,
    isFake: boolean,
    isDefault: boolean,
    isLoading: boolean,
}) => {
    const BACKGROUND_IMG = useMemo(() => cardBackgroundName(last4), [])

    const getHeight = (cardBrand: string) => {
        if (cardBrand == "visa") {
            return "25px"
        } else if (cardBrand == "jcb") {
            return "30px"
        } else if (cardBrand == "unionpay") {
            return "30px"
        } else if (cardBrand == "mastercard") {
            return "30px"
        } else {
            return "15px"
        }
    }



    return (
        <div onClick={fn} style={{ position: "relative", }} className={'card-item clickable'}>
            <div style={{ filter: isFake ? "blur(1px)" : "None", }} className="card-item__side -front">
                <div
                    className={`card-item__focus`}
                />
                <div className="card-item__cover">
                    <img
                        alt=""
                        src={isFake ? "https://college-poster.s3.us-east-2.amazonaws.com/libvirt-vms/card-background/13.jpeg" : `https://college-poster.s3.us-east-2.amazonaws.com/libvirt-vms/card-background/${BACKGROUND_IMG}`}
                        className="card-item__bg"
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", flexDirection: "column", height: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                        <img
                            style={{ width: "30px", zIndex: 5 }}
                            src={'https://college-poster.s3.us-east-2.amazonaws.com/libvirt-vms/card-type/chip.png'}
                            alt=""
                            className="card-item__chip"
                        />
                        {isDefault ? <Badge color="cyan" c="black" size="sm">Default</Badge> : <img
                            style={{ height: getHeight(cardBrand), zIndex: 5 }}
                            alt={cardBrand}
                            src={`https://college-poster.s3.us-east-2.amazonaws.com/libvirt-vms/card-type/${cardBrand}.png`}
                            className="card-item__typeImg"
                        />}
                    </div>

                    <div style={{ zIndex: 5, width: "100%", }} className="card-item__number">
                        <div style={{ zIndex: 5, width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", gap: "12px", fontWeight: 600, }} className="card-item__numberItem asterix-font-size">
                            < span >∗∗∗∗</span> < span >∗∗∗∗</span> < span >∗∗∗∗</span> <span className="last-4">{last4}</span>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                        <div
                            style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", zIndex: 5 }}
                            className="card-item__info">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="card-item__holder">
                                    Card Holder
                                </div>
                                <span className="card-item__name">
                                    {cardHolder?.split('@')[0].toUpperCase()}
                                </span>
                            </div>

                        </div>
                        <label
                            className="card-item__info ta-end"
                        >
                            <div className="card-item__holder">Expires</div>
                            <div className="card-item__name">
                                <span className="card-item__nameItem">
                                    {cardMonth}/{cardYear.toString().substring(2, 4)}
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            {
                isFake ? 
                <div style={{ display: "flex", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", justifyContent: "space-between", }}>
                    <Button leftSection={ !isLoading && <IconPlus stroke="2.5" size="12" />} style={{ fontSize: "12px" }} onClick={fn} c="black" size="xs" gradient={{ from: 'white', to: "white", deg: 90 }} variant="gradient">
                        { isLoading ? < Loader size="xs" color="black" /> : "Add new card" }
                    </Button>
                    </div> 
                : null
            }
        </div>
    );
};

export default Card;
