import { Input, Switch } from "@mantine/core";
import CustomLabel from "../../../custom-items/CustomLabel";
import SelectGPUModal from "../../../custom-items/CustomSelectGPUModal";

const AdvancedOption = ({
  selectedGPU,
  setSelectedGPU,
  reference,
  form,
}: {
  selectedGPU: string;
  setSelectedGPU: React.Dispatch<React.SetStateAction<string>>;
  reference: React.MutableRefObject<null>;
  form: any;
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <div style={{ display: "flex", width: "100%", flexDirection: "column", paddingTop: "10px" }}>
        <CustomLabel required={false} label="Machine name" />
        <Input
          {...form.getInputProps("vmName")}
          className=""
          styles={{
            input: {
              borderRadius: "4px",
              border: "1px solid black",
            },
            wrapper: {
              borderRadius: "4px",
              border: "none",
            },
          }}
          maxLength={25}
        />
      </div>
      <div>
        <CustomLabel required={false} label="IP Configuration" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div style={{ flex: 1, textAlign: "end" }} className="font-14">
            Dynamic IP
          </div>
          <Switch
            style={{ flex: 0 }}
            labelPosition="left"
            label="Public IP"
            color="black"
            size="md"
            styles={{
              label: {
                visibility: "hidden",
                display: "none",
              },
              thumb: {
                backgroundColor: form.values.staticIP ? "white" : "black",
              },
              track: {
                border: "1px solid black",
              },
            }}
            {...form.getInputProps("staticIP", { type: "checkbox" })}
          />
          <div style={{ flex: 1, textAlign: "start" }} className="font-14">
            Static IP
          </div>
        </div>
      </div>

      <SelectGPUModal
        setSelectedGPU={setSelectedGPU}
        selectedGPU={selectedGPU}
      />
    </div>
  );
};

export default AdvancedOption;
